<template>
  <div class="cfa-root d-flex align-items-center justify-content-center" style="height: 100vh; background-color: #f7f8fc;">
    <form @submit.prevent="tryLogin" class="leftBlock">
        <!-- brand -->
        <div class="auth-form-header">
            <a href="/"><div class="auth-form-header-illustration mx-auto"></div></a>
            <div class="auth-form-header-separator"></div>
            <span class="auth-form-header-title"><e style="color: white;">Compte bloqué</e></span>
        </div>
        <!-- form -->
        <div class="auth-form-content">
            <!--<div class="mt-5">
                <modern-input @inputChange="(e)=>{this.connect_email = e}" :syncField="syncTime" placeholder="Votre Adresse email" errorText="Champ requis" uid="cfa-username-bloc" type="email"></modern-input>
            </div>-->
            <p style="color: white;">Un code de dévérouillage vous a été envoyé par email. Entrez le dans la champ ci-dessous pour debloquer votre compte. </p>
            <div class="mt-5">
                <modern-input @inputChange="(e)=>{this.connect_pass = e}" :syncField="syncTime" placeholder="Votre Code" errorText="Champ requis" uid="cfa-password-bloc" type="password"></modern-input>
            </div>
        </div>
        <!-- button -->
        <div class="auth-form-actions">
            <button type="submit" class="accent light normal oauth-button">
                <span v-if="!waitingAPIResponse">Débloquer mon compte</span>
                <img v-else src="@/assets/img/gif/load2.gif" alt="loading gif" style="width: 30px;"/>
            </button>
            <div class="d-flex">
                <a href="/login" type="button" target="" rel="noopener" class="neutral inverted light oauth-button-light"> Me connecter plutôt ? </a>
            </div>
        </div>
        <!--<div class="" style="padding: 2em 3em; text-align: justify;">
            <span>En continuant, vous acceptez les termes de notre <a class="link" href="/generals-conditions" target="_blank">politique de confidentialité</a> et nos réglementations concernant les <a class="link" href="/generals-conditions#cookies" target="_blank">cookies</a>.</span>
        </div>-->
    </form>
    <div class="rightBlock px-5 position-relative" v-if="!waitingAPIResponse2">
        <img v-if="pub != null" class="pub-cfa-block" :src="pub.image"/>
        <img v-else class="cfa-side-logo d-none d-md-block" src="@/assets/brand/spec-brand-1.svg" alt="cyberforceacademy logo">
        <p v-if="pub != null" style="px-5 mb-5">{{ pub?.description }}</p>
        <a v-if="pub != null" :href="pub.link" target="_blank">
          <button type="submit" class="accent light normal oauth-button cfa-bg-secondary mt-3"> En savoir plus</button>
        </a>
    </div>
    <div class="rightBlock px-5 position-relative" v-else>
      <div class="gZebbraLoader">
        <div id="loading">
          <div class="preloader d-flex flex-column">
            <img src="@/assets/brand/cfa-favicon1.png" alt="cfa preloader">
            <p class="pl-3 fw-bold">loading . . .</p>
          </div>
        </div>
      </div>
    </div>
    <transition name="fadeY" appear mode="out-in">
      <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
    </transition>
  </div>
</template>

<script>
import ModernInput from '@/components/shared/input/ModernInput.vue'
import { guestAPI } from '@/axios-api.js'
import Alert from '@/components/shared/alert/Alert.vue'

export default {
  name: 'UnlockView',
  components: {
    ModernInput,
    Alert
  },
  data () {
    return {
        connect_email: '',
        connect_pass: '',
        alertMe: false,
        gAlertMessage: '',
        gAlertType: '',
        waitingAPIResponse: false,
        waitingAPIResponse2: true,
        syncTime: null,
        pub: null,
    }
  },
  methods: {
    displayError(error, type='alert-ok') {
        this.gAlertMessage = error
        this.gAlertType = type
        this.alertMe = true
        setTimeout(() => {
            this.alertMe = false
        }, 5000);
    },
    tryLogin () {
        if(this.connect_pass.length < 2) {
            this.displayError('Oups ! Veuillez remplir les champs convenablement.', 'alert-no');
            this.syncTime = Date.now().toString()
        }
        else{
            this.waitingAPIResponse = true
            guestAPI.post('accounts/unlock-login',
            {
                email: this.$route.params.email,
                code: this.connect_pass,
            })
            .then(response => {
                if(response.status == 200) {
                    this.displayError("Votre compte à été débloqué avec succès", 'alert-yes')
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                        let a = document.createElement('a');
                        a.href="/login"
                        a.click()
                        a.remove()
                    }, 1000);
                }
                else {
                    this.displayError('Oups ! something went wrong.', 'alert-no')
                    this.waitingAPIResponse = false
                }
            })
            .catch(error => {
                sessionStorage.removeItem('token')
                if (error.response) {
                    this.displayError("Code invalide", 'alert-no', 10000)
                }
                else if (error.request) {
                    this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
                }
                else {
                    this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
                }
                this.waitingAPIResponse = false
            })
        }
    }
  },
  mounted() {
    this.waitingAPIResponse2 = true
    guestAPI.get('pub?key=CONNEXION').then(response => {
      if(response.status == 200) {
        this.pub = response.data[0]
      }
      else{
        console.log('Something went wrong !')
      }
      this.waitingAPIResponse2 = false
    }).catch(() => {
      console.log('Something went wrong !')
      this.waitingAPIResponse2 = false
    });
  }
}
</script>

<style scoped>
    @media only screen and (max-width: 600px) {
        .leftBlock{
            width: 100% !important;
            border-bottom-right-radius: 0px !important;
            border-top-right-radius: 0px !important;
            background-color: #262F44;
            font-weight: 400;
        }
        .rightBlock{
            display: none !important;
        }
    }
    .leftBlock{
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0em;
        min-width: 30em;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        box-shadow: 8px 0 6px #4763881a;
        overflow-y: auto;
        width: 30%;
        z-index: 10;
        background-color: #262F44;
        font-weight: 400;
    }
    .rightBlock{
        width: 70%;
        height: 100vh;
        background-color: #f7f8fc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .rightBlock img{
        margin-bottom: 1em;
        max-width: 100%;
        max-height: calc(100vh - 12em);
    }
    .auth-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
    }
    .auth-form-header {
        display: flex;
        flex-direction: column;
        letter-spacing: .5px;
        text-align: center;
    }
    .auth-form-content{
        width: 22em;
    }
    .auth-form-header .auth-form-header-illustration {
        /* width: 121px;
        height: 30px; */
        width: 181px;
        height: 40px;
        align-self: center;
        margin-bottom: 20px;
        background-image: url(@/assets/brand/logo-1SD.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .auth-form-header .auth-form-header-separator {
        width: 200px;
        height: 1px;
        align-self: center;
        /* background: #bfcee1; */
        border-bottom: 1px solid #bfcee1;
    }
    .auth-form-header .auth-form-header-title {
        margin-top: 20px;
        margin-bottom: 30px;
        color: #476388;
        font-size: 24px;
        white-space: nowrap;
    }
    .auth-form-link .forgot-password {
        cursor: pointer;
        text-decoration: underline;
    }
    .auth-form-link a {
        color: #476388;
    }
    /* auth actions style */
    .auth-form-actions {
        display: flex;
        width: 200px;
        height: 90px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }
    .oauth-button{
        background-color: var(--primary-color);
        color: #fff;
        border: none;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        user-select: none;
        position: relative;
        display: flex;
        min-width: 150px;
        height: 34px;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        border-radius: 17px !important;
        font-size: 14px;
        font-weight: 800;
        letter-spacing: .5px;
        line-height: 32px;
        text-align: center;
        transition: all .15s cubic-bezier(0,0,.2,.1);
        box-shadow: 0 0 6px #47638840;
    }
    .oauth-button:hover{
        background-color: var(--secondary-color);
    }
    .oauth-button-light{
        background-color: #fff;
        color: #6c84a2;
        border: none;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        user-select: none;
        position: relative;
        display: flex;
        min-width: 150px;
        height: 34px;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        border-radius: 17px !important;
        font-size: 14px;
        font-weight: 800;
        letter-spacing: .5px;
        line-height: 32px;
        text-align: center;
        transition: all .15s cubic-bezier(0,0,.2,.1);
        box-shadow: 0 0 6px #47638840;
    }
    .oauth-button-light:hover{
        color: var(--primary-color);
    }
</style>
