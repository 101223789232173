<template>
  <div>
    <div class="cfa-certificate-preview">
      <div class="w-100 h-100 position-relative">
        <div class="barL">
          <div class="position-relative cfa-decoration-certificate">
            <img style="position: absolute; right: 2.05em; z-index: 10; top: -2em;" width="50" src="../../../assets/Icons/All_Icon/Group1000004446.png" alt="cfa video placeholder"/>
          </div>
        </div>
        <div class="barl"></div>
        <div class="p-5 pb-2">
          <br><br>

          <h3><strong>Spectorly</strong></h3>
          <p>{{fname}}</p>
          <br><br>
          <p class="pe-5">

          </p>
          <p class="text-success">
              <span style="font-size: 1.3em;">Ce certificat est valide !</span>
              <br>
          </p>
          <div class="text-end pe-5 pt-5">
            <p>Code Certificat: {{ (code||'').toString().toUpperCase() }}</p>
            <!--<small>Signature du Mentor</small>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CertificatePreview',
  props: {
    fname: {
      type: String,
      default: 'Malick'
    },
    lname: {
      type: String,
      default: 'Spector'
    },
    isReady: {
      type: Boolean,
      default: false
    },
    code: {
        type: String,
        default: null
    },
  },
  data () {
    return {
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped>
.cfa-certificate-preview{
  border-radius: .5em;
  overflow: hidden;
  height: 22.5em;
  background-color: #fff;
}
.barL{
  position: absolute;
  top: 3em;
  left: 0;
  right: 0;
  padding: 0.5em;
  background: rgb(6,187,196);
  background: linear-gradient(90deg, rgba(6,187,196,1) 0%, rgba(6,187,196,1) 57%, rgba(0,212,255,0.3435574058725053) 100%);
}
.barl{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4em;
  padding: 0.5em;
  height: 100vh;
  background: rgb(6,187,196);
  background: linear-gradient(0deg, rgba(6,187,196,1) 0%, rgba(6,187,196,1) 57%, rgba(0,212,255,0.3435574058725053) 100%);
}
</style>
