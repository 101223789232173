<template>
  <div class="cfa-content-filter w-100 d-flex align-items-center justify-content-between">
    <div>
      <button @click="filterView" :style="filter ? 'border-bottom: 2px solid #000; font-weight: 400; color: #000;' : 'color: ;#6e7483'" class="cfa-filter-type ms-0">
        {{label1}}
        <span>{{total1}}</span>
      </button>
      <button @click="filterView" :style="!filter ? 'border-bottom: 2px solid #000; font-weight: 400; color: #000;' : 'color: ;#6e7483'" class="cfa-filter-type">
        {{label2}}
        <span>{{total2}}</span>
      </button>
    </div>
    <div class="d-flex">
      <button @click="changeDisplayMode('GROUP')" class="cfa-discret-btn">
        <img width="15" src="../../assets/Icons/All_Icon/Vector-14.png" alt="Group View"/>
      </button>
      <button @click="changeDisplayMode('LIST')" class="cfa-discret-btn">
        <img width="15" src="../../assets/Icons/All_Icon/Vector-15.png" alt="List View"/>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentFilter',
  props: {
    total1:{
      type: Number,
      default: 12
    },
    total2:{
      type: Number,
      default: 10
    },
    label1:{
      type: String,
      default: 'Tous les modules'
    },
    label2:{
      type: String,
      default: 'En cours'
    }
  },
  data () {
    return {
      filter: true
    }
  },
  methods: {
    filterView () {
      this.filter = !this.filter
      this.$emit('viewFilter', (this.filter) ? 'ALL' : 'IN_PROGRESS')
    },
    changeDisplayMode (e) {
      this.$emit('viewMode', e)
    }
  }
}
</script>

<style scoped>
.cfa-discret-btn{
  border: none;
  background-color: transparent;
  border-radius: .5em;
  padding: 0.4em .5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cfa-discret-btn:focus{
  background-color: #ddd;
}
.cfa-filter-type{
  border: none;
  padding: 0.3em 0;
  margin: 0.5em;
  /* border-bottom: 2px solid #000; */
  background-color: transparent;
  font-size: 1.1em;
  transition: all ease-in .3s;
}
.cfa-filter-type span{
  width: .8em;
  height: .8em;
  background-color: #e1e4e9;
  border-radius: 0.3em;
  padding: 0.2em .5em;
  font-size: .7em;
}
</style>