<template>
    <div :class="(status == 'alert-yes') ? 'gAlert gAlert-ok' : (status == 'alert-middle') ? 'gAlert gAlert-blue' : 'gAlert gAlert-no' ">
        <div class="i">
            <!--<i class="fa fa-info-circle"></i>  -->
            <svg v-if="status == 'alert-no'" class="icon icon-custom-size flex-shrink-0 me-2 flex-shrink-0 me-2" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img">undefined<path fill="var(--ci-primary-color, currentColor)" d="M311.145,257.6c5.5-9.032,11.185-18.371,16.8-28,18.794-32.218,22.243-64.681,10.254-96.489C314.008,68.933,233.142,29.83,214.421,23.591L201.257,19.2,180.711,60.3l9.915,8.1c.111.089,11.124,9.712,11.858,24.3.627,12.453-6.2,25.91-20.286,40-9.782,9.781-20.518,19.239-31.885,29.251C102.487,204.069,48.28,251.82,48.28,342.154q0,1.861.035,3.7a151.362,151.362,0,0,0,46.444,106.49A154.177,154.177,0,0,0,202.583,496h92.1l-11.369-23.072C237.121,379.177,269.788,325.525,311.145,257.6ZM202.583,464c-66.2,0-121.05-53.267-122.274-118.739q-.028-1.546-.029-3.107c0-75.878,46.356-116.713,91.186-156.2,11.239-9.9,22.862-20.138,33.359-30.637,20.754-20.753,30.719-42.365,29.619-64.232a64.963,64.963,0,0,0-7.214-26.56c27.84,15.211,67.523,44.053,81.027,79.877,8.544,22.665,5.943,45.26-7.951,69.079-5.465,9.369-11.072,18.576-16.493,27.481C244.132,306.131,206.49,367.943,244.361,464Z" class="ci-primary"></path><path fill="var(--ci-primary-color, currentColor)" d="M468.243,341.129q-.4-1.586-.834-3.185c-11.546-42.332-75.457-96.762-82.706-102.829l-14.557-12.182L360.6,239.341c-21.753,37.4-40.421,71.512-48.559,110.212-9.279,44.134-3.033,88.989,19.1,137.13L335.422,496H346.3a125.168,125.168,0,0,0,99.3-48.5A123.175,123.175,0,0,0,468.243,341.129Zm-47.88,86.683a93.3,93.3,0,0,1-64.44,35.7c-31.541-75.9-12.931-127.635,22.63-191.114,23.642,21.994,52.455,53.69,57.983,73.961q.349,1.283.669,2.554A91.372,91.372,0,0,1,420.363,427.812Z" class="ci-primary"></path></svg>
            <svg v-else-if="status == 'alert-yes'" class="icon icon-custom-size flex-shrink-0 me-2 flex-shrink-0 me-2" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img">undefined<path fill="var(--ci-primary-color, currentColor)" d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z" class="ci-primary"></path><polygon fill="var(--ci-primary-color, currentColor)" points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808" class="ci-primary"></polygon></svg>
            <svg v-else class="icon icon-custom-size flex-shrink-0 me-2 flex-shrink-0 me-2" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img">undefined<rect width="32" height="176" x="240" y="176" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect><rect width="32" height="32" x="240" y="384" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect><path fill="var(--ci-primary-color, currentColor)" d="M274.014,16H237.986L16,445.174V496H496V445.174ZM464,464H48V452.959L256,50.826,464,452.959Z" class="ci-primary"></path></svg>
        </div>
        <div style="margin-top: .3em;" v-html="message"></div>
    </div>
</template>

<script>
export default {
    name: 'AlertComponents',
    props: {
        status: {type: String, default: 'alert-no'},
        message: String
    }
}
</script>

<style>
    .gAlert {
        display: inline-block;
        width: 35em;
        position: fixed;
        bottom: 0.7em;
        left: 1em;
        right: 0;
        margin: auto;
        padding: 1em 1.5em;
        color: white;
        display: flex;
        flex-direction: row;
        z-index: 1000000000;
        font-weight: 400; font-size: 14px; border-radius: 4px;
    }
    @media only screen and (max-width: 600px) {
        .gAlert {
            width: calc(100% - 2em) !important;
            left: 0;
        }
    }
    .gAlert .i{
        margin-right: 0.3em !important;
    }
    .gAlert-ok{
        background-color: #46911d;
    }
    .gAlert-no{
        background-color: #da3d3d;
    }
    .gAlert-blue{
      background-color: #06bbc4;
      /*background-color: rgb(25, 110, 221);*/
    }
</style>
