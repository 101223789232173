<template>
  <div class="cfa-root dashboard">
    <c-f-a-nav :alert="alertString">
      <template v-slot:title>
      <div v-if="subscriptionData?.is_activated == false || subscriptionData == null" class="card-body p-0 mt-4 mb-0">
          <div class="card-transparent cfa-warning-deadline py-3 px-2" style="border: 2px solid rgb(50,204,218); background-color: rgb(229,245,245); color: rgb(50,204,218);font-weight: 400;">
              <div class="d-flex align-items-center justify-content-between w-100">
                  <div>
                    {{ $t('discover_plans') }}
                  </div>
                  <div class="">
                      <a href="/facturation/pricing"><button class="btn cfa-btn-primary">{{ $t('subscribe') }}</button></a>
                  </div>
              </div>
          </div>
      </div>
        <div class="w-100 pt-5 text-start mb-3">
          <h1 class="cfa-section-title cfa-section-title-dashboard">{{ $t('dashboard_title') }}</h1>
        </div>
      </template>
      <template v-slot:content>
        <!-- missing deadline for org -->
        <div v-if="showAlert" class="card-body p-0 p-4" style="position: fixed;left: 0;right: 0;top: 0;bottom: 0;z-index: 10000000000;background: #fff; display: flex; flex-direction: column;align-items: center;justify-content: center;">
            <div class="col-md-6">
                <div class="card-transparent cfa-warning-deadline py-3 px-3 mb-4" style="border: 2px solid rgb(50,204,218); background-color: rgb(229,245,245); color: rgb(50,204,218);font-weight: 400; border-radius: 4px;">
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <div>
                          {{ $t('profile_information') }}&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!waitingAPIResponse" class="cfa-block py-4 col-md-6">
                <div class="d-flex align-items-center justify-content-between">
                    <h1 class="cfa-section-title mb-0">{{ $t('my_information') }}</h1>
                    <div class="d-flex">
                        <a @click="openFileSelector" class="ps-2 ms-2 btn align-self-center cfa-btn-primary">
                            <i v-if="!uploadPending" class="fa fa-image margin-right-10 d-none d-md-inline" aria-hidden="true">&nbsp;</i>
                            <span v-if="!uploadPending" class="d-none d-md-inline">{{ $t('update_avatar') }}</span>
                            <span v-if="!uploadPending" class="d-md-none d-inline">Avatar</span>
                            <div v-if="uploadPending" class="" style="width: 11em;">
                                <img  width="20" src="@/assets/img/gif/load2.gif" alt="loader"/>
                            </div>
                        </a>
                        <input type="file" ref="fileSelector" @change="uploadImage" style="display: none;">
                    </div>
                </div>

                <section class="text-center py-4 cfa-bg-secondary" style="border-radius: 0.5em;">
                    <div >
                        <img class="bg-white p-1" width="150" height="150" style="border-radius: 100%;" :src="(userData.image_url != null) ? userData.image_url : defaultImage" alt="logo company"/>
                    </div>
                </section>

                <section class="mt-4">
                    <div class="row">
                        <div class="col-12 col-md-4 pl-0">
                            <div class="form-group ">
                                <label class="control-label" for="inputFirstname">{{ $t('first_name') }}<span class="required">*</span></label>
                                <input id="inputFirstname" class="form-control" name="inputFirstname" type="text" v-model="firstname">
                            </div>
                        </div>
                        <div class="col-12 col-md-4 pl-0">
                            <div class="form-group ">
                                <label class="control-label" for="inputLastname">Nom<span class="required">*</span></label>
                                <input id="inputLastname" class="form-control" name="inputLastname" type="text" v-model="lastname">
                            </div>
                        </div>
                        <div class="col-12 col-md-4 pl-0 pr-0">
                            <div class="form-group ">
                                <label class="control-label" for="inputEmail">E-mail</label>
                                <input id="inputEmail" class="form-control" name="inputEmail" type="email" :value="userData.email" disabled="">
                            </div>
                        </div>
                    </div>
                </section>
                <section class="mt-4">
                    <div class="row">
                        <div class="col-12 col-md-4 pl-0">
                            <div class="form-group ">
                                <label class="control-label" for="inputFirstname">{{ $t('personal_phone') }}<span class="required">*</span></label>
                                <input id="inputFirstname" class="form-control" name="inputFirstname" type="text" v-model="phonePersonal">
                            </div>
                        </div>
                        <div class="col-12 col-md-4 pl-0">
                            <div class="form-group ">
                                <label class="control-label" for="inputLastname">{{ $t('professional_phone') }}</label>
                                <input id="inputLastname" class="form-control" name="inputLastname" type="text" v-model="phoneProfessional">
                            </div>
                        </div>
                        <div class="col-12 col-md-4 pl-0 pr-0">
                            <div class="form-group ">
                                <label class="control-label" for="inputEmail">Entreprise</label>
                                <input id="inputEmail" class="form-control" name="inputEmail" type="email" :value="userData.organisation_detail?.name" disabled="">
                            </div>
                        </div>
                    </div>
                </section>
                <div class="mt-4 d-flex justify-content-between align-items-center">
                    <div class="text-left">
                      {{ $t('valid_information') }}
                    </div>
                    <button @click="updateUserProfil" id="validateButton-pass" class="btn btn-round cfa-btn-primary" type="submit">
                      {{ $t('continue') }}

                    </button>
                </div>
            </div>
            <div v-if="waitingAPIResponse" class="cfa-page">
                <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
                    <div class="preloader_center d-flex flex-column">
                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                        <p class="pl-3 fw-bold">chareeeeeeeeegement . . .</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!waitingAPIResponse" class="cfa-page gBlock-row">
            <div class="row d-flex flex-wrap">
              <div class="col-12 col-md-6 col-lg-3">
                <div class="bg-white d-flex  py-3 gBlock">
                  <div class="p-3 gFolder-container">
                    <img width="30" src="../../assets/Icons/All_Icon/Vector-3.png" alt="dashboard state image"/>
                  </div>
                  <div>
                    <p>{{ this.dashboardData?.numb_module }}</p>
                    <p>{{ $t('all_module') }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3">
                <div class="bg-white gBlock d-flex py-3">
                  <div class="p-3 gFolder-container">
                    <img width="30" src="../../assets/Icons/All_Icon/Vector-4.png" alt="dashboard state image"/>
                  </div>
                  <div>
                    <p>{{ this.dashboardData?.module_finish }}</p>
                    <p>{{ $t('module_done') }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3">
                <div class="bg-white gBlock d-flex py-3">
                  <div class="p-3 gFolder-container">
                    <img width="25" src="../../assets/Icons/All_Icon/Group1000004437.png" alt="dashboard state image"/>
                  </div>
                  <div>
                    <p>{{ this.dashboardData?.quizz_finish }}</p>
                    <p>{{ $t('quiz_done') }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3">
                <div class="bg-white gBlock d-flex py-3">
                  <div class="p-3 gFolder-container">
                    <img width="30" src="../../assets/Icons/All_Icon/Group1000004435.png" alt="dashboard state image"/>
                  </div>
                  <div>
                    <p>{{ this.dashboardData?.cyberscore }}%</p>
                    <p>{{ $t('all_quiz') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- col1 -->
            <div class="row">
            <div class="col-12 col-lg-8 ">
              <div class="cfa-welcome ml-0">
                <div class="cfa-welcome-text mb-3">
                  <h1 style="font-size: 1.5em;" class="d-flex align-items-center mb-2 mt-2">
                    <img width="35" src="@/assets/img/gif/waving_hand.gif" alt="greeting gif"/>&nbsp;
                    <span class="font-weight-bold position-relative top-1">{{ $t('welcome') }} <span class="welcome-username">{{ username }}</span></span>
                  </h1>
                  <p style="color: #132B4D;">
                    {{ $t('cybersecurity_awareness') }} <span class="cfa-primary">{{ this.dashboardData?.numb_module }} modules</span> {{ $t('complete_overview') }} <span class="cfa-primary">quiz final</span> pour l’obtention de votre certification.
                  </p>
                </div>
                <div v-if="dashboardData?.deadline != null" class="cfa-welcome-text mt-3">
                  <div style="color: #132B4D;">
                    <div v-if="this.formatedDealine[0] - currentDateDay <= 7">
                      <span class="fs-5" style="color: red; font-weight:bold;">IMPORTANT</span>: {{ $t('deadline_text') }} <h5 style="display: inline-block;"><strong style="color: #ff4366; font-weight:bold;">{{ this.formatedDealine[0] }}/{{ this.formatedDealine[1] }}/{{ this.formatedDealine[2] }} à {{this.formatedDealine[3]}}:{{this.formatedDealine[4]}}</strong></h5>.
                    </div>

                    <div v-if="this.formatedDealine[0] - currentDateDay > 7 && this.formatedDealine[0] - currentDateDay <= 14">
                      <span class="fs-5" style="color: orange;">IMPORTANT</span>: {{ $t('deadline_text') }} <h5 style="display: inline-block;"><strong style="color: orange; font-weight:bold;">{{ this.formatedDealine[0] }}/{{ this.formatedDealine[1] }}/{{ this.formatedDealine[2] }} à {{this.formatedDealine[3]}}:{{this.formatedDealine[4]}}</strong></h5>.
                    </div>
                  
                    <div v-if="this.formatedDealine[0] - currentDateDay > 14">
                      <span class="cfa-primary fs-5">IMPORTANT</span>: {{ $t('deadline_text') }} <h5 style="display: inline-block;"><strong style="color: rgb(50,204,218); font-weight:bold;">{{ this.formatedDealine[0] }}/{{ this.formatedDealine[1] }}/{{ this.formatedDealine[2] }} à {{this.formatedDealine[3]}}:{{this.formatedDealine[4]}}</strong></h5>.
                    </div>
                  </div>
                </div>
                <dead-line v-if="dashboardData?.deadline != null" :day="this.formatedDealine[0]" :month="this.formatedDealine[1]" :year="this.formatedDealine[2]" :remainDays="this.get_time_passed(this.dashboardData.deadline)"></dead-line>
                <div v-if="playVideo != 'null'" class="cfa-welcome-video">
                  <c-f-a-video placeholder="./spectorly.svg" :autoplay="true" video-link="https://player.vimeo.com/video/952453040" :showLabel="false" :max-height="30.5" :video-height="400"></c-f-a-video>
                </div>
              </div>
              <div v-if="Org == true" class="row d-flex flex-wrap">
                <div class="col-12 col-md-6">
                    <div class="bg-white d-flex  py-3 gBlock">
                        <div class="p-3 gFolder-container">
                            <img width="30" src="@/assets/img/png/phishing.png" alt="dashboard state image"/>
                        </div>
                        <div>
                            <!-- <p>{{ subscriptionData?.remain_credit }}/{{ total_credit_mail }}</p> -->
                            <p>{{ subscriptionData?.remain_credit_phishing || 0 }}</p>
                            <p>{{ $t('remaining-phishing') }}</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="bg-white gBlock d-flex py-3">
                        <div class="p-3 gFolder-container">
                            <img width="30" src="@/assets/img/png/sms.png" alt="dashboard state image"/>
                        </div>
                        <div>
                            <!-- <p>{{ credit_sms }}/{{ total_credit_sms }}</p> -->
                            <p>{{ subscriptionData?.remain_credit_smshing || 0 }}</p>
                            <p>{{ $t('remaining-smshing') }}</p>
                        </div>
                    </div>
                </div>
         
        
        
           </div>
 <div v-if="Org == true" class="card pe-0 get-card overflow-hidden entreprise-block">
                                        <div v-if="subscriptionData?.is_activated == true " class="card-header card-no-border d-flex justify-content-between align-items-center" style="padding: 1.5em 2em">
                                            <h5>Licence</h5>
                                            <button class="btn top-btn" :style="`background-color: ${( subscriptionData?.is_activated) ? 'green' : 'red'}`" >{{ ( subscriptionData?.is_activated) ? 'Active' : 'Expirée' }}</button>
                                        </div>
                                        <div v-else class="card-header card-no-border d-flex justify-content-between align-items-center" style="padding: 1.5em 2em">
                                            <h5 style="color: #fff;">{{$t('license')}}</h5>
                                            <button class="btn top-btn" style="background-color: rgb(255 114 94);" >{{ $t('no-active-plans') }}</button>
                                        </div>
                                            <div v-if="subscriptionData?.is_activated == true" class="d-flex licence-card" style="padding: 0 1.5em 1.5em;">
                                                <div class="card mb-0 w-100 ">
                                                    <div class="card-body" style="display: flex; flex-direction: row; justify-content: space-between;">
                                                      <div>
                                                        <h5 class="card-title">{{ $t('active-plan') }}</h5>
                                                        <a v-if="remain_days != ''" class="btn btn-light- ff-bold cfa-bg-light-primary f-light no-wrap" style="pointer-events: none; 
                                                        " v-html="lastTransactionPlan"></a>
                                                        <a v-else class="btn btn-light- ff-bold cfa-bg-light-primary f-light no-wrap" style="pointer-events: none;" v-html="'---'"></a>
                                                      </div>
                                                      <div>
                                                        <h5 class="card-title">{{ $t('remaining-days') }}</h5>
                                                        <a v-if="remain_days != ''" class="btn btn-light- ff-bold cfa-bg-light-primary f-light no-wrap" style="pointer-events: none;" v-html="remain_days + ' jours'"></a>
                                                        <a v-else class="btn btn-light- ff-bold cfa-bg-light-primary f-light no-wrap" style="pointer-events: none;" v-html="remain_days + '---'"></a>
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card mb-0 w-100 ms-4">
                                                  <div  class="card-body">
                                                    <h5 class="card-title">{{ $t('license-capacity') }}</h5>
                                                    <a v-if="subscriptionData?.is_activated == true" class="btn btn-light- ff-bold cfa-bg-light-primary f-light no-wrap" href="" style="pointer-events: none; 
                                                    ">
                                                      <!-- {{ subscriptionData[0]?.plan.duration }} utilisateurs -->
                                                      ILLIMITE
                                                    </a>
                                                    <a v-else class="btn btn-light- ff-bold cfa-bg-light-primary f-light no-wrap" href="" style="pointer-events: none; 
                                                    ">
                                                      <!-- {{ subscriptionData[0]?.plan.duration }} utilisateurs -->
                                                      ---
                                                    </a>
                                                  </div>
                                                </div>
                                            </div>
                                            <div v-else class="card-body pt-0">
                                                <div  class="">
                                                    <div class="d-flex justify-content-center align-items-center oups">
                                                        <div class="d-flex flex-column my-4">
                                                            <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 2.5em; margin: auto;"/>
                                                            <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label" style="color: #132B4D">{{ $t('no_subscription') }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
             

              <div v-if="certificatOk" class="ml-0 mt-4">
                <certificate-preview :fname="fname" :lname="lname" :is-ready="dashboardData?.certificat_score>=80"></certificate-preview>
              </div>
            </div>

            <div class="col-12 col-lg-4 ">
              <div class="cfa-welcome py-3">

                <!-- CyberScore Global -->
                <a v-if="Org == true" href="/quiz-final" class="progress-card" style="text-decoration: none;">
                  <div  class="d-flex align-items-center justify-content-between cfa-state-view cfa-bg-primary py-2 px-4 mb-2">
                    <div  class="d-flex justify-content-start align-items-center">
                      <div >
                        <DoughnutChart
                          :valueCountUp="true"
                          :classValue="'chartTextStyle2'"
                          :width="80"
                          :height="80"
                          :percent="Math.floor(cyberscore).toString().substring(0, 3)"
                          :percent-to-display="Math.floor(cyberscore).toString().substring(0, 3)"
                          :visibleValue="true"
                          foregroundColor="rgb(3, 57, 60)"
                          backgroundColor="#fff"
                          :strokeWidth="15"
                        />
                      </div>
                      <div class="ms-2">
                        <h5>{{ $t('progressGlobal') }}</h5>
                      </div>
                    </div>
                    <br/><br/><br/>
                    <div>
                      <!--<i class="fa fa-chevron-right"></i>-->
                    </div>
                  </div>
                </a>

                <a href="/mon-certificat#detail-de-progression" class="progress-card" style="text-decoration: none;">
                  <div class="d-flex align-items-center justify-content-between cfa-state-view cfa-bg-primary py-2 px-4 mb-2">
                    <div class="d-flex justify-content-start align-items-center">
                      <div>
                        <DoughnutChart
                          :valueCountUp="true"
                          :classValue="'chartTextStyle2'"
                          :width="80"
                          :height="80"
                          :percent="Math.floor(dashboardData?.advancement_module).toString().substring(0, 3)"
                          :percent-to-display="Math.floor(dashboardData?.advancement_module).toString().substring(0, 3)"
                          :visibleValue="true"
                          foregroundColor="rgb(3, 57, 60)"
                          backgroundColor="#fff"
                          :strokeWidth="15"
                        />
                      </div>
                      <div class="ms-2">
                        <h5>{{ $t('progress1') }}</h5>
                        <!-- <p>{{Math.floor(dashboardData?.advancement_module).toString().substring(0,3) }} %</p> -->
                      </div>
                    </div>
                    <div>
                      <!--<i class="fa fa-chevron-right"></i>-->
                    </div>
                  </div>
                </a>

                <a href="/quiz-final" class="progress-card" style="text-decoration: none;">
                  <div class="d-flex align-items-center justify-content-between cfa-state-view  cfa-bg-primary py-2 px-4 mb-2">
                    <div class="d-flex justify-content-start align-items-center">
                      <div>
                        <DoughnutChart
                          :valueCountUp="true"
                          :classValue="'chartTextStyle2'"
                          :width="80"
                          :height="80"
                          :percent=Math.floor(cyberscores).toString().substring(0,3) 
                          :percent-to-display="Math.floor(cyberscores).toString().substring(0,3) "
                          :visibleValue="true"
                          foregroundColor="rgb(3, 57, 60)"
                          backgroundColor="#fff"
                          :strokeWidth="15"
                        />
                      </div>
                      <div class="ms-2">
                        <h5>{{ $t('progress5') }}</h5>
                        <!--<p>{{ Math.floor(dashboardData?.certificat_score).toString().substring(0,3) }} Leçons</p>-->
                      </div>
                    </div>
                    <div>
                      <!--<i class="fa fa-chevron-right"></i>-->
                    </div>
                  </div>
                </a>

                <!--
                <a href="/rendez-vous-expert" style="text-decoration: none;">
                  <div class="d-flex align-items-center justify-content-between cfa-state-view  cfa-bg-primary py-2 px-4">
                    <div class="d-flex justify-content-start align-items-center">
                      <div>
                        <DoughnutChart
                          :valueCountUp="true"
                          :classValue="'chartTextStyle2'"
                          :width="80"
                          :height="80"
                          time="yes"
                          :percent=getPercent(dashboardData?.appointment_time,30).toString().substring(0,3)
                          :percentToDisplay="dashboardData?.appointment_time.toString().substring(0,3)"
                          :visibleValue="true"
                          foregroundColor="rgb(3, 57, 60)"
                          backgroundColor="#b9ebec"
                          :strokeWidth="15"
                        />
                      </div>
                      <div class="ms-2">
                        <h5>{{ $t('progress3') }}</h5>
                        <p>Total: 30 minutes</p>
                      </div>
                    </div>
                    <div>
                      --><!--<i class="fa fa-chevron-right"></i>--><!--
                    </div>
                  </div>
                </a>
                -->
              </div>

              <div class="cfa-welcome py-3">
                <div>
                  <strong>{{ $t('progress4') }}: <br>({{ module_covered }} modules / {{ this.dashboardData?.numb_module }})</strong>
                </div>
                <div>
                  <progress-bar v-for="progress in module_progress"  v-bind:key="progress" :moduleName="'Module '+progress.module" :percent="progress.progress" :label="progress.progress+'%'" style="padding-top: 1em;"></progress-bar>
                </div>
              </div>
              <div class="cfa-welcome">
                <div class="py-4 d-flex align-items-center justify-content-between">
                  <h5 class="">{{ $t('progress_status') }}</h5>
                </div>
                <p class="text-md-left text-justify mb-0" style="color: #132B4D">
                  {{ $t('certificate_of_resilience') }}
                </p>
                <div>
                  <br>
                  <progress-bar
                   :moduleName="`<b class='ff-bold' style='color: var(--primary-color);'>${$t('my_progress')}:</b>`" 
                   :percent="(dashboardData?.module_finish*100)/this.dashboardData?.numb_module || (dashboardData?.advancement_module*100)/this.dashboardData?.numb_module" backgroundColor="#d9d9d9" forgroundColor="var(--primary-color)" :label="Math.ceil(((dashboardData?.module_finish/this.dashboardData?.numb_module)*100) + ' %') || (dashboardData?.advancement_module  + ' %')"></progress-bar>
                  <br>
                  <progress-bar 
                   :moduleName="`<b class='ff-bold' style='color: var(--primary-color);'>${$t('quiz_score')}:</b>`"
                   :percent="(dashboardData?.quizz_finish*100)/this.dashboardData?.numb_module" backgroundColor="#d9d9d9" forgroundColor="red" :label="Math.ceil((dashboardData?.quizz_finish*100)/this.dashboardData?.numb_module)+'%'"></progress-bar>
                </div>
              </div>

            </div>
          </div>
        </div>
        
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>

</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import CFAVideo from '@/components/shared/video/CFAVideo.vue'
import CertificatePreview from '@/components/shared/certificate/CertificatePreview.vue'
import DoughnutChart from '@/components/shared/chart/DoughnutChart.vue'
import ProgressBar from '@/components/shared/chart/ProgressBar.vue'
import DeadLine from '@/components/shared/DeadLine.vue'
import {getAPI} from "@/axios-api";

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav,
    CFAVideo,
    CertificatePreview,
    DoughnutChart,
    ProgressBar,
    DeadLine
  },
  //props: (route) => ({ organisation: route.query.organisation }),
  data () {
    return {
        waitingAPIResponse: true,
        alertString: '',
        dashboardData: null,
        Org:false,
        data:null,
        username: (sessionStorage.getItem('firstname') != 'null' && sessionStorage.getItem('lastname') != 'null') ? sessionStorage.getItem('firstname') + ' ' + sessionStorage.getItem('lastname') : '',
        fname: sessionStorage.getItem('firstname'),
        lname: sessionStorage.getItem('lastname'),
        module_progress: [],
        module_covered: 0,
        formatedDealine: [],
        showAlert: (sessionStorage.getItem('firstname') == 'null' && sessionStorage.getItem('lastname') == 'null'),
        playVideo: sessionStorage.getItem('firstname'),
        userData: null,
        cyberscore:null,
        firstname: null,
        lastname: null,
        deadLine:null,
        role: null,
        phonePersonal: null,
        phoneProfessional: null,
        uploadPending: false,
        subscriptionData: [],
        currentDateDay: 0,
        certificatOk: false,
        remain_days: "",
        expiration_date: "",
        cyberscores:0
    }
  },
  methods: {
    async getAllQuiz() {
      try {
        this.waitingAPIResponse = true;
        const [response1] = await Promise.all([
          getAPI.get(`stat_quizz?user_id=${sessionStorage.user}`),
        ]);
        if(response1.status == 200) {
          this.data=response1.data;
          this.cyberscores=this.data[0].note;
          console.log("Ici: ", this.data);
          this.quiz = (response1.data.results.length > 0) ? response1.data.results[0] : null;
          
          console.log("Quiz id: ", this.quiz.id)
          if (this.quiz !== null) {
            //this.getQuizStat();
          }
          //this.getQuizStat();
          
          this.waitingAPIResponse = false
        }
        else {
          this.waitingAPIResponse = false
          //this.alertString = `Une premiere erreur est survenu|alert-no|4000::`+Date.now()
        }
      }
      catch (e) {
        console.log("Une erreur est survenue dans getAllQuiz :", e);
        //this.alertString = `Une seconde erreur est survenu|alert-no|4000::`+Date.now()
      }
    },
    getFedaConfirmationPayment() {
      this.waitingAPIResponse = true
      const id = this.$route.query.id;

      if (id) {
        getAPI.post(`transactions/feda-confirmation`, { code: id })
          .then(response => {
            if (response.status === 200) {
              ////this.alertString = "Activation of subscription via feda successful.|alert-yes|5000::"+Date.now()
              console.log("Activation of subscription via feda successful !")
            } else {
                //console.log("Confirmation failed !")
            }
          })
          .catch(/*error*/() => {
            //console.error("Erreur lors de la requête API : " + error);
          });
      } else {
        //console.error("The 'id' parameter is missing from the URL.");
      }
    },
    updateUserProfil () {
          if(this.firstname.length > 2 && this.lastname.length > 2 && this.phonePersonal.length > 5) {
              getAPI.put(`accounts/users/${sessionStorage.user}/`, {
                  firstname: this.firstname,
                  lastname: this.lastname,
                  phone: this.phonePersonal,
                  //role: this.role,
              }).then(response => {
                  if(response.status == 200) {
                      sessionStorage.setItem('firstname', this.firstname)
                      sessionStorage.setItem('lastname', this.lastname)
                      ////this.alertString = "Profil mis a jour avec succès|alert-yes|5000::"+Date.now()
                      this.silentRefresh = Date.now();
                      this.$router.go(0);
                  }
                  else{
                      ////this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
                  }
              }).catch(() => {
                 // //this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
              })
          }
          else{
              ////this.alertString = "Veuillez remplir les champs correctement.|alert-no|5000::"+Date.now()
          }
    },
   
    async uploadImage() {
          const file = this.$refs.fileSelector.files[0];
          const formData = new FormData();
          formData.append('image_url', file);

          this.uploadPending = true
          getAPI.put(`accounts/users/upload-profile/${sessionStorage.user}/`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }).then((payload) => {
              this.userData.image_url = payload.data.data.image_url
              ////this.alertString = "Profil mis a jours avec succès.|alert-yes|5000::"+Date.now()
              this.silentRefresh = Date.now();
              this.uploadPending = false
          }).catch((err) => {
             // //this.alertString = "Fichier trop large.|alert-no|5000::"+Date.now()
              this.uploadPending = false
              console.log(err)
          });
    },
    openFileSelector() {
        this.$refs.fileSelector.click();
    },
    getPercent(value, maxValue) {
        if (maxValue === 0) {
            return 0;
        }
        return Math.min(100, Math.round((value / maxValue) * 100));
    },
    formatDate (value) {
      if (!value) return ''
      const date = new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      const hour = String(date.getHours()).padStart(2, '0')
      const minute = String(date.getMinutes()).padStart(2, '0')
      return [day, month, year, hour, minute]
    },
    isOrg () {
      if(sessionStorage.getItem('type_account')  == 'organisation'){
        this.Org=true;
      }
      else{
        this.Org=false;
      }
      //return sessionStorage.getItem('type_account')  == 'organisation';
    },
    actualDate() {
      const actualDate = new Date();
      const currentDate = this.formatDate(actualDate);
      const currentDay = currentDate[0];
      this.currentDateDay = currentDay;
      //return currentDay;
    },
    retrieveUserTransactions() {
      this.waitingAPIResponse = true
      getAPI.get(`/transactions?user_id=${sessionStorage.getItem('user')}`)
        .then(response => {
          if (response.status == 200) {
            this.transactionsData = response.data

            this.transactions = []

            const lastTransaction = this.transactionsData[this.transactionsData.length - 1]

            this.lastTransactionPlan = lastTransaction.plan.name;

          }
          else {
            ////let msg = 'Oups ! something went wrong.'
            ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        }).catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            ////let msg = 'Oups ! something went wrong.'
            ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
    },
    retrieveUserSubscription () {
          this.waitingAPIResponse = true
          getAPI.get(`subscriptions/find-subscription`).then(response => {
            if(response.status == 200) {
              this.subscriptionData = response.data.data
              console.log('subscription data', this.subscriptionData);

              this.remain_days = this.subscriptionData.remain_days

              const created_at = new Date(this.subscriptionData.created_at)
              created_at.setDate(created_at.getDate() + this.remain_days);
              
              const expirationDate = created_at.toISOString();

              this.expiration_date = expirationDate

              //console.log("Tatata: ", this.subscriptionData)
            }
            else {
               // //let msg = 'Oups ! something went wrong.'
                ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
                this.waitingAPIResponse = false
            }, 2000);
          }).catch(error => {
              if (error.response) {
                  //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                  //let msg = 'The request was made but no response was received. Please check your network.'
                  //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                 // //let msg = 'Oups ! something went wrong.'
                  ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse = false
          })
    },
    retrieveUserData () {
        getAPI.get('accounts/users/me')
            .then(response => {
                if(response.status == 200) {
                    this.userData = response.data.data
                    this.firstname = this.userData.firstname
                    this.lastname = this.userData.lastname
                    this.role = this.userData.role
                    this.phonePersonal = this.userData.phone
                    this.phoneProfessional = this.userData.phone
                }
                else {
                 //   //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
                }
            })
            .catch(error => {
                  if (error.response) {
                 //     //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
                  }
                  else if (error.request) {
                  //    //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
                  }
                  else {
                 //     //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
                  }
              })
    },
    convertDate(dateString) {
              const date = new Date(dateString);
              const formattedDate = date.toLocaleDateString();
              //const formattedTime = date.toLocaleTimeString();
              
              return formattedDate;
          },
     get_time_passed(old_date) {
	const now = new Date();
  const pastDate = new Date(old_date)
  console.log(this.convertDate(pastDate.getTime()));
	const diff = now.getTime() - this.convertDate(pastDate.getTime());
	return diff; 
},
    getDashboardData() {
      this.waitingAPIResponse = true
      getAPI.get(`other/dashboard`).then(response => {

      if(response.status == 200) {
        this.dashboardData = response.data.data

        if(this.dashboardData != null) {
          this.formatedDealine = this.formatDate(this.dashboardData.deadline)
          this.deadLine =this.get_time_passed(this.dashboardData.deadline);
          console.log("dates",this.deadLine)
        }
      }
      else {
        //let msg = 'Oups ! something went wrong.'
       // //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      setTimeout(() => {
        this.waitingAPIResponse = false
      }, 2000);
    }).catch(error => {
      if (error.response) {
        ////this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
      }
      else if (error.request) {
        //let msg = 'The request was made but no response was received. Please check your network.'
        ////this.alertString = `${msg}|alert-no|8000::`+Date.now()
      }
      else {
        //let msg = 'Oups ! something went wrong.'
        ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      this.waitingAPIResponse = false
    })
    },
    hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    retrieveOrg() {
      getAPI.get(`organisations/${sessionStorage.organisation}/`)
        .then(response => {
          if (response.status == 200) {
            this.organisationData = response.data
            this.primary = this.organisationData.primary_color;
            this.secondary = this.organisationData.secondary_color;
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    saveColor() {
      const payload = {
          primary_color: this.primary_color,
          secondary_color: this.secondary_color,
          tertiary_color: this.tertiary_color,
      };
      getAPI.put(`organisations/change_color/${sessionStorage.organisation}/`, payload)
        .then(response => {
          if (response.status === 200) {
            console.log('response', response);
            if(response.data.data){
              // localStorage.setItem('primaryColor', this.primary);
              // localStorage.setItem('secondaryColor', this.secondary);
              localStorage.setItem('tertiaryColor', response.data.data.tertiary_color);
            }
            this.alertString = "Color changed successfully |alert-yes|5000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            console.log('respose',error.response);
            this.alertString = `Upgrade your plan for change color|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    activeColor() {
      const primaryColor = this.primary;
      if(primaryColor){
        const primaryRgb = this.hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = this.secondary;
      if(secondaryColor){
        const secondaryRgb = this.hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    },
    async setPrimaryColor() {
      this.primary_color = this.primary;
      this.saveColor()
      const actualColor = this.primary;
      const rgb = this.hexToRgb(this.primary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--primary-color', actualColor);
      document.documentElement.style.setProperty('--primary-color-r', r);
      document.documentElement.style.setProperty('--primary-color-g', g);
      document.documentElement.style.setProperty('--primary-color-b', b);
    },
    async setSecondaryColor() {
      this.secondary_color = this.secondary;
      this.saveColor();
      const rgb = this.hexToRgb(this.secondary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--secondary-color', this.secondary);
      document.documentElement.style.setProperty('--secondary-color-r', r);
      document.documentElement.style.setProperty('--secondary-color-g', g);
      document.documentElement.style.setProperty('--secondary-color-b', b);
    },
  },
  mounted() {
    this.isOrg();
    this.getDashboardData();
    this.getFedaConfirmationPayment();
    this.retrieveUserData();
    this.actualDate();
    this.retrieveUserSubscription();
    
    this.retrieveUserTransactions();
    this.getAllQuiz();
    

    this.waitingAPIResponse = true
    getAPI.get(`stat_module?user_id=${sessionStorage.user}`).then(response => {
      if(response.status == 200) {
        let progress = response.data
        this.module_covered = progress.length
        for (let i = 0; i < progress.length; i++) {
          this.module_progress.push({module: progress[i].module.number, progress: (progress[i].progression == 0) ? 1 : progress[i].progression })
        }
        for (let i = this.module_progress.length; i < this.dashboardData?.numb_module; i++) {
          this.module_progress.push({module: i+1, progress: 0 })
        }
      }
      else {
        //let msg = 'Oups ! something went wrong.'
        ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      setTimeout(() => {
        this.waitingAPIResponse = false
      }, 2000);
    }).catch(error => {
      if (error.response) {
       // //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
      }
      else if (error.request) {
        //let msg = 'The request was made but no response was received. Please check your network.'
        ////this.alertString = `${msg}|alert-no|8000::`+Date.now()
      }
      else {
        //let msg = 'Oups ! something went wrong.'
     //   //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      this.waitingAPIResponse = false
    }),
    
     getAPI.get(`other/cyberscores-global`).then(response => {
          if (response.status == 201) {
              this.cyberscore = response.data.data.cyberScore;
              console.log("Data: ", this.dashboardData)
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          setTimeout(() => {
              this.waitingAPIResponse = false
          }, 2000);
      }).catch(error => {
          if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::` + Date.now()
          } else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::` + Date.now()
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          this.waitingAPIResponse = false
      })
  }
}
</script>

<style scoped>
.cfa-page{

}
.gFolder-container{
  background-color: var(--primary-color); /*#cef1f3;*/
  border-radius: 0.5em;
  height: 4.3em;
  width: 4.6em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gBlock{
  align-items: center;
  justify-content: start;
  border-left: 4px solid var(--primary-color);
  border-radius: 0.6em;
  padding-left: 2em;
  padding-right: 2em;
  height: 8em !important;
}
.gBlock div:last-of-type p{
  margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
  font-weight: 400;
  font-size: 2em;

}
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  /* margin: .5em; */
}

.cfa-alert-d{
  border-bottom: 1px solid #f3f3f3;
  transition: all ease-in .3s;
}
.cfa-alert-d:last-of-type{
  border: none;
}
.cfa-state-view{
  border-radius: .5em;
}
.cfa-state-view:hover{
  background: var(--secondary-color) !important;
}

.welcome-username {
  color:rgb(50,204,218);
}
.cfa-root {
  font-weight: 400;
}
.cfa-section-title-dashboard{
  color: #fff;
}
.cfa-avatar1 div {
    display: flex;
    margin: 0px;
    color: #fff !important;
    font-weight: 500;
    font-weight: 400;
}
#cfa-nav-bottom-swiper {
    padding: 0px !important;
}
.cfa-avatar1{
  background: #fff !important;
  padding-right: 5px !important;
}


</style>
