<template>
  <div class="cfa-nav-btn" :style="style">
    <router-link v-if="isLink" :class="'cfa-nav-link '+moreCss" :to="{name: this.routeName}">
      <button class="btn cfa-nav-button" :title="label">
        <slot name="icon"></slot>
        <span v-if="menuMode == 'LARGE'" class="mText  d-md-block" :style="'white-space: nowrap; ' + cssStyle">&nbsp;&nbsp;&nbsp;{{ this.label }}</span>
      </button>
    </router-link>
    <button v-else class="btn cfa-nav-button" :title="label">
      <slot name="icon"></slot>
      <span v-if="menuMode == 'LARGE'" class="mText  d-md-block" :style="'white-space: nowrap; ' + cssStyle">&nbsp;&nbsp;&nbsp;{{ this.label }}</span>
    </button>
  </div>
</template>

<script>

export default {
  name: 'NavButton',
  props: {
    style: {
      type: String,
      default: '',
    },
    routeName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'BUTTON'
    },
    isLink: {
      type: Boolean,
      default: true
    },
    menuMode: {
      type: String,
      default: 'LARGE'
    },
    moreCss: {
      type: String,
      default: ''
    },
    cssStyle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scope>
.cfa-link-active .btn .gIcon{
  filter: brightness(0) invert(1);
}
.cfa-link-active .btn .mText{
  color: #fff;
}
.cfa-nav-btn a{
  text-decoration: none;
}
.cfa-nav-btn a button span{
  display: flex;
  align-items: center;
  padding-top: .2em;
}
.cfa-nav-btn button{
  padding: 8px 10px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left; 
}
.mText{
  font-weight: 400;
  font-size: 1.1em;
  /*color: #9ea1ac;*/
  color: #2f3247;
}
.cfa-link-active button{
  background-color: var(--primary-color);
  color: #fff;
  transition: all ease-in .2s;
  -webkit-box-shadow: 4px 18px 19px -8px var(--primary-light-color);
  box-shadow: 4px 18px 19px -8px var(--primary-light-color);
}
.cfa-link-active .cfa-nav-button:hover{
    transition: all ease-in .2s;
    background-color: var(--primary-color);
}
.cfa-nav-button{
  border: none !important;
}
.cfa-primary .cfa-nav-link .cfa-nav-button .mText {
  color: var(--primary-color);
}
.cfa-nav-link:hover > button span {
  color: var(--primary-color);
}
.cfa-nav-link-active > button span {
  color: #fff !important;
}
</style>