<template>
  <div :id="uid+'-wrapper'" class="cfa-slider-background hidden" style="z-index: 2049;;">
    <div class="cfa-modal-wrapper">
      <transition name="fadeY" appear mode="out-in">
        <div v-if="open" class="cfa-modal-wrapper">
          <div :id="uid" class="cfa-modal-content" :style="style">
            <!-- top -->
            <div class="text-end">
              <button v-if="!disableQuit" class="btn cfa-btn-light-primary modal-quit" @click="close">
                <i class="fa fa-close"></i>
              </button>
            </div>

            <!-- content -->
            <div class="cfa-modal-text-content">
              <slot name="content"></slot>
            </div>

            <!-- end -->
            <div v-if="!disableControl">
              <div v-if="!advancedControl" class="justify-content-end d-flex">
                <button class="btn cfa-btn-secondary mx-1" @click="close">
                  {{ exitButtonText }}
                </button>
                <button class="btn cfa-btn-secondary" @click="close">
                  <i class="fa fa-check"></i>&nbsp;{{ validationButtonText }}
                </button>
              </div>
              <div v-else class="justify-content-end d-flex">
                <button class="btn cfa-btn-secondary mx-1" @click="close">
                  {{ exitButtonText }}
                </button>
                <button v-if="!waitingAPI" class="btn m-btn2 cfa-btn-secondary" @click="exec">
                  <i class="fa fa-check"></i>&nbsp;{{ validationButtonText }}
                </button>
                <button v-else class="btn m-btn2 cfa-btn-secondary">
                  <img  width="20" src="@/assets/img/gif/load2.gif" alt="loader"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition name="fadeY" appear mode="out-in">
      <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
    </transition>
  </div>
</template>

<script>
import Alert from '@/components/shared/alert/Alert.vue'

export default  {
  name: 'ScalableModal',
  components: {
    Alert
  },
  props: {
    uid: {
      type: String,
      default: 'gSlider01'
    },
    open: {
      type: Boolean,
    },
    style: {
      type: String,
    },
    validationButtonText: {
      type: String,
      default: 'Valider'
    },
    exitButtonText: {
      type: String,
      default: 'Annuler'
    },
    disableQuit: {
      type: Boolean,
      default: false
    },
    disableControl: {
      type: Boolean,
      default: false
    },
    advancedControl: {
      type: Boolean,
      default: false
    },
    notifyTaskDone: {
      type: String,
    },
    break: {
      type: String
    },
    displayMode: {
      type: String,
      default: 'block'
    }
  },
  data () {
    return {
      waitingAPI: false,
      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',
      waitingAPIResponse: false
    }
  },
  methods: {
    displayError(error, type='alert-ok', time=5000) {
      this.gAlertMessage = error
      this.gAlertType = type
      this.alertMe = true
      setTimeout(() => {
        this.alertMe = false
      }, time);
    },
    close () {
      document.getElementById(this.uid).style.right = "-50vw"
      setTimeout(() => {
        document.getElementById(this.uid+'-wrapper').style.display="none"
        document.getElementById(this.uid+'-wrapper').classList.add('hidden')
      }, 300);
      this.$emit('close')
    },
    exec () {
      this.waitingAPI = true
      this.$emit('callback')
    }
  },
  watch: {
    open: function () {
      console.log('trace_modal', this.open)
      if(this.open) {
        this.waitingAPI = false
        document.getElementById(this.uid+'-wrapper').style.display=this.displayMode
        document.getElementById(this.uid+'-wrapper').classList.remove('hidden')
        setTimeout(() => {
          document.getElementById(this.uid).style.right = "0"
        }, 100);
      }
    },
    notifyTaskDone: function () {
      console.log(this.notifyTaskDone)
      let type = (this.notifyTaskDone.split('::')[1] == '-1') ? 'alert-no' : 'alert-yes';
      this.displayError(this.notifyTaskDone.split('::')[0], type, 4000)
      setTimeout(() => {
        this.close()
      }, 4000)
    },
    break: function () {
      this.close()
    }
  }
}
</script>

<style scoped>
.hidden{
  opacity: 0;
}
.cfa-slider-background{
  background-color: #0000005e;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100vw;
  overflow: auto;
  display: none;
  transition: all ease-in .3s;
}
.cfa-modal-wrapper{
  width: 100vw;
  /*height: 100vh;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.cfa-modal-content{
  width: 80vw;

  padding: 2em;
  background-color: #fff;
  transition: all ease-in .3s;
  border-radius: .5em;
}
.gEmpty{
  width: 100vw;
}
.cfa-go-back{
  width: 3em !important;
  margin-right: 0.3em;
}
.cfa-go-back i{
  position: relative;
  top: .2em;
}
.cfa-modal-text-content{
  height: calc(100% - 4.5em);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cfa-go-back:hover{
  background-color: #585f73;
  color: #fff;
}
.modal-quit{
  position: relative;
  top: -.5em;
}
.m-btn2{
  min-width: 7em;
}
</style>
