<template>
  <div class="g-progress">
    <div :class="(this.active) ? 'g-icon g-fill-green' : 'g-icon'" >
      <i :class="(this.active) ? 'fa fa-unlock' : 'fa fa-lock'"></i>
    </div>
    <div class="g-loader">
      <div class="g-progress-box" :style="(this.active) ? getProgression() : ''"></div>
      <div class="g-progress-text"> {{ (this.showText) ? content : '' }} </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CFAProgress',
  props: {
    percent: {
      type: Number,
      default: 0,
      required: true,
      validator: val => val >= 0 && val <= 100
    },
    active: {
      type: Boolean,
      default: false,
      required: true
    },
    showText: {
      type: Boolean,
      default: false
    },
    format: Function
  },
  data () {
    return {
      idNow: ''
    }
  },
  computed: {
    content () {
      if (typeof this.format === 'function') {
        return this.format(this.percent) || ''
      } else {
        if (this.active) {
          return `${this.percent}%`
        } else {
          return '0%'
        }
      }
    }
  },
  mounted () {
    this.idNow = this.getUUID()
  },
  methods: {
    getUUID () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
      })
    },
    getProgression () {
      return 'width: ' + this.percent + '%;'
    }
  }
}
</script>

<style>
  .g-progress {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #606266;
    font-size: 14px;
    margin-right: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    background-color: var(--primary-light-color);
    width: 100%;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    position: relative;
  }
  .g-icon{
    /*padding: 0.7em;*/
    border-radius: 50%;
    height: 2.5em;
    width: 2.5em;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    left: -0.3em;
    z-index: 11;
    box-shadow: 20px 20px 123px -50px rgba(0,0,0,0.87);
    -webkit-box-shadow: 20px 20px 123px -50px rgba(0,0,0,0.87);
    -moz-box-shadow: 20px 20px 123px -50px rgba(0,0,0,0.87);
  }
  .g-loader{
    background-color: var(--primary-light-color);
    width: 100%;
    height: 1.5em;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    position: relative;
    display: flex;
  }
  .g-progress-box{
    background-color: var(--primary-color);
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .g-progress-text{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 0.9em;
    margin-top: 0.15em;
    /*background-color: #2a96a5;*/
  }
  .g-fill-green{
    background-color: var(--primary-color);
    color: #fff;
  }
</style>
