<template>
    <div :id="uid" :style="(filterMode == 'ALL') ? 'display: block' : ((bookMarked == true) ? 'display: block' : 'display: none')" :class="(viewMode == 'GROUP') ? 'cfa-module col-12 col-md-3 mb-4' : 'cfa-module col-12 mb-4'">
        <div :id="uid+'-content'" :class="(viewMode == 'GROUP') ? 'p-3 bg-white cfa-course-module-inner cfa-module-shadow' : 'p-3 bg-white cfa-course-module-inner d-flex flex-row cfa-module-shadow'">
          <div>
            <div class="cfa-module-image position-relative" :id="uid+'-cts'" style="min-width: 14em;">
                <img v-if="preview != ''" :src="preview" alt="cfa module course image"/>
                <img v-else style="width: 35% !important; height: 35% !important; min-height: auto;" src="@/assets/img/png/cloud.png" alt="cfa module course image"/>
                <!--<img v-else style="width: 100% !important; height: 35% !important; min-height: auto;" src="https://picsum.photos/700/450" alt="cfa module course image"/>-->
                <!--<img v-else style="width: 25% !important; height: 35% !important; min-height: auto;" src="../../../assets/Icons/All_Icon/Group-1.png" alt="cfa module course image"/>-->
              <button @click="bookMark" class="gType btn">
                <img v-if="bookMarked" width="20" style="width: 2em !important; min-height: auto !important;" src="../../../assets/Icons/All_Icon/Vector-17.png" alt="icon"/>
                  <img v-else width="20" style="width: 2em !important; min-height: auto !important;" src="../../../assets/Icons/All_Icon/Vector-18.png" alt="icon"/>
              </button>
              <!-- <button style="text-transform: uppercase;" class="gType2 btn btn-discret cfa-btn-primary">{{type}}</button> -->
              <!--<button style="text-transform: uppercase;" class="gType2 btn btn-discret">•••</button>-->
              <div class="gType2">
                  <select-button v-if="unlock" @action="getOptionAction" label="•••" :uid="'dcfa-dropdown-'+uid" :dropDownLabel="['Ouvir', 'Télécharger']" :showArrow="false" cssStyle="border: none !important;"></select-button>
                  <select-button v-else @action="getOptionAction" label="•••" :uid="'dcfa-dropdown-'+uid" :dropDownLabel="['Non disponible sans souscription !']" :showArrow="false" cssStyle="border: none !important;"></select-button>
              </div>
            </div>
          </div>
          <div :class="(viewMode == 'GROUP') ? '' : 'ms-3 w-100'">
            <div class="py-2 pt-4">
              <!-- <a :href="link" class="linke"> -->
              <router-link v-if="unlock"  :id="'link-'+uid" class="linke" :to="{name: 'ResourcesIframe', params: {id: id, slug: title.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')}}">
                <h2 style="height: 2.5em; font-size: 1.1em; overflow: hidden; text-align: left;"><strong>{{title.toUpperCase()}}</strong></h2>
              </router-link>
              <h2 v-else style="height: 2.5em; font-size: 1.1em; overflow: hidden; text-align: left;"><strong>{{title.toUpperCase()}}</strong></h2>
              <!-- </a> -->

            </div>
            <div style="height: 5em; text-align: justify; overflow: hidden;" class="py-2 pb-3 text-justify" v-html="description"></div>
          </div>
        </div>
    </div>
</template>

<script>
import SelectButton from '../../../components/shared/button/SelectButton.vue'

export default {
  name: 'CourseCard2',
  components: {
    SelectButton
  },
  props: {
    uid: {
      type: String,
      default: 'cfa-course-01'
    },
    link: {
      type: String,
      default: '#'
    },
    preview: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'FORENSIC'
    },
    title: {
      type: String,
      default: 'Sécurité du post de travail.'
    },
    id: {
      type: String,
    },
    description: {
      type: String,
      default: 'lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem'
    },
    started: {
      type: Boolean,
      default: true
    },
    max__horaire: {
      type: String,
      default: '22'
    },
    viewMode: {
      type: String,
      default: 'GROUP'
    },
    filterMode: {
      type: String,
      default: 'ALL'
    },
    marked: {
      type: Boolean,
      default: true
    },
    unlock: {
        type: Boolean,
        default: false,
    }
  },
  data () {
    return {
      bookMarked: this.marked
    }
  },
  methods: {
    bookMark () {
      document.getElementById(this.uid+'-cts').style.backgroundColor='var(--primary-color)';
      setTimeout(() => {
        document.getElementById(this.uid+'-cts').style.backgroundColor='transparent';
      }, 500);
      this.bookMarked = !this.bookMarked
      // --------
      // API CALL
      // --------
    },
    goTo () {
      let a = document.createElement('a')
      a.href=this.link
      a.click()
    },
    getOptionAction (payload) {
      console.log(payload)
      if(payload == '01') {
        document.getElementById('link-'+this.uid).click();
      }
      else {
        let a = document.createElement('a')
        a.setAttribute('href', this.link)
        a.setAttribute('download', true)
        a.click()
        a.remove()
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.cfa-course-module-inner{
  border-radius: .5em;
}
.cfa-module-image{
  /* width: 500px; */
  border: 2px solid #f0f1f6;
  border-radius: .5em;
  overflow: hidden;
  min-height: 14em;
  max-height: 14em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cfa-module-image img{
  width: 100%;
  min-height: 200px;
}
.gType{
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.gType2{
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
.cfa-module{
  transition: all ease-in .3s;
}
.cfa-module-shadow{
  /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.linke{
  color: #000;
  text-decoration: none;
}
.linke:hover{
  text-decoration: underline;
  color: var(--primary-color);
}
</style>
