<template>
    <div class="input-container-wrapper form-group">
        <div class="d-flex w-100">
            <button class="country-list-btn" @click="showCountryList" @blur="hideCountryList">
                <span>{{ this.countrySelected.flag }}</span>
                <span class="arrow-down"></span>
            </button>
            <input class="input input-1r input-3r form-control form-control-alternative"  type="text" :value="'+'+this.countrySelected.code"/>
            <input @input="setInput" v-model="input" :id="uid+'-input'" :type="type" :placeholder="placeholder" class="input input-1r input-2r form-control form-control-alternative" @focus="moveLabel" @blur="reinitLabel" style="margin-left:50px;width:300px;">
        </div>
        <label :for="uid+'-input'" :id="uid+'-label'" class="label translation animated-label form-control-label" style="color:#808080;">{{placeholder}}</label>
        <div :id="uid+'-error1'" role="img" svgicon="icon-warning" class="alert-container">
            <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
                    <path d="M12,0 C18.6274821,0 24,5.37128483 24,11.9992057 C24,18.6271265 18.6274821,24 12,24 C5.37251787,24 0,18.6271265 0,11.9992057 C0,5.37128483 5.37251787,0 12,0 Z M12.0610526,16.2921801 C11.6274111,16.2921801 11.2470743,16.4443148 10.9496045,16.7417846 C10.6509806,17.0404085 10.5,17.4272963 10.5,17.8721801 C10.5,18.3192007 10.6525981,18.7054646 10.9552603,18.9986686 C11.2526499,19.2867647 11.6306317,19.4332327 12.0610526,19.4332327 C12.4824424,19.4332327 12.853225,19.2839566 13.1440797,18.9931019 C13.4373102,18.6998714 13.5842105,18.316035 13.5842105,17.8721801 C13.5842105,17.4304543 13.4388629,17.0459864 13.1496464,16.7474404 C12.8588101,16.4472222 12.48574,16.2921801 12.0610526,16.2921801 Z M12.0421053,3.6 C10.7366943,3.6 10.382605,3.91195124 9.99908447,4.38586429 C9.61556392,4.85977733 9.57781767,5.35178251 9.61556392,5.78586438 L9.61556392,5.78586438 L11.0691213,13.9428436 C11.090737,14.2257 11.1830848,14.4623412 11.3544724,14.6459708 C11.5367791,14.8412994 11.7761787,14.9469169 12.0421053,14.9469169 C12.3006556,14.9469169 12.5353068,14.8502958 12.7177639,14.6678387 C12.9005338,14.4850688 12.9939611,14.2398222 13.0145188,13.9520141 L13.0145188,13.9520141 L14.4698261,5.76900254 C14.5063929,5.35178251 14.338391,4.72918704 14.0294373,4.38586429 C13.7204835,4.04254153 13.3475162,3.6 12.0421053,3.6 Z"></path>
                </g>
            </svg>
        </div> 
        <span :id="uid+'-error2'" style="margin-top:20px;" class="alert-text"> {{ errorText }} </span>
        <div v-if="type == 'password'" @click="hideOrShow" :id="uid+'-toogle'" class="show-eye toggle-password" :style="'right: '+floatingRight+'px;'"></div>
        <select @blur="hideCountryList" v-model="selectedCountryIndex" @change="updatePrefix" :id="uid + 'countryListSelect'" class="countryList d-none">
            <option v-for="country, i in countries" :value="i" :key="i" :selected="country.code === defaultCountryCode" :disabled="country.disabled">
                {{ country.flag + ' ' + country.name }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "PhoneInput",
    props: {
        uid: {
            type: String,
            default: 'cfa-uuid'
        },
        placeholder: {
            type: String,
            default: ''
        },
        errorText: {
            type: String,
            default: 'Incorrect value'
        },
        type: {
            type: String,
            default: 'text'
        },
        defaultCountryCode: {
            type: String,
            default: '229'
        },
        syncField: {
          type: String,
          default: null
        }
    },
    data () {
        return {
            hideInput: true,
            floatingRight: 7,
            selectedCountryIndex: 0,
            countries: [
                { name: 'Selectionnez votre indicatif', code: '', flag: '', disabled: true },
                { name: 'Afghanistan', code: '93', flag: '🇦🇫' },
                { name: 'Albania', code: '355', flag: '🇦🇱' },
                { name: 'Algeria', code: '213', flag: '🇩🇿' },
                { name: 'American Samoa', code: '1684', flag: '🇺🇸' },
                { name: 'Andorra', code: '376', flag: '🇦🇩' },
                { name: 'Angola', code: '244', flag: '🇦🇴' },
                { name: 'Anguilla', code: '1264', flag: '🇦🇮' },
                { name: 'Antarctica', code: '672', flag: '🏔️' },
                { name: 'Antigua and Barbuda', code: '1268', flag: '🇦🇬' },
                { name: 'Argentina', code: '54', flag: '🇦🇷' },
                { name: 'Armenia', code: '374', flag: '🇦🇲' },
                { name: 'Aruba', code: '297', flag: '🇦🇼' },
                { name: 'Australia', code: '61', flag: '🇦🇺' },
                { name: 'Austria', code: '43', flag: '🇦🇹' },
                { name: 'Azerbaijan', code: '994', flag: '🇦🇿' },
                { name: 'Bahamas', code: '1242', flag: '🇧🇸' },
                { name: 'Bahrain', code: '973', flag: '🇧🇭' },
                { name: 'Bangladesh', code: '880', flag: '🇧🇩' },
                { name: 'Barbados', code: '1246', flag: '🇧🇧' },
                { name: 'Belarus', code: '375', flag: '🇧🇾' },
                { name: 'Belgium', code: '32', flag: '🇧🇪' },
                { name: 'Belize', code: '501', flag: '🇧🇿' },
                { name: 'Benin', code: '229', flag: '🇧🇯' },
                { name: 'Bermuda', code: '1441', flag: '🇧🇲' },
                { name: 'Bhutan', code: '975', flag: '🇧🇹' },
                { name: 'Bolivia', code: '591', flag: '🇧🇴' },
                { name: 'Bosnia and Herzegovina', code: '387', flag: '🇧🇦' },
                { name: 'Botswana', code: '267', flag: '🇧🇼' },
                { name: 'Brazil', code: '55', flag: '🇧🇷' },
                { name: 'British Virgin Islands', code: '1284', flag: '🇻🇬' },
                { name: 'Brunei', code: '673', flag: '🇧🇳' },
                { name: 'Bulgaria', code: '359', flag: '🇧🇬' },
                { name: 'Burkina Faso', code: '226', flag: '🇧🇫' },
                { name: 'Burundi', code: '257', flag: '🇧🇮' },
                { name: 'Cambodia', code: '855', flag: '🇰🇭' },
                { name: 'Cameroon', code: '237', flag: '🇨🇲' },
                { name: 'Canada', code: '1', flag: '🇨🇦' },
                { name: 'Cape Verde', code: '238', flag: '🇨🇻' },
                { name: 'Caribbean Netherlands', code: '599', flag: '🇧🇶' },
                { name: 'Cayman Islands', code: '1345', flag: '🇰🇾' },
                { name: 'Central African Republic', code: '236', flag: '🇨🇫' },
                { name: 'Chad', code: '235', flag: '🇹🇩' },
                { name: 'Chile', code: '56', flag: '🇨🇱' },
                { name: 'China', code: '86', flag: '🇨🇳' },
                { name: 'Christmas Island', code: '61', flag: '🎅' },
                { name: 'Cocos Islands', code: '61', flag: '🇨🇨' },
                { name: 'Colombia', code: '57', flag: '🇨🇴' },
                { name: 'Comoros', code: '269', flag: '🇰🇲' },
                { name: 'Cook Islands', code: '682', flag: '🇨🇰' },
                { name: 'Costa Rica', code: '506', flag: '🇨🇷' },
                { name: 'Croatia', code: '385', flag: '🇭🇷' },
                { name: 'Cuba', code: '53', flag: '🇨🇺' },
                { name: 'Curaçao', code: '599', flag: '🇨🇼' },
                { name: 'Cyprus', code: '357', flag: '🇨🇾' },
                { name: 'Czech Republic', code: '420', flag: '🇨🇿' },
                { name: 'Democratic Republic of the Congo', code: '243', flag: '🇨🇩' },
                { name: 'Denmark', code: '45', flag: '🇩🇰' },
                { name: 'Djibouti', code: '253', flag: '🇩🇯' },
                { name: 'Dominica', code: '1767', flag: '🇩🇲' },
                { name: 'Dominican Republic', code: '1809', flag: '🇩🇴' },
                { name: 'Ecuador', code: '593', flag: '🇪🇨' },
                { name: 'Egypt', code: '20', flag: '🇪🇬' },
                { name: 'El Salvador', code: '503', flag: '🇸🇻' },
                { name: 'Equatorial Guinea', code: '240', flag: '🇬🇶' },
                { name: 'Eritrea', code: '291', flag: '🇪🇷' },
                { name: 'Estonia', code: '372', flag: '🇪🇪' },
                { name: 'Eswatini', code: '268', flag: '🇸🇿' },
                { name: 'Ethiopia', code: '251', flag: '🇪🇹' },
                { name: 'Falkland Islands', code: '500', flag: '🇫🇰' },
                { name: 'Faroe Islands', code: '298', flag: '🇫🇴' },
                { name: 'Fiji', code: '679', flag: '🇫🇯' },
                { name: 'Finland', code: '358', flag: '🇫🇮' },
                { name: 'France', code: '33', flag: '🇫🇷' },
                { name: 'French Guiana', code: '594', flag: '🇬🇫' },
                { name: 'French Polynesia', code: '689', flag: '🇵🇫' },
                { name: 'Gabon', code: '241', flag: '🇬🇦' },
                { name: 'Gambia', code: '220', flag: '🇬🇲' },
                { name: 'Georgia', code: '995', flag: '🇬🇪' },
                { name: 'Germany', code: '49', flag: '🇩🇪' },
                { name: 'Ghana', code: '233', flag: '🇬🇭' },
                { name: 'Gibraltar', code: '350', flag: '🇬🇮' },
                { name: 'Greece', code: '30', flag: '🇬🇷' },
                { name: 'Greenland', code: '299', flag: '🇬🇱' },
                { name: 'Grenada', code: '1473', flag: '🇬🇩' },
                { name: 'Guadeloupe', code: '590', flag: '🇬🇵' },
                { name: 'Guam', code: '1671', flag: '🇬🇺' },
                { name: 'Guatemala', code: '502', flag: '🇬🇹' },
                { name: 'Guernsey', code: '44', flag: '🇬🇬' },
                { name: 'Guinea', code: '224', flag: '🇬🇳' },
                { name: 'Guinea-Bissau', code: '245', flag: '🇬🇼' },
                { name: 'Guyana', code: '592', flag: '🇬🇾' },
                { name: 'Haiti', code: '509', flag: '🇭🇹' },
                { name: 'Honduras', code: '504', flag: '🇭🇳' },
                { name: 'Hong Kong', code: '852', flag: '🇭🇰' },
                { name: 'Hungary', code: '36', flag: '🇭🇺' },
                { name: 'Iceland', code: '354', flag: '🇮🇸' },
                { name: 'India', code: '91', flag: '🇮🇳' },
                { name: 'Indonesia', code: '62', flag: '🇮🇩' },
                { name: 'Iran', code: '98', flag: '🇮🇷' },
                { name: 'Iraq', code: '964', flag: '🇮🇶' },
                { name: 'Ireland', code: '353', flag: '🇮🇪' },
                { name: 'Isle of Man', code: '44', flag: '🇮🇲' },
                { name: 'Israel', code: '972', flag: '🇮🇱' },
                { name: 'Italy', code: '39', flag: '🇮🇹' },
                { name: 'Ivory Coast', code: '225', flag: '🇨🇮' },
                { name: 'Jamaica', code: '1876', flag: '🇯🇲' },
                { name: 'Japan', code: '81', flag: '🇯🇵' },
                { name: 'Jersey', code: '44', flag: '🇯🇪' },
                { name: 'Jordan', code: '962', flag: '🇯🇴' },
                { name: 'Kazakhstan', code: '7', flag: '🇰🇿' },
                { name: 'Kenya', code: '254', flag: '🇰🇪' },
                { name: 'Kiribati', code: '686', flag: '🇰🇮' },
                { name: 'Kosovo', code: '383', flag: '🇽🇰' },
                { name: 'Kuwait', code: '965', flag: '🇰🇼' },
                { name: 'Kyrgyzstan', code: '996', flag: '🇰🇬' },
                { name: 'Laos', code: '856', flag: '🇱🇦' },
                { name: 'Latvia', code: '371', flag: '🇱🇻' },
                { name: 'Lebanon', code: '961', flag: '🇱🇧' },
                { name: 'Lesotho', code: '266', flag: '🇱🇸' },
                { name: 'Liberia', code: '231', flag: '🇱🇷' },
                { name: 'Libya', code: '218', flag: '🇱🇾' },
                { name: 'Liechtenstein', code: '423', flag: '🇱🇮' },
                { name: 'Lithuania', code: '370', flag: '🇱🇹' },
                { name: 'Luxembourg', code: '352', flag: '🇱🇺' },
                { name: 'Macau', code: '853', flag: '🇲🇴' },
                { name: 'Madagascar', code: '261', flag: '🇲🇬' },
                { name: 'Malawi', code: '265', flag: '🇲🇼' },
                { name: 'Malaysia', code: '60', flag: '🇲🇾' },
                { name: 'Maldives', code: '960', flag: '🇲🇻' },
                { name: 'Mali', code: '223', flag: '🇲🇱' },
                { name: 'Malta', code: '356', flag: '🇲🇹' },
                { name: 'Marshall Islands', code: '692', flag: '🇲🇭' },
                { name: 'Martinique', code: '596', flag: '🇲🇶' },
                { name: 'Mauritania', code: '222', flag: '🇲🇷' },
                { name: 'Mauritius', code: '230', flag: '🇲🇺' },
                { name: 'Mayotte', code: '262', flag: '🇾🇹' },
                { name: 'Mexico', code: '52', flag: '🇲🇽' },
                { name: 'Micronesia', code: '691', flag: '🇫🇲' },
                { name: 'Moldova', code: '373', flag: '🇲🇩' },
                { name: 'Monaco', code: '377', flag: '🇲🇨' },
                { name: 'Mongolia', code: '976', flag: '🇲🇳' },
                { name: 'Montenegro', code: '382', flag: '🇲🇪' },
                { name: 'Montserrat', code: '1664', flag: '🇲🇸' },
                { name: 'Morocco', code: '212', flag: '🇲🇦' },
                { name: 'Mozambique', code: '258', flag: '🇲🇿' },
                { name: 'Myanmar', code: '95', flag: '🇲🇲' },
                { name: 'Namibia', code: '264', flag: '🇳🇦' },
                { name: 'Nauru', code: '674', flag: '🇳🇷' },
                { name: 'Nepal', code: '977', flag: '🇳🇵' },
                { name: 'Netherlands', code: '31', flag: '🇳🇱' },
                { name: 'New Caledonia', code: '687', flag: '🇳🇨' },
                { name: 'New Zealand', code: '64', flag: '🇳🇿' },
                { name: 'Nicaragua', code: '505', flag: '🇳🇮' },
                { name: 'Niger', code: '227', flag: '🇳🇪' },
                { name: 'Nigeria', code: '234', flag: '🇳🇬' },
                { name: 'Niue', code: '683', flag: '🇳🇺' },
                { name: 'Norfolk Island', code: '672', flag: '🇳🇫' },
                { name: 'North Korea', code: '850', flag: '🇰🇵' },
                { name: 'Northern Mariana Islands', code: '1670', flag: '🇲🇵' },
                { name: 'Norway', code: '47', flag: '🇳🇴' },
                { name: 'Oman', code: '968', flag: '🇴🇲' },
                { name: 'Pakistan', code: '92', flag: '🇵🇰' },
                { name: 'Palau', code: '680', flag: '🇵🇼' },
                { name: 'Palestine', code: '970', flag: '🇵🇸' },
                { name: 'Panama', code: '507', flag: '🇵🇦' },
                { name: 'Papua New Guinea', code: '675', flag: '🇵🇬' },
                { name: 'Paraguay', code: '595', flag: '🇵🇾' },
                { name: 'Peru', code: '51', flag: '🇵🇪' },
                { name: 'Philippines', code: '63', flag: '🇵🇭' },
                { name: 'Pitcairn', code: '64', flag: '🇵🇳' },
                { name: 'Poland', code: '48', flag: '🇵🇱' },
                { name: 'Portugal', code: '351', flag: '🇵🇹' },
                { name: 'Puerto Rico', code: '1787', flag: '🇵🇷' },
                { name: 'Qatar', code: '974', flag: '🇶🇦' },
                { name: 'Réunion', code: '262', flag: '🇷🇪' },
                { name: 'Romania', code: '40', flag: '🇷🇴' },
                { name: 'Russia', code: '7', flag: '🇷🇺' },
                { name: 'Rwanda', code: '250', flag: '🇷🇼' },
                { name: 'Saint Helena', code: '290', flag: '🇸🇭' },
                { name: 'Saint Kitts and Nevis', code: '1869', flag: '🇰🇳' },
                { name: 'Saint Lucia', code: '1758', flag: '🇱🇨' },
                { name: 'Saint Martin', code: '590', flag: '🇸🇽' },
                { name: 'Saint Pierre and Miquelon', code: '508', flag: '🇵🇲' },
                { name: 'Saint Vincent and the Grenadines', code: '1784', flag: '🇻🇨' },
                { name: 'Samoa', code: '685', flag: '🇼🇸' },
                { name: 'San Marino', code: '378', flag: '🇸🇲' },
                { name: 'Sao Tome and Principe', code: '239', flag: '🇸🇹' },
                { name: 'Saudi Arabia', code: '966', flag: '🇸🇦' },
                { name: 'Senegal', code: '221', flag: '🇸🇳' },
                { name: 'Serbia', code: '381', flag: '🇷🇸' },
                { name: 'Seychelles', code: '248', flag: '🇸🇨' },
                { name: 'Sierra Leone', code: '232', flag: '🇸🇱' },
                { name: 'Singapore', code: '65', flag: '🇸🇬' },
                { name: 'Slovakia', code: '421', flag: '🇸🇰' },
                { name: 'Slovenia', code: '386', flag: '🇸🇮' },
                { name: 'Solomon Islands', code: '677', flag: '🇸🇧' },
                { name: 'Somalia', code: '252', flag: '🇸🇴' },
                { name: 'South Africa', code: '27', flag: '🇿🇦' },
                { name: 'South Korea', code: '82', flag: '🇰🇷' },
                { name: 'Sudan', code: '249', flag: '🇸🇩' },
                { name: 'South Sudan', code: '211', flag: '🇸🇸' },
                { name: 'Spain', code: '34', flag: '🇪🇸' },
                { name: 'Sri Lanka', code: '94', flag: '🇱🇰' },
                { name: 'Suriname', code: '597', flag: '🇸🇷' },
                { name: 'Svalbard and Jan Mayen', code: '47', flag: '🇸🇻' },
                { name: 'Swaziland', code: '268', flag: '🇸🇿' },
                { name: 'Sweden', code: '46', flag: '🇸🇪' },
                { name: 'Switzerland', code: '41', flag: '🇨🇭' },
                { name: 'Syria', code: '963', flag: '🇸🇾' },
                { name: 'Taiwan', code: '886', flag: '🇹🇼' },
                { name: 'Tajikistan', code: '992', flag: '🇹🇯' },
                { name: 'Tanzania', code: '255', flag: '🇹🇿' },
                { name: 'Thailand', code: '66', flag: '🇹🇭' },
                { name: 'Timor-Leste', code: '670', flag: '🇹🇱' },
                { name: 'Togo', code: '228', flag: '🇹🇬' },
                { name: 'Tokelau', code: '690', flag: '🇹🇰' },
                { name: 'Tonga', code: '676', flag: '🇹🇴' },
                { name: 'Trinidad and Tobago', code: '1868', flag: '🇹🇹' },
                { name: 'Tunisia', code: '216', flag: '🇹🇳' },
                { name: 'Turkey', code: '90', flag: '🇹🇷' },
                { name: 'Turkmenistan', code: '993', flag: '🇹🇲' },
                { name: 'Turks and Caicos Islands', code: '1649', flag: '🇹🇨' },
                { name: 'Tuvalu', code: '688', flag: '🇹🇻' },
                { name: 'Uganda', code: '256', flag: '🇺🇬' },
                { name: 'Ukraine', code: '380', flag: '🇺🇦' },
                { name: 'United Arab Emirates', code: '971', flag: '🇦🇪' },
                { name: 'United Kingdom', code: '44', flag: '🇬🇧' },
                { name: 'United States', code: '1', flag: '🇺🇸' },
                { name: 'United States Minor Outlying Islands', code: '1', flag: '🇺🇲' },
                { name: 'Uruguay', code: '598', flag: '🇺🇾' },
                { name: 'Uzbekistan', code: '998', flag: '🇺🇿' },
                { name: 'Vanuatu', code: '678', flag: '🇻🇺' },
                { name: 'Vatican City', code: '379', flag: '🇻🇦' },
                { name: 'Venezuela', code: '58', flag: '🇻🇪' },
                { name: 'Vietnam', code: '84', flag: '🇻🇳' },
                { name: 'British Virgin Islands', code: '1284', flag: '🇻🇬' },
                { name: 'U.S. Virgin Islands', code: '1340', flag: '🇻🇮' },
                { name: 'Wallis and Futuna', code: '681', flag: '🇼🇫' },
                { name: 'Western Sahara', code: '212', flag: '🇪🇭' },
                { name: 'Yemen', code: '967', flag: '🇾🇪' },
                { name: 'Zambia', code: '260', flag: '🇿🇲' },
                { name: 'Zimbabwe', code: '263', flag: '🇿🇼' }
            ],
            countrySelected:  { name: 'France', code: '33', flag: '🇫🇷' },
            input: '',
            syncTime: null,
        }
    },
    methods: {
        moveLabel () {
            let label = document.getElementById(this.uid+'-label')
            let input = document.getElementById(this.uid+'-input')
            label.style.display="block"
            label.style.top="-2.2em"
            label.classList.remove('danger')
            input.setAttribute('placeholder', '')
            // input.setAttribute('')
        },
        reinitLabel () {
            let label = document.getElementById(this.uid+'-label')
            let input = document.getElementById(this.uid+'-input')
            let error1 = document.getElementById(this.uid+'-error1')
            let error2 = document.getElementById(this.uid+'-error2')
            if(input.value == "") {
                error1.style.display="block"
                error2.style.display="block"
                label.style.display="block"
                label.classList.add('danger')
                input.classList.add('dangerFrame')
                label.style.top="0em"
                input.setAttribute('placeholder', this.placeholder)
                // let t =
                setTimeout(()=>{
                    label.style.display="none"
                }, 200)
                this.floatingRight = 35
                // clearInterval(t)
            }
            else{
                error1.style.display="none"
                error2.style.display="none"
                this.floatingRight = 7
                input.classList.remove('dangerFrame')
            }
        },
        hideOrShow () {
            let input = document.getElementById(this.uid+'-input')
            let toogler = document.getElementById(this.uid+'-toogle')
            if(this.hideInput) {
                input.setAttribute('type', 'text')
                toogler.classList.remove('show-eye')
                toogler.classList.add('hide-eye')
            }
            else{
                input.setAttribute('type', this.type)
                toogler.classList.add('show-eye')
                toogler.classList.remove('hide-eye')
            }
            this.hideInput = !this.hideInput
        },
        setInput(event) {
            this.formatPhoneNumber(event)
            let payload = {
              "phone": this.input,
              "country": this.countrySelected?.name,
              "countryCode": this.countrySelected?.code
            };
            this.$emit('inputChange', payload)
        },
        showCountryList () {
            document.getElementById(this.uid + 'countryListSelect').classList.remove('d-none')
        },
        hideCountryList (event) {
            let target = event.relatedTarget;
            if((target == null) || (target != null && target.getAttribute('id') != (this.uid + 'countryListSelect'))) {
                let elm = document.getElementById(this.uid + 'countryListSelect')
                if(!elm.classList.contains('d-none'))
                    elm.classList.add('d-none')
            }
        },
        updatePrefix () {
            this.countrySelected = this.countries[this.selectedCountryIndex]
            let elm = document.getElementById(this.uid + 'countryListSelect')
            if(!elm.classList.contains('d-none'))
                elm.classList.add('d-none')
        },
        formatPhoneNumber(event) {
            // Get the input field and the current caret position
            const input = event.target;
            const caret = input.selectionStart;

            // Get the current value of the input field
            let value = input.value;

            // Remove any non-numeric characters
            value = value.replace(/[^\d]/g, '');

            // Split the number into the country code and the local number
            const localNumber = value;

            // Format the number as +CC-XXX-XXX-XXXX
            value = `${localNumber.slice(0, 3)}-${localNumber.slice(3, 6)}-${localNumber.slice(6)}`;

            // Update the value of the input field
            // input.value = value;
            this.input = value;

            // Set the caret position to the correct location
            input.setSelectionRange(caret, caret);
        }
    },
    mounted() {
        document.getElementById(this.uid+'-error1').style.display="none";
        document.getElementById(this.uid+'-error2').style.display="none";
    },
    watch: {
      syncField: function () {
        this.reinitLabel();
      }
    }
}
</script>

<style scoped>
    .countryList{
        position: absolute;
        top: 42px;
        left: 0;
        right: 0;
        width: 100%;
    }
    .arrow-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        display: inline-block;
        border-top: 5px solid #52504f;
    }
    .country-list-btn{
        position: relative;
        width: 5.3em;
        padding: 0 10px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 600;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        border: 1px solid #ccc;
        /*box-shadow: inset 0 1px 1px rgba(0,0,0,.075);*/
    }
    .country-list-btn span:first-of-type{
        font-size: 1.38em;
    }
    .country-list-btn span:last-of-type{
        position: relative;
        bottom: .25em;
        left: .3em;
    }
    .toggle-password{
        position: absolute;
        top: 9px;
        right: 15px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        right: 35px;
    }
    .show-eye{
        background-image: url(@/assets/Icons/All_Icon/icon_show_password.svg);
    }
    .hide-eye{
        background-image: url(@/assets/Icons/All_Icon/icon_hide_password.svg);
    }
    .danger{
        color: #ff7483;
    }
    .dangerFrame{
        border-color: #ff7483;
    }
    .transaition{
        touch-action: manipulation;
        transition: all .2s;
    }
    .input-container-wrapper {
        position: relative;
        display: flex;
        margin-top: 0;
    }
    .input{
        position: relative;
        width: 100%;
        /* height: 34px; */
        padding: 0 10px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 600;
        border-left: none;
    }
    .input-1r{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .input-2r{
        padding-left: 3.5em;
    }
    .input-3r{
        position: absolute;
        left: 3.9em;
        width:4.5em;
        
        z-index: 10000;
        background-color: transparent;
        border: 1px transparent;
        padding: 0;
        padding-left: 0px;
        padding-left: 3em;
        pointer-events: none;
       
        border-radius: 0;
    }
    .input-country-code{
        border-radius: 0 !important;
        width: 5em;
        padding: 0;
        padding-left: 0px;
        padding-left: .2em;
        border-right: none;
    }
    .label{
        position: absolute;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 8px 10px 0;
        font-size: 16px;
        pointer-events: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: text;
    }
    .alert-container{
        color: #ff7483;
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 8px;
        padding: 2px 0;
        font-size: 14px;
        font-weight: 600;
        transform: translateY(-55%);
        user-select: none;
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor;
        height: 24px;
        width: 24px;
        overflow: hidden;
    }
    .alert-container svg{
        position: relative;
        bottom: 1px;
    }
    .alert-text{
        position: absolute;
        top: 38px;
        left: 5px;
        font-size: 11px;
        color: #ff7483 !important;
    }
    .animated-label{
        display: none;
    }
</style>
