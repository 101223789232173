<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3 d-flex justify-content-between align-items-center efwegl sfger" style="margin-bottom: 3rem !important;">
          <h1 class="cfa-section-title mb-0">{{ $t('SUPPORT') }}</h1>
          <button @click="openNewGroup=true" class="btn cfa-btn-primary" style="opacity: 1; display: flex; background-color: var(--primary-color); padding: 0.7em 1.3em;">
            {{ $t('write_to_support') }}
          </button>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
            <div class="row">
                <div v-for="(element, i) in support_items" :key="i" class="col-sm-4 patch-pf-dot-4">
                    <a :href="`/support-service/${element.id}`" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-certificate pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> {{ $t('OPEN') }}
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">{{ element.title }}</h4>
                                </div>
                              <div>
                                <span class="f-light pt-2">
                                    {{ element.content }}
                                </span>
                              </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>
                <!--<div class="col-sm-4 patch-pf-dot-4">
                    <a href="/support-service/rubrique1" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-server pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> OUVRIR
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">Offres et souscription</h4>
                                </div>
                                <div>
                        <span class="f-light pt-2">
                            Learn how to create videos, add design elements and layouts, use templates and
                        </span>
                                </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>
                <div class="col-sm-4 patch-pf-dot-4">
                    <a href="/support-service/rubrique1" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-certificate pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> OUVRIR
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">Certificat</h4>
                                </div>
                                <div>
                        <span class="f-light pt-2">
                            Learn how to create videos, add design elements and layouts, use templates and
                        </span>
                                </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>
                <div class="col-sm-4 patch-pf-dot-4">
                    <a href="/support-service/rubrique1" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-home pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> OUVRIR
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">Activation</h4>
                                </div>
                                <div>
                        <span class="f-light pt-2">
                            Learn how to create videos, add design elements and layouts, use templates and
                        </span>
                                </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>
                <div class="col-sm-4 patch-pf-dot-4">
                    <a href="/support-service/rubrique1" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-server pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> OUVRIR
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">Offres et souscription</h4>
                                </div>
                                <div>
                        <span class="f-light pt-2">
                            Learn how to create videos, add design elements and layouts, use templates and
                        </span>
                                </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>
                <div class="col-sm-4 patch-pf-dot-4">
                    <a href="/support-service/rubrique1" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-certificate pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> OUVRIR
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">Certificat</h4>
                                </div>
                                <div>
                        <span class="f-light pt-2">
                            Learn how to create videos, add design elements and layouts, use templates and
                        </span>
                                </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>
                <div class="col-sm-4 patch-pf-dot-4">
                    <a href="/support-service/rubrique1" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-home pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> OUVRIR
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">Activation</h4>
                                </div>
                                <div>
                        <span class="f-light pt-2">
                            Learn how to create videos, add design elements and layouts, use templates and
                        </span>
                                </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>
                <div class="col-sm-4 patch-pf-dot-4">
                    <a href="/support-service/rubrique1" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-server pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> OUVRIR
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">Offres et souscription</h4>
                                </div>
                                <div>
                        <span class="f-light pt-2">
                            Learn how to create videos, add design elements and layouts, use templates and
                        </span>
                                </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>
                <div class="col-sm-4 patch-pf-dot-4">
                    <a href="/support-service/rubrique1" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-certificate pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> OUVRIR
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">Certificat</h4>
                                </div>
                                <div>
                        <span class="f-light pt-2">
                            Learn how to create videos, add design elements and layouts, use templates and
                        </span>
                                </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>
                <div class="col-sm-4 patch-pf-dot-4">
                    <a href="/support-service/rubrique1" style="text-decoration: none; color: initial">
                        <div class="card course-box">
                            <div class="card-body time-block">
                                <div class="course-widget d-flex justify-content-between">
                                    <div class="course-icon position-relative">
                                        <i class="fa fa-certificate pentagone_icon"></i>
                                        <svg class="fill-icon" style="background: var(--primary-color);"></svg>
                                    </div>
                                    <div style="font-weight: lighter">
                                        <br> OUVRIR
                                        <i class="fa fa-chevron-right" style="font-weight: lighter"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0">Certificat</h4>
                                </div>
                                <div>
                        <span class="f-light pt-2">
                            Learn how to create videos, add design elements and layouts, use templates and
                        </span>
                                </div>
                            </div>

                            <ul class="square-group">
                                <li class="square-1 warning"></li>
                                <li class="square-1 primary"></li>
                                <li class="square-2 warning1"></li>
                                <li class="square-3 danger"></li>
                                <li class="square-4 light"></li>
                                <li class="square-5 warning"></li>
                                <li class="square-6 success"></li>
                                <li class="square-7 success"></li>
                            </ul>
                        </div>
                    </a>
                </div>-->
            </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
    <scalable-modal @callback="confirmNewGroup" :break="exitNewGroupModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-group-create" @close="openNewGroup=false" :open="openNewGroup"  :advancedControl="true" validationButtonText="Envoyer" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
          <template v-slot:content>
              <!-- blo1 -->
              <div class="row w-100">
                  <div class="form-group py-2 px-0">
                      <p class="mb-2"><strong>{{ $t('question_or_subject') }}</strong></p>
                      <div class="d-flex flex-row">
                          <input v-model="title" class="form-control w-100" type="text" >
                      </div>
                  </div>
              </div>
              <div class="row w-100">
                  <div class="form-group py-2 px-0">
                      <p class="mb-2"><strong>{{ $t('email') }}</strong></p>
                      <div class="d-flex flex-row">
                          <input v-model="email" class="form-control w-100" type="text" >
                      </div>
                  </div>
              </div>
              <div class="row w-100">
                  <div class="form-group py-2 px-0">
                      <p class="mb-2"><strong>{{ $t('Details') }}</strong></p>
                      <div class="d-flex flex-row">
                          <textarea v-model="content" class="form-control w-100" style="height: 183px;"></textarea>
                      </div>
                  </div>
              </div>
          </template>
    </scalable-modal>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import ScalableModal from "@/components/shared/modal/ScalableModal.vue";
import {getAPI} from "@/axios-api";
//import {CollapseTransition} from "vue2-transitions";

export default {
  name: 'DashboardView',
  components: {
      ScalableModal,
      CFAFooter,
      CFANav,
      //CollapseTransition
  },
  data () {
    return {
      waitingAPIResponse: true,
      displayMode: 'GROUP',
      filterMode: 'ALL',
      allModules: [],
      alertString: null,
      totalInProgress: 0,
      totalModule: 0,
      module_covered: 0,
      module_progress: [],
      openNewGroup: false,
      activeTab: '',
      activeQuestionIndex: null,
      showAccordion: true,
      items: [
          {
              title: 'How long is a day and year on Venus?',
              value: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'How many time zones are there in all?',
              value: 'Given a 24-hour day and 360 degrees of longitude around the Earth',
              category: 'Facturation & Tarification'
          },
          {
              title: 'What animal smells like popcorn?',
              value: 'Binturongs smell like popcorn.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'How many time zones are there in all?',
              value: 'Given a 24-hour day and 360 degrees of longitude around the Earth',
              category: 'Facturation & Tarification'
          },
          {
              title: 'How long is a day and year on Venus?',
              value: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'What animal smells like popcorn?',
              value: 'Binturongs smell like popcorn.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'How many time zones are there in all?',
              value: 'Given a 24-hour day and 360 degrees of longitude around the Earth',
              category: 'Facturation & Tarification'
          },
          {
              title: 'How long is a day and year on Venus?',
              value: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'What animal smells like popcorn?',
              value: 'Binturongs smell like popcorn.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'How many time zones are there in all?',
              value: 'Given a 24-hour day and 360 degrees of longitude around the Earth',
              category: 'Facturation & Tarification'
          },
          {
              title: 'How long is a day and year on Venus?',
              value: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'What animal smells like popcorn?',
              value: 'Binturongs smell like popcorn.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'How many time zones are there in all?',
              value: 'Given a 24-hour day and 360 degrees of longitude around the Earth',
              category: 'Facturation & Tarification'
          },
          {
              title: 'How long is a day and year on Venus?',
              value: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'What animal smells like popcorn?',
              value: 'Binturongs smell like popcorn.',
              category: 'Modules de sensibilisation'
          },
          {
              title: 'How long is a day and year on Venus?',
              value: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
              category: 'Planification RDV Expert'
          },
          {
              title: 'What animal smells like popcorn?',
              value: 'Binturongs smell like popcorn.',
              category: 'Planification RDV Expert'
          }
      ],
      questionProperty:  'title',
      answerProperty: 'value',
      tabName: 'category',
      activeColor:  '#fff',
      activeColorPrimary:  'var(--primary-color)',
      borderColor: '#e1e0e0',
      fontColor:  '#000000',
      initialTab:  null,
      initialQuestionIndex:  0,
      exitNewGroupModal: null,
      support_items: [],
    }
  },
  methods: {
    confirmNewGroup () {
        if(this.title != '' && this.content != '') {
            getAPI.post(`support/`, {
                title: this.title,
                email: this.email,
                content: this.content,
            })
                .then(response => {
                    console.log("Title: ", this.title)
                    if(response.status == 200 || response.status == 201) {
                        this.getAllGroup()
                        //let msg = 'Your message has been sent successfully!'
                        //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        this.exitNewGroupModal = Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.exitNewGroupModal = Date.now()
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.exitOrgUpdateModal = Date.now()
                })
        }
        else {
            this.exitNewGroupModal = Date.now()
            //let msg = 'Oups ! Veuillez remplir les champs convenablement.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
    },
    getViewMode (e) {
      this.displayMode = e
    },
    getViewFilter (e) {
      this.filterMode = e
    },
    getModuleError (e) {
    console.log(e)
      //this.alertString = `${e}|alert-no|10000::`+Date.now()
    },
    setActiveCategoryAndQuestion() {
        const uniqueCategories = this.items
            .map(item => item[this.tabName])
            .filter((category, index, categories) => categories.indexOf(category) === index);

        this.activeTab = this.initialTab || uniqueCategories[0];
        this.activeQuestionIndex = this.initialQuestionIndex || 0;
    },
    makeActive (itemIndex) {
        this.activeQuestionIndex = this.activeQuestionIndex === itemIndex ? null : itemIndex
        this.$emit('itemSelect', { itemIndex })
    },
    generateButtonClasses (buttonIndex) {
        return [
            'accordion__toggle-button',
            this.activeQuestionIndex === buttonIndex
                ? 'accordion__toggle-button_active'
                : null
        ]
    },
    generateQuestionClasses (questionIndex) {
        return [
            'accordion__title',
            this.activeQuestionIndex === questionIndex
                ? 'accordion__title_active'
                : null
        ]
    },
    makeActiveCategory (category, categoryIndex) {
        if (this.activeTab === category) return

        this.showAccordion = false
        this.activeTab = category
        this.activeQuestionIndex = null
        setTimeout( () => {
            this.$emit('categorySelect', { categoryIndex })
            this.showAccordion = true
        }, 300 )
    },
    generateCategoryClasses (category) {
          return [
              'faq__nav-item',
              this.activeTab === category
                  ? 'faq__nav-item_active'
                  : null
          ]
      }
  },
  computed: {
      categories () {
          const uniqueCategories = this.items
              .map(item => item[this.tabName])
              .filter((category, index, categories) => categories.indexOf(category) === index)

          // this.activeTab = this.initialTab || uniqueCategories[0]
          // this.activeQuestionIndex = this.initialQuestionIndex || 0
          return uniqueCategories
      },
      categoryItems () {
          return this.items
              .filter(item => item[this.tabName] === this.activeTab)
      },
      hasNavigation () {
          return !!this.categories[0]
      }
  },
  mounted () {
      /*this.$refs.rootEl.style.setProperty('--active-color-primary', this.activeColorPrimary)
      this.$refs.rootEl.style.setProperty('--active-color', this.activeColor)
      this.$refs.rootEl.style.setProperty('--border-color', this.borderColor)
      this.$refs.rootEl.style.setProperty('--font-color', this.fontColor)*/
      // setTimeout(() => {this.waitingAPIResponse=false;}, 2000)
      this.waitingAPIResponse = true
      getAPI.get('support')
          .then(response => {
              if(response.status == 200) {
                let payload = response.data.results;
                this.support_items = payload;
                console.log("<>", payload)
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                this.waitingAPIResponse = false
              }, 2000);
          })
          .catch(error => {
              if (error.response) {
                //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse = false
          })
  },
  watch: {
      categories: {
          immediate: true,
          handler() {
              this.setActiveCategoryAndQuestion();
          },
      },
  },
}
</script>

<style scoped>
.accordion__item__grid{
    /*margin: 1em 1em 1em 0em;*/
    margin: .5em;
    border: 1px solid #e1e0e0;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
button {
    border: none;
    background: none;
    outline: none;
}
.faq {
    width: 100%;
    /*padding: 0 10px;*/
}
.faq-wrapper {
    /*max-width: 825px;*/
}
.faq__title {
    text-align: center;
    margin-bottom: 25px;
}
.faq__nav {
    display: flex;
    justify-content: space-between;
    border: 2px solid var(--border-color);
    border-radius: 5px;
}
.faq__nav-item {
    height: 50px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid var(--border-color);
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s;
    text-align: center;
    user-select: none;
    color: var(--font-color);
    font-weight: 400;
}
.faq__nav-item_active {
    color: var(--active-color);
    background-color: var(--primary-color);
}
.faq__nav-item:hover {
    /*color: var(--active-color);*/
}
.faq__nav-item:last-child {
    border-right: none;
}
.faq__accordion {
    min-height: 250px;
}
.accordion-fade-slide-enter-active, .accordion-fade-slide-leave-active {
    transition: all 0.3s;
}
.accordion-fade-slide-enter {
    transform: translateY(-25px);
    opacity: 0;
}
.accordion-fade-slide-leave-to {
    transform: translateY(25px);
    opacity: 0;
}
.accordion {
    border: 2px solid var(--border-color);
    border-radius: 5px;
    margin-top: 15px;
}
.accordion__item {
    border-bottom: 2px solid var(--border-color);
    background-color: #fff;
}
.accordion__item:last-child {
    border-bottom: none;
}
.accordion__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    cursor: pointer;
    transition: all 0.3s;
    color: var(--font-color);
}
.accordion__title_active {
    color: var(--active-color-primary);
}
.accordion__title:hover {
    color: var(--active-color-primary);
}
.accordion__title:hover .accordion__toggle-button::before, .accordion__title:hover .accordion__toggle-button::after {
    background: var(--active-color-primary);
    font-weight: 400;
}
.accordion__title-text {
    margin-right: 10px;
}
.accordion__value {
    padding: 0 25px 25px 25px;
    text-align: left;
    /*color: var(--font-color);*/
}
.accordion__toggle-button {
    position: relative;
    width: 16px;
    height: 16px;
    transition: all 0.3s;
    transform-origin: 50% 50%;
    padding-left: 16px;
    cursor: pointer;
}
.accordion__toggle-button::before, .accordion__toggle-button::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    transition: all 0.3s;
    background: black;
}
.accordion__toggle-button::before {
    transform: rotate(90deg);
}
.accordion__toggle-button_active {
    transform: rotate(45deg);
}
.accordion__toggle-button_active::before, .accordion__toggle-button_active::after {
    background: var(--active-color-primary);
}

.entreprise-block{
    min-height: 15.5em;
}
.time-block{
    min-height: 13em;
}
.license-block{
    min-height: 30.3em;
}
.cfa-page{

}
.cfa-block{
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

    /* border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.ticket-list tr {
    border-color: transparent !important;
}
.codeStyle{
    padding: 0.5em;
    background: #f5eded;
    text-decoration: none;
    color: #0fbac3;
    font-weight: 400;
    display: inline-block;
    cursor: copy;
}
.gFolder-container{
    background-color: #cef1f3;
    border-radius: 0.5em;
    height: 4.3em;
    width: 4.6em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gBlock{
    align-items: center;
    justify-content: start;
    border-radius: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
    height: 8em !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-image: url('https://laravel.pixelstrap.com/cuba/build/assets/widget-bg-a2f1cbe6.png');
    background-size: cover;
}
.gBlock2{
    align-items: center;
    justify-content: start;
    border-radius: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
    height: 18.5em !important;
    box-shadow: none;
    background-image: none !important;
    background-color: #cef1f3 !important;
}
.gBlock div:last-of-type p{
    margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
    font-weight: 400;
    font-size: 1.4em;
}

.welcome-card {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
}
.card {
    margin-bottom: 20px;
    border: none;
    transition: all .3s ease;
    letter-spacing: .5px;
    border-radius: 15px;
    box-shadow: 0 9px 20px #2e235e12;
}
.o-hidden {
    overflow: hidden;
}
.card .card-body {
    padding: 30px;
    background-color: transparent;
}
.welcome-card .welcome-img {
    height: 100%;
    position: absolute;
    right: 0;
    bottom: -5px;
}

@media (max-width: 1700px) {
    .welcome-card .welcome-img {
        bottom: -5px;
    }
}
@media (max-width: 1780px) {
    .welcome-card .welcome-img {
        height: 100%;
        bottom: -5px;
    }
}
.course-box {
    overflow: hidden;
}
.square-group {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scaleX(-1);
}
ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}
.square-group .warning {
    background: var(--primary-light-color-2);
}
.square-group .square-1 {
    width: 12px;
    height: 18px;
}
.square-group .primary {
    background: var(--primary-color);
}
.square-group .warning1 {
    background: #FFD497;
}
.square-group .danger {
    background: #f54132;
}
.square-group .light {
    background: #E6E8EF;
}
.square-group .warning {
    background: #c8fcff;
}
.square-group .success {
    background: #54BA4A;
}
.square-group .square-6, .square-group .square-7 {
    position: absolute;
    width: 4px;
    height: 4px;
}
.square-group .square-6, .square-group .square-7 {
    position: absolute;
    width: 4px;
    height: 4px;
}
.square-group .success {
    background: #54BA4A;
}
.course-widget {
    display: flex;
    gap: 8px;
}
.course-widget .course-icon {
    position: relative;
    width: 54px;
    height: 54px;
    clip-path: polygon(40% 7.67949%,43.1596% 6.20615%,46.52704% 5.30384%,50% 5%,53.47296% 5.30384%,56.8404% 6.20615%,60% 7.67949%,81.65064% 20.17949%,84.50639% 22.17911%,86.97152% 24.64425%,88.97114% 27.5%,90.44449% 30.6596%,91.34679% 34.02704%,91.65064% 37.5%,91.65064% 62.5%,91.34679% 65.97296%,90.44449% 69.3404%,88.97114% 72.5%,86.97152% 75.35575%,84.50639% 77.82089%,81.65064% 79.82051%,60% 92.32051%,56.8404% 93.79385%,53.47296% 94.69616%,50% 95%,46.52704% 94.69616%,43.1596% 93.79385%,40% 92.32051%,18.34936% 79.82051%,15.49361% 77.82089%,13.02848% 75.35575%,11.02886% 72.5%,9.55551% 69.3404%,8.65321% 65.97296%,8.34936% 62.5%,8.34936% 37.5%,8.65321% 34.02704%,9.55551% 30.6596%,11.02886% 27.5%,13.02848% 24.64425%,15.49361% 22.17911%,18.34936% 20.17949%);
    background: linear-gradient(310.31deg,#FF3364 14.71%,#FF7F9E 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.f-light {
    color: #52526c;
    opacity: .8;
}
.no-wrap{
    white-space: nowrap;
}
.course-widget .btn-light {
    background: #f2f3f7 !important;
    border: none;
}
.course-widget a {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    position: relative;
    margin-top: 5px;
    top: 8px;
}
.card {
    margin-bottom: 20px;
    border: none;
    transition: all .3s ease;
    letter-spacing: .5px;
    border-radius: 7px;
    box-shadow: 0 9px 20px #2e235e12;

    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}
.card .card-header.card-no-border {
    border-bottom: none!important;
}
.get-card .card-header {
    padding-left: 20px;
    padding-right: 20px;
}
.card .card-header {
    background-color: #fff;
    padding: 30px;
    border-bottom: 1px solid #ecf3fa;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.mb-1 {
    margin-bottom: 0.25rem!important;
}
.card .card-body {
    padding: 30px;
    background-color: transparent;
}
.btn-outline-white {
    border: 1px solid #fff;
    color: #fff;
}
.pentagone_icon{
    position: absolute;
    font-size: 28px;
    color: #fff;
}
.course-box{
    transition: .3s all ease-in;
}
.course-box:hover{
    background: #c9fbff;
}
.patch-pf-dot-4{
    padding: 0 0.6em !important;
}
</style>
