<template>
  <div class="cfa-root">
    <c-f-a-header></c-f-a-header>
    <div class="cfa-root-container">
      <!--<header class="text-start py-3 px-md-5 px-0">
        <h1 style="font-size: 18px; margin-bottom: 0px;">CONDITIONS GÉNÉRALES D'UTILISATION</h1>
      </header>-->
      <div class="content d-flex align-items-center">
          <div class="pl-0 w-100">
              <div class="m-auto col-12 col-md-6">
                  <h2 class="text-start">OUTIL DE VERIFICATION DES CERTIFICATS <span class="cfa-primary">CCI OCITANIE</span></h2>
                  <br><br>
                  <div class="text-start">
                      Entrez le code à 8 chiffres pour verifier la validiter du certificat correspondant
                  </div>
              </div>

              <div class="form-group col-12 col-md-6 m-auto pt-4 text-start">
                  <!--<label class="control-label" for="inputFirstname"></label>-->
                  <input id="inputFirstname" placeholder="Entrez le code du certificat" class="form-control" name="inputFirstname" type="text" v-model="code" style="padding: 1em 0.75rem; font-size: 1.3rem;">
              </div>
              <div class="mt-3 col-12 col-md-6 m-auto py-4 text-start result_content d-flex justify-content-center align-items-center oups" style="padding-top: 5em !important; padding-bottom: 5em !important;">
                  <div v-if="waitingAPIResponse" class="text-center">
                      <img class="mb-2" src="@/assets/img/gif/scan2.webp" alt="" style="width: 15em;"/>
                      <p>
                          <span style="font-size: 1.3em;">Verification en cours</span>
                      </p>
                  </div>
                  <div v-else style="min-width: 80%;">
                      <div v-if="payload == null" class="text-center">
                          <img class="mb-2" src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="" style="width: 15em;"/>
                          <p>
                              <span style="font-size: 1.3em; position: relative; left: 1em;">Ce certificat n'existe pas !</span>
                          </p>
                      </div>
                      <div v-else>
                          <certificate-success :code="code" :fname="payload.data.username" lname="..." :is-ready="true"></certificate-success>
                          <br>
                          <p style="font-size: 1.3em; position: relative; left: 1em;">
                              Délivré le : <span  v-html="convertDate(payload.data.created_at)"></span>
                          </p>
                      </div>
                  </div>
              </div>
              <br>
              <pre>code test: 01D6ORS3</pre>
          </div>
      </div>
    </div>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFAHeader from '@/components/nav/CFAHeader.vue'
import {guestAPI} from "@/axios-api";
import CertificateSuccess from "@/components/shared/certificate/CertificateSuccess.vue";

export default {
  name: 'ConditionsView',
  components: {
      CertificateSuccess,
      CFAFooter,
      CFAHeader,
  },
  data () {
    return {
      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',
      waitingAPIResponse: false,
      code: null,
      payload: null,
    }
  },
  methods: {
      fetchStatus () {
          this.waitingAPIResponse = true
          guestAPI.get(`certificats/verified/${this.code}/`)
            .then(response => {
                if(response.status === 200) {
                    console.log(response.data)
                    this.payload = response.data;
                }
                else {
                    this.payload = null;
                    console.log('Something went wrong !')
                }
                setTimeout(() => {
                    this.waitingAPIResponse = false
                }, 3000)
            })
            .catch(() => {
                this.payload = null;
                console.log('Something went wrong !')
                setTimeout(() => {
                    this.waitingAPIResponse = false
                }, 3000)
            });
      },
      convertDate(dateString) {
          const date = new Date(dateString);
          const formattedDate = date.toLocaleDateString();
          const formattedTime = date.toLocaleTimeString();
          return `<span>${formattedDate} à ${formattedTime}</span>`;
      },
  },
  mounted() {
    console.log(this.$route)
  },
  watch: {
      code: function (value) {
        if(value.length === 8) {
          this.fetchStatus()
        }
      },
  }
}
</script>

<style scoped>
.result_content{
    min-height: 15em;
    background-color: #f1f1f1;
}
.content{
    min-height: calc(100vh - 6em);
    background-color: #f6f6f6;
}
.cfa-root-container{
  min-height: 50vh;
  min-width: 100vw;
  text-align: center;
  padding-top: 0em !important;
}
body {
  font-family: Arial, sans-serif;
  background-color: #F8F8F8;
  margin: 0;
  padding: 0;
}

header {
  background-color: var(--primary-color);
  color: #FFFFFF;
  /*padding: 20px;*/
  text-align: center;
}

h1 {
  font-size: 25px;
  margin-top: 0;
  padding-left:1em;
}

.container {
  max-width: 1280px;
  margin: 2em auto;
  padding: 20px;
  background-color: #FFFFFF;
  /*box-shadow: 0px 0px 10px #CCCCCC;*/
  text-align: left;
}

h2 {
  font-size: 18px;
  margin-top: 0;
}

p {
  margin-top: 0;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 20px;
}

li {
  margin-top: 10px;
}
h2{
  margin-bottom: 0;
  padding: .5em;
  /*background-color: #0bbbc41c;*/
}
.section_content{
  padding: .5em;
  background-color: #FFF;
  margin-bottom: 1em;
}
</style>
