<template>
  <div class="cfa-root">
    <c-f-a-nav>
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3">
          <h1 class="cfa-section-title">XXXXXXXXXX</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div class="cfa-page">
          123
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav
  }
}
</script>

<style scoped>
.cfa-page{

}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
</style>