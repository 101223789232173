<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3 d-flex justify-content-between align-items-center efwegl">
          <h1 class="cfa-section-title"><span class="cfa-primary">{{ $t('resources') }} :</span> {{ createStringFromSlug($route.params.slug) }}</h1>
          <router-link :to="{name: 'Resources'}">
            <button class="btn cfa-btn-primary"><i class="fa fa-chevron-left">&nbsp;&nbsp;</i>RETOUR</button>
          </router-link>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div class="cfa-page">
          <div class="courseIframe d-flex position-relative">
            <iframe v-show="!waitingHttpResponse" class="col-12" :src="url_pdf" title="CyberForceAcademy - Courses Interface"></iframe>
            <pre-loader v-show="waitingHttpResponse" />
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import PreLoader from '@/components/shared/PreLoader.vue'
import {getAPI} from "@/axios-api";

export default {
  name: 'WebContainerView',
  props: {
    /*https://www.africau.edu/images/default/sample.pdf*/
    url: {
      type: String,
      default: ''
    }
  },
  components: {
    CFAFooter,
    CFANav,
    PreLoader
  },
  data () {
    return {
      waitingHttpResponse: true,
      url_pdf: this.url,
      alertString: ''
    }
  },
  methods: {
    hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    retrieveOrg() {
      getAPI.get(`organisations/${sessionStorage.organisation}/`)
        .then(response => {
          if (response.status == 200) {
            this.organisationData = response.data
            this.primary = this.organisationData.primary_color;
            this.secondary = this.organisationData.secondary_color;
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    saveColor() {
      const payload = {
          primary_color: this.primary_color,
          secondary_color: this.secondary_color,
          tertiary_color: this.tertiary_color,
      };
      getAPI.put(`organisations/change_color/${sessionStorage.organisation}/`, payload)
        .then(response => {
          if (response.status === 200) {
            console.log('response', response);
            if(response.data.data){
              // localStorage.setItem('primaryColor', this.primary);
              // localStorage.setItem('secondaryColor', this.secondary);
              localStorage.setItem('tertiaryColor', response.data.data.tertiary_color);
            }
            this.alertString = "Color changed successfully |alert-yes|5000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            console.log('respose',error.response);
            this.alertString = `Upgrade your plan for change color|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    activeColor() {
      const primaryColor = this.primary;
      if(primaryColor){
        const primaryRgb = this.hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = this.secondary;
      if(secondaryColor){
        const secondaryRgb = this.hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    },
    async setPrimaryColor() {
      this.primary_color = this.primary;
      this.saveColor()
      const actualColor = this.primary;
      const rgb = this.hexToRgb(this.primary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--primary-color', actualColor);
      document.documentElement.style.setProperty('--primary-color-r', r);
      document.documentElement.style.setProperty('--primary-color-g', g);
      document.documentElement.style.setProperty('--primary-color-b', b);
    },
    async setSecondaryColor() {
      this.secondary_color = this.secondary;
      this.saveColor();
      const rgb = this.hexToRgb(this.secondary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--secondary-color', this.secondary);
      document.documentElement.style.setProperty('--secondary-color-r', r);
      document.documentElement.style.setProperty('--secondary-color-g', g);
      document.documentElement.style.setProperty('--secondary-color-b', b);
    },
    createStringFromSlug(slug) {
      let words = slug.split('-');
      words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      return words.join(' ').toUpperCase();
    }
  },
  mounted() {
    
    getAPI.get(`ressources/${this.$route.params.id}/`).then((response) => {
      if(response.status == 200) {
        this.url_pdf = response.data.resources_link
        //let msg = 'Ficher PDF trouvé avec succès!'
        //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
      }
      this.exitResourceModal = Date.now()
    }).catch((error) => {
      if (error.response) {
        //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
      }
      else if (error.request) {
        //let msg = 'The request was made but no response was received. Please check your network.'
        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
      }
      else {
        //let msg = 'Oups ! something went wrong.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      this.exitResourceModal = Date.now()
    });

    document.querySelector('iframe').onload = () => {
      this.waitingHttpResponse = false
    }
    // document.getElementById('cfa-menu-swiper').click()
    // document.getElementById('cfa-nav-bottom-swiper').click()
  }
}
</script>

<style scoped>
.cfa-page{

}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.courseIframe iframe{
  padding: 0;
  overflow-y: hidden;
}
.courseIframe{
  width: 100%;
  min-height: 100vh;
  /* margin-top: 5em; */
}
.iframeAction{
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.bg-summary{
  background-color: #f3f3f3;
}
</style>
