<template>
  <div class="w-100 mt-" style="overflow-x: scroll;">
    <div class="col-12 pe-0" style="min-width: 800px;">
      <!-- filter tools -->
      <div :class="'filter-zone d-flex align-items-center justify-content-between ' + classFilter">
        <div>
          <div>
            <div class="opc-searchbar-wrapper" >
              <img width="15" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAqCAYAAAAu9HJYAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQxSURBVHgBzVnNVtNAFP5m0lQEwXgU7c6+gXHnjrBziU+g7nRleQLhCYSdO+vSlbB0ZfoExiew7nrERVDk9BDa8d42k0zSAmkpDd+m85PMfJn7N/dWYAo4juv0bGwo4JEEPCXgQKGePCDQpn6b5gMh0LJO4IdhEGJKiEkevnXP9WjTt9T0MCkE9lQfu0e/Ax8TohDJS5EbRTuKsN4Ng3bRF84lyWLt23hLYmuMmW4pBV8oBFEPgbnpguPWK5WB+PnjPPpdG7Pxzp+DYBOXIUkE66R3X6lZN4ZDIrZbOcXOJDrm1GitHrao+Tw3VehURWGCCntRBZvdTnExFSR7IVFRgGDYV9j+9zvYwYywdM9tSIF3KEhU5Aiya/mWIQis/zsIAswYS6uuKxUdBruvIZFAEtFxaiTNDhuJQRBXRZDB6/YF1nWfjNON9x+BpRv8ZfQ1Td0nEW+SiPdwhYiOOx17sXZIHuBpPPSkulhrnRx32uZz0mh8Nsabs9TB8xDv4+t+7I8zGJBcvutuwBBzZGEbc4Rl4SXJW+uix8HDnB+QpNhruoTmZdzMNAhpP9LJXd3Pn6Zkl0ODG3pg3qeoQQdl6r/LnkZ3ZM+Ca0y25n2KGrEX8eOu06um9wRyVWmHYzFKBN2SviPl4um2FBKPjOd8lAiRtfK6brPhJJ3TU/EDJcKykQYOlR5ehmQ3/PYTZaILMySmhoNrhFzcvp4kTbdDSAgzybbuLDiPH6JERIbqCZHykioNR7Cr6jFKhLRSksTrMBknK2olE/2MY587Mj4bqaWzn0w6dMRrKBPG/sTF121pRZmY6fG9EiUgvkMke3NBQbdlbPbJAKWoGygBveytvGm6o+FVTaU3H/qaN5w3Y45whvu90H3i89GcH5CMSx++fse28QFzRM/cT2A/X4pJnLl5mgRv5YHbwBywct/NlG8iOVotSRIxTn6qN2t3SNxPBgMKT8clRbPE8n1KWxTe6z4f1PGv0eQvExYrVF0QyLikz/l8Y2YEV93nlDMnYuZ9Scxb457NkGSLIq//DGlIcojo11mLfmXVfQNOn0VyiQhPeN8zYOUHukedcHG5tk8en13RcBES/Y2lWl1Wa99Pu52pi6FsxfZyjVPnV7mpBUvBIdXaH/fe2VU1Li71qQyiMlU1RpPqNtuT1Bfj+iZnpC9yU5wumJlB8+9B8BJFSRobbI1L2FmH+uy2FPYp0wvtCG1WF75uddmNVeGSIazRcxxFvPz7nMIeHQSNMeuPEC1U6Y1Ldk1gJrG9RR/XMGtMFxG1UACsp6QvTXZJtBh/2KTxPaS3vtDJvmYL5hqQOUlr+7S2iKvCDJdtQOvoRIV9jUGJ0KJ/HSSVm5kwZ3YKtzE0NCZ0SO4lUMN/IfaoMhwUqQyfdaJTkbxK5Ilyda+QuOeJvOjp9xOuK7jSNwibhP+ljMkC4t/IngAAAABJRU5ErkJggg==" alt="search icon">
              <input type="search"  id="filter-input" v-model="filterText" placeholder="Type to filter" style="background-color: rgb(255, 255, 255); border: 1px solid rgb(229, 232, 242);">
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div>
            <label for="range-select" class="table-select-label">Rows per page:</label>
            <select id="range-select" v-model="paginationRange" class="table-select" style="height: 2.9em; border-radius: 5px;">
              <option v-for="option in rangeOptions" :key="option" :value="option">{{ option }}</option>
            </select>
          </div>
          <div class="ml-1">
            <slot></slot>
          </div>
        </div>
      </div>

      <div v-if="filteredData.length">
        <!-- table -->
        <table v-if="allowExtension" class="customized-table">
          <thead class="opc-block">
            <tr>
              <th style="width: 3em; padding: 0.5em;"></th>
              <th v-for="(column, index) in columns" :key="index"  :style="{width: (index === columns.length - 1) ? lastCellWidth : ((absoluteWidth) ? absoluteWidth : ''), minWidth: thWidth ? thWidth : '', maxWidth: maxThWidth ? maxThWidth : ''}">{{ column }}</th>
            </tr>
          </thead>
          <tbody class="opc-blocks" v-for="(item, index) in filteredData" :key="item.id">
              <tr :key="index + '-summary'" class="opc-table-row opc-blockf">
                <td v-if="allowExtension" style="padding: 0.5em; text-align: center;">
                  <button @click="showDetails(item)" class="btn-custom bg-gray uppercase rounded-full dropdown_btn">
                    <i :class="[item.showDetails ? 'fa fa-chevron-up' : 'fa fa-chevron-down']"></i>
                  </button>
                </td>
                <td v-for="(column, index) in columns" @click="action($event, column, item)" :key="index" v-html="item[column]"></td>
              </tr>
              <tr :key="index + '-details'" v-if="item.showDetails" class="opc-table-row-details">
                <td :colspan="columns.length+1" style="position: relative;">
                  <!-- <pre v-html="highlightJSON(JSON.stringify(item, null, 2))"></pre> -->
                  <div v-show="!waitingAPIResponse" class="cfa-page pt-1">
                    <div class="tab-body p-3">
                          <h5 class="mb-0">Chronologie des actions de Généreux Akotenou</h5>
                          <p>Email: genereux@cyberspector.com</p>
                          <br>
                          <div class="shadow-custom p-2">
                              <div class="p-2  overflow-hidden">
                                  <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                          <div class="bg-success rounded-5 p-4 d-flex align-items-center justify-content-between" style="height: 2em; width: 2em;">
                                              <i class="fa fa-rocket fa-spider fa-12x text-white" style="left: -0.47em;"></i>
                                          </div>
                                          <p class="ps-3"><b>Campagne créée</b></p>
                                      </div>
                                      <p>	04/04/2023 à 09:46:17</p>
                                  </div>
                              </div>
                              <div class="p-2  overflow-hidden">
                                  <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                          <div class="bg-success rounded-5 p-4 d-flex align-items-center justify-content-between" style="height: 2em; width: 2em;">
                                              <i class="fa fa-envelope fa-spider fa-12x text-white" style="left: -0.47em;"></i>
                                          </div>
                                          <p class="ps-3"><b>Email envoyé</b></p>
                                      </div>
                                      <p>	04/04/2023 à 09:46:17</p>
                                  </div>
                              </div>
                              <div class="p-2  overflow-hidden">
                                  <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                          <div class="bg-warning rounded-5 p-4 d-flex align-items-center justify-content-between" style="height: 2em; width: 2em;">
                                              <i class="fa fa-envelope-open fa-spider fa-12x text-white" style="left: -0.47em;"></i>
                                          </div>
                                          <p class="ps-3"><b>Email ouvert</b></p>
                                      </div>
                                      <p>	04/04/2023 à 09:46:17</p>
                                  </div>
                              </div>
                              <div class="p-2  overflow-hidden">
                                  <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                          <div class="bg-warning rounded-5 p-4 d-flex align-items-center justify-content-between" style="height: 2em; width: 2em;">
                                              <i class="fa fa-mouse-pointer fa-spider fa-12x text-white" style="left: -0.19em;"></i>
                                          </div>
                                          <div>
                                              <p class="ps-3"><b>Email cliqués</b></p>
                                          </div>
                                      </div>
                                      <p>	04/04/2023 à 09:46:17</p>
                                  </div>
                                  <div class="ps-5">
                                      <div class="ps-3">
                                          <h5 class="fs-6">
                                              <i class="fa fa-apple"></i>
                                              MAC OS (Os version 10.12.4)
                                          </h5>
                                      </div>
                                      <div class="ps-3">
                                          <h5 class="fs-6">
                                              <i class="fa fa-chrome"></i>
                                              Chrome (Version 69.0.3.10009)
                                          </h5>
                                      </div>
                                      <div class="ps-3">
                                          <h5 class="fs-6">
                                              <i class="fa fa-address-card"></i>
                                              Pays: Bénin, IP: 12.89.98.4, Long: 123"45, Lal: 95"45
                                          </h5>
                                      </div>
                                  </div>
                              </div>
                              <div class="p-2  overflow-hidden">
                                  <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                          <div class="bg-danger rounded-5 p-4 d-flex align-items-center justify-content-between" style="height: 2em; width: 2em;">
                                              <i class="fa fa-exclamation fa-spider fa-12x text-white"></i>
                                          </div>
                                          <p class="ps-3"><b>Données envoyées</b></p>
                                      </div>
                                      <p >	04/04/2023 à 09:46:17</p>
                                  </div>
                                  <div class="ps-5">
                                      <div class="ps-3">
                                          <h5 class="fs-6">
                                              <button class="btn cfa-btn-primary">
                                                  <i class="fa fa-key"></i>
                                                  VOIR DONNÉES SENSIBLES COLLECTÉES
                                              </button>
                                          </h5>
                                      </div>
                                  </div>
                              </div>
                          </div>
                  </div>
                  </div>
                  <div v-show="waitingAPIResponse" class="cfa-page">
                    <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
                      <div class="preloader_center d-flex flex-column">
                        <img src="@/assets/brand/cfa-favicon1.png" alt="cfa preloader">
                        <p class="pl-3">loading . . .</p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
          </tbody>
        </table>
        <table v-else class="customized-table">
          <thead class="opc-block">
          <tr>
            <th v-if="allowExtension" style="width: .5em; padding: 0.5em;"></th>
            <th v-for="(column, index) in columns" :key="index"  :style="{width: (index === columns.length - 1) ? lastCellWidth : ((absoluteWidth) ? absoluteWidth : ''), minWidth: thWidth ? thWidth : '', maxWidth: maxThWidth ? maxThWidth : ''}">{{ column }}</th>
          </tr>
          </thead>
          <tbody class="opc-block">
            <tr v-for="(item, index) in filteredData" :key="index" class="opc-table-row">
              <td v-for="(column, index) in columns" @click="action($event, column, item)" :key="index" v-html="item[column]"></td>
            </tr>
          </tbody>
        </table>

        <!-- pagination -->
        <div class="pagination opc-block" style="background-color: transparent;">
          <button class="btn bg-light" @click="previousPage" :disabled="currentPage === 1">Previous</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button class="btn bg-cfa-primary" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center align-items-center oups" style="min-height: 60vh">
        <div class="d-flex flex-column">
          <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 11em;"/>
          <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Oops ! Nothing yet.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hljs from 'highlight.js'
import 'highlight.js/styles/github.css'
import {getAPI} from "@/axios-api";

export default {
  name: 'TableComponent',
  components: {
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    columnsAction: {
      type: Array,
      required: true,
    },
    defaultRange: {
      type: Number,
      default: 10,
    },
    lastCellWidth: {
      type: String,
      default: '245px'
    },
    classFilter: {
      type: String,
      default: ''
    },
    thWidth: {
      type: String,
      default: null
    },
    maxThWidth: {
      type: String,
      default: null
    },
    absoluteWidth: {
      type: String,
      default: null
    },
    allowExtension: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      filterText: '',
      paginationRange: this.defaultRange,
      currentPage: 1,
      selectedOrgUser: [],
      waitingAPIResponse: true
    };
  },
  computed: {
    filteredData() {
      return this.data.filter(item => {
        return Object.values(item).some(value => {
          return String(value).toLowerCase().includes(this.filterText.toLowerCase());
        });
      });
    },
    rangeOptions() {
      return [10, 20, 50, 100];
    },
    totalPages() {
      return Math.ceil(this.filteredData.length / this.paginationRange);
    },
    visibleData() {
      const startIndex = (this.currentPage - 1) * this.paginationRange;
      const endIndex = startIndex + this.paginationRange;
      return this.filteredData.slice(startIndex, endIndex);
    },
    initListener () {
      alert('o')
      return false;
    }
  },
  methods: {
    previousPage() {
      this.currentPage--;
    },
    nextPage() {
      this.currentPage++;
    },
    action (event, column, item) {
      if(this.columnsAction.includes(column)) {
        let elm = event.target
        if (elm.classList.contains('btn-action')) {
          let payload = {
            id: elm.getAttribute('btn-id'),
            action_type: elm.getAttribute('btn-action'),
            name: item.name,
          };
          this.$emit('action', payload)
        }
      }
    },
    showDetails(item) {
      item.showDetails = !item.showDetails;
      // console.log("item")
      // console.log(item)
      this.waitingAPIResponse = true
      getAPI.get(`accounts/users/organisation/${item.id}/`)
        .then(response => {
          if(response.status == 200) {
            let users = response.data.data;
            this.selectedOrgUser = []
            for(let i=0; i<users.length; i++) {
              let row = users[i]
              let email = `<a style="color: #175ccc; font-weight: 400;" href="mailto:${row.email}">${row.email}</a>`
              // let btn = `<div class="d-flex align-items-center">
              //               <button btn-id="${row.id}" btn-action="view" class="btn cfa-btn-primary btn-action">EDITER</button>
              //               <button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-secondary btn-action"><i class="fa fa-eye"></i></button>
              //               <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-trash"></i></button>
              //          </div>`
              let btn = `<div class="d-flex align-items-center">`;
              btn += (row.is_active) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action"><i class="fa fa-eye"> COMPTE ACTIF&nbsp;&nbsp;</i></button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-eye-slash"></i> COMPTE INACTIF</button>`;
              btn += `</div>`;
              let progress = `<div class="cfa-progress-bar2 my-4 w-100">
                <div class="d-flex align-items-center w-100">
                  <div class="position-relative cfa-progress-wrapper w-100" style="background-color: rgb(205, 240, 243); height: 1em; border-radius: 3px;">
                    <div class="cfa-progress-line" style="background-color: #06bbc4;width:${40}%; height: 1em; border-radius: 3px;'"></div>
                  </div>
                  &nbsp;
                  <span style="min-width: 3em;">${ 40 }%</span>
                </div>
              </div>`;
              // classify and add to array
              this.selectedOrgUser.push({'n°': (i+1), 'Nom & Prénoms': row.firstname + ' ' + row.lastname, 'Email':email, 'Téléphone':row.phone, 'CyberScore': 0, 'Progression': progress, 'Fin Souscription': 'undefined', 'Actions': btn, name: row.email, id: row.id})
            }
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
            if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    highlightJSON(json) {
      const highlighted = hljs.highlight('json', json, true)
      return `<span class="hljs">${highlighted.value}</span>`
    },
    getCallToAction (payload) {
      if(payload.action_type == 'activate'||payload.action_type == 'deactivate') {
        getAPI.put(`accounts/users/change-statut/${payload.id}/`, {
          is_active: (payload.action_type == 'activate') ? true : false
        }).then(response => {
          if(response.status == 200) {
            //let msg = 'Utilisateur MAJ avec succès.'
            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.getMembers()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
        }).catch(() => {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        })
      }
    },
  },
};
</script>

<style scoped>
  .opc-searchbar-wrapper {
    /*min-width: 23em;*/
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    .opc-searchbar-wrapper[data-v-5a2240de] {
      width: 100%;
      min-width: 10em;
      position: relative;
    }
  }
  .opc-searchbar-wrapper img {
    position: absolute;
    top: 0.65em;
    bottom: 0.5em;
    left: 1em;
  }
  .opc-searchbar-wrapper input {
    outline: none !important;
    border-radius: 0.5em;
    padding: 0.5em 1.2em 0.4em 3em;
    width: 100%;
    border: none;
    background-color: #f0f3f8;
    color: #2e354d;
    box-shadow: 0px 0px 35px 7px rgb(246 249 254 / 96%);
    /*box-shadow: -1px 1px 29px 20px rgb(246 249 253 / 87%);*/
  }
  .filter-zone{
    padding: 0.5em;
  }
  .customized-table{
    position: initial;
  }
  .pagination{
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .pagination span{
    margin: 1em;
  }
  .opc-block{
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgb(246 249 253 / 87%);
    -webkit-box-shadow: -1px 1px 29px 20px rgb(246 249 253 / 87%);
    -moz-box-shadow: -1px 1px 29px 20px rgb(246 249 253 / 87%);
  }
  .opc-table-row{
    border-bottom: 1px solid #eceff5;
  }
  .opc-table-row:last-of-type{
    border-bottom: none;
  }
  .opc-table-row:hover{
    box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  }
  .empty-label{
    position: relative;
    left: 1em;
  }
  .table-select{
    padding: 0.3em 1.5em 0.3em 0.5em;
  }
  .table-select-label{
    padding-right: .5em;
  }

  table{
      width: 100% !important;
      position: relative;
      top: 1em;
      overflow-x: auto !important;
  }
  thead{
      background-color: #fff;
      border-radius: 0.5em;
      padding: .5em 1em;
      /*box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);*/
      /*-webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);*/
      /*-moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);*/
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
  }
  thead tr th{
      color: var(--primary-color);
      padding: 1em;
  }
  thead tr th{
      min-width: 210px;
  }
  thead tr th:first-of-type{
      min-width: 10px !important;
  }
  thead tr th:last-of-type{
      width: 210px;
  }
  tbody{
      box-shadow: none;
      margin-top: 0.2em;
      background-color: #fff;
      border-radius: 0.5em;
      padding: 0.5em 1em;
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
      border-top: 2px solid #eceff5!important;
  }
  tbody tr{
      border-bottom: 1px solid #eceff5;
  }
  tbody tr td{
      padding: 1em;
  }
  .dataTables_info{
      float: left;
      position: relative;
      top: 2.5em;
  }
  .dataTables_paginate{
      float: right;
      position: relative;
      top: 2.5em;
  }
  .paginate_button{
      padding: 0.5em;
      border: 1px solid #ddd;
      margin: 0.5em;
      background: #f1eded;
      text-decoration: none;
      color: #000;
  }
  .current{
      background: var(--primary-color);
  }
  @media only screen and (max-width: 600px) {
      .dataTables_length{
          display: none !important;
      }
      .dataTables_filter{
          width: 100%;
          float: left !important;
      }
  }
  .fa-12x{
      font-size: 1.5em;
      position: relative;
      left: -0.15em;
  }
  .dropdown_btn{
      border: none;
      color: var(--primary-color);
      background-color: rgba(243, 243, 243, 0.78);
  }
</style>
