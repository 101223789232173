<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString">
      <template v-slot:title>
        <div v-show="!waitingAPIResponse" class="w-100 pt-5 text-start mb-3">
          <h1 v-if="moduleData != null" class="cfa-section-title"><span class="cfa-secondary">MODULE {{ moduleData.number }} :</span> {{ moduleData.title.toUpperCase() }}</h1>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <!-- col1 -->
          <div class="d-flex flex-column flex-md-row mt-3 flex-1">
            <div v-if="moduleData != null" class="col-12 col-md-8 pe-0 pe-md-2">
              <div class="cfa-welcome ml-0">
                <div class="cfa-welcome-video">
                  <!--<c-f-a-video :autoplay="true" :videoLink="moduleData.module_video" placeholder="https://cci-occitanie.cyberspector.com/spectorly.svg" :showLabel="false"></c-f-a-video>-->
                  <c-f-a-video placeholder="./spectorly.svg" :autoplay="true" :video-link="moduleData.module_video" :showLabel="false" :max-height="30.5" :video-height="400"></c-f-a-video>
                </div>
                <div class="py-3 d-flex justify-content-between">
                  <button class="btn btn-discret cfa-btn-primary">
                    <img width="10" src="../../assets/Icons/All_Icon/Vector-7.png" alt="cfa assets"/>
                    MODULE {{ moduleData.number }}
                  </button>
                  <router-link :to="{name: 'ModuleIframe', params: {id: moduleData.id, slug: this.stringToSlug(moduleData.title)}}">
                    <button class="btn cfa-btn-primary" :disabled="moduleCheck.progression != 100">
                      {{ $t('CONTINUER') }}
                      <i class="fa fa-arrow-right"></i>
                    </button>
                  </router-link>
                  <!-- <button class="btn cfa-btn-primary" @click="redirectToModuleIframe">
                    CONTINUER
                    <i class="fa fa-arrow-right"></i>
                  </button> -->
                </div>
                <div class="pb-3 pt-4">
                  <h1 class="cfa-section-title fs-1/2 sub-heading">{{ moduleData.title.toUpperCase() }}</h1>
                </div>
                <div class="py-3 d-flex">
                  <div class=" d-flex align-items-center">
                    <img width="13" src="../../assets/Icons/All_Icon/Vector-24.png" alt="cfa assets image"/>
                    <span class="ms-2">{{ $t('duration') }}: <b class="cfa-primary">{{ moduleData.duration }}</b></span>
                  </div>
                  <div class="ms-2 d-flex align-items-center">
                    <img width="13" src="../../assets/Icons/All_Icon/Vector-9.png" alt="cfa assets image"/>
                    <span class="ms-2">{{ $t('Lang') }}: <b class="cfa-primary">FRANÇAISE</b></span>
                  </div>
                </div>
                <br>
                <div class="py-0">
                  <h1 class="cfa-section-title fs-1/2">DESCRIPTION</h1>
                  <hr>
                  <!--<p class="w-100">Aperçu du module</p>-->
                  <p class="w-100" style="color: #132B4D;">
                    {{ moduleData.resume }}
                  </p>
                  <p class="d-flex align-items-center">
                    <img width="10" src="../../assets/Icons/All_Icon/Vector-10.png" alt="cfa assets"/>
                    <strong>
                      <!--<span v-for="tag,i in moduleData.tags" v-bind:key="i">#{{ this.tags[tag] }}&nbsp;&nbsp;</span>-->
                      <span v-for="tag,i in moduleData.tags" v-bind:key="i">#{{ tag.name }}&nbsp;&nbsp;</span>
                      <!-- Mot de passe, Couriel, Virus -->
                    </strong>
                  </p>
                  <!--<br>
                  <p class="w-100">Quelle est la cible ?</p>
                  <p class="w-100" style="color: #a9a9a9;">
                    {{ moduleData.target }}
                  </p>-->
                  <hr>
                </div>
                <!--<div class="py-3">
                  <h1 class="cfa-section-title fs-1/2">RESSOURCES</h1>
                  <div class="mt-4 cfa-welcome cfa-resources-course p-4">-->
                    <!-- <h3>Semaine 01</h3> -->
                    <!--<div>
                      <div class="d-flex flex-column">

                        <div v-if="linkedRessource.length != 0" >
                          <div v-for="ressource in linkedRessource" v-bind:key="ressource.id" class="cfa-border-top-light py-4 mt-0 d-flex align-items-center justify-content-between">
                            <div>
                              <img width="15" src="@/assets/Icons/All_Icon/Vector-11.png" alt="cfa assets"/>&nbsp;
                              <span><b>Lecture</b> : {{ ressource.title.toUpperCase() }}</span>
                            </div>
                            <router-link class="linke" :to="{name: 'ResourcesIframe', params: {id: ressource.id, slug: ressource.title.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')}}">
                              <button class="btn cfa-btn-primary cfa-btn-discret">ACCÉDER À LA RESSOURCE</button>
                            </router-link>
                          </div>
                        </div>
                        <div v-else class="d-flex justify-content-center align-items-center oups emptyBlock">
                          <p>AUCUNE RESSOURCE RATTACHÉE A CE MODULE !</p>
                        </div>-->

<!--                        <div class="cfa-border-top-light py-4 mt-0 d-flex align-items-center justify-content-between">-->
<!--                          <div>-->
<!--                            <img width="15" src="../../assets/Icons/All_Icon/Vector-11.png" alt="cfa assets"/>&nbsp;-->
<!--                            <span><b>Lecture</b> : Lorem lorem lorem lorem</span>-->
<!--                          </div>-->
<!--                          <button class="btn cfa-btn-primary cfa-btn-discret">2 QUESTIONS</button>-->
<!--                        </div>-->

<!--                        <div class="cfa-border-top-light py-4 mt-2 d-flex align-items-center justify-content-between">-->
<!--                          <div>-->
<!--                            <img width="15" src="../../assets/Icons/All_Icon/Vector-12.png" alt="cfa assets"/>&nbsp;-->
<!--                            <span><b>Video</b> : Lorem lorem lorem lorem</span>-->
<!--                          </div>-->
<!--                          <span>15 minutes</span>-->
<!--                        </div>-->
<!--                        <div class="cfa-border-top-light py-4 mt-2 d-flex align-items-center justify-content-between">-->
<!--                          <div>-->
<!--                            <img width="15" src="../../assets/Icons/All_Icon/Vector-13.png" alt="cfa assets"/>&nbsp;-->
<!--                            <span><b>Audio</b> : Lorem lorem lorem lorem</span>-->
<!--                          </div>-->
<!--                          <button class="btn cfa-btn-primary cfa-btn-discret">2 QUESTIONS</button>-->
<!--                        </div>-->
<!--                        <div class="cfa-border-top-light py-4 mt-2 d-flex align-items-center justify-content-between">-->
<!--                          <div>-->
<!--                            <img width="15" src="../../assets/Icons/All_Icon/Vector-11.png" alt="cfa assets"/>&nbsp;-->
<!--                            <span><b>Lecture</b> : Lorem lorem lorem lorem</span>-->
<!--                          </div>-->
<!--                          <span>22 minutes</span>-->
<!--                        </div>-->

                      <!--</div>
                    </div>
                  </div>
                </div>-->
              </div>
            </div>
            <div v-else  class="col-12 col-md-8 pe-0 pe-md-2">
              <div class="cfa-slider-text-content">
                <div class="cfa-welcome ml-0 pt-5 d-flex align-items-center justify-vontent-center" style="height: 100vh;">
                  <br><br><br>
                  <div class='d-flex flex-column justify-content-between align-items-center py-5 w-100'>
                    <img width="150" src="../../assets/img/svg/cfa-ud-assets-05.svg" alt="cfa default image for no notification"/>
                    <p class="mt-5 text-center pb-5">😥️ {{ $t('no-content') }} !</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4 ps-0 ps-md-2 pt-4 pt-md-0">
              <div class="cfa-welcome py-3">
                <!--<a href="/mon-certificat#detail-de-progression" style="text-decoration: none;">
                  <div class="d-flex align-items-center justify-content-between cfa-state-view cfa-bg-primary py-2 px-4 mb-2">
                    <div class="d-flex justify-content-start align-items-center">
                      <div>
                        <DoughnutChart
                          :classValue="'chartTextStyle2'"
                          :width="80"
                          :height="80"
                          :value-count-up="true"
                          :percent=Math.floor(dashboardData?.advancement_module).toString().substr(0,3)
                          :percent-to-display="Math.floor(dashboardData?.advancement_module).toString().substr(0,3)"
                          :visibleValue="true"
                          foregroundColor="#fff"
                          backgroundColor="#b9ebec"
                          :strokeWidth="15"
                        />
                      </div>
                      <div class="ms-2">
                        <h5>{{ $t('progress1') }}</h5>
                        <p>{{Math.floor(dashboardData?.advancement_module).toString().substring(0,3)}} %</p>
                      </div>
                    </div>
                    <div>-->
                      <!--<i class="fa fa-chevron-right"></i>-->
                    <!--</div>
                  </div>
                </a>-->

                <a href="/quiz-final" style="text-decoration: none;">
                  <div class="row">
                  <div class="d-flex align-items-center justify-content-between cfa-state-view cfa-bg-primary py-2 px-4 mb-2">
                    <div class="d-flex justify-content-start align-items-center">
                      <div>
                        <DoughnutChart
                          :classValue="'chartTextStyle2'"
                          :width="80"
                          :height="80"
                          :value-count-up="true"
                          :percent=Math.floor(cyberscore).toString().substring(0,3)
                          :percent-to-display="Math.floor(cyberscore).toString().substring(0,3)"
                          :visibleValue="true"
                          foregroundColor="rgb(3, 57, 60)"
                          backgroundColor="#b9ebec"
                          :strokeWidth="15"
                        />
                      </div>
                      <div class="ms-2">
                        <h5>{{ $t('progress2') }}</h5>
                        <!--<p>{{ Math.floor(dashboardData?.certificat_score).toString().substring(0,3) }} Leçons</p>-->
                      </div>
                      
                    </div>
                    
                    <div>
                      <!--<i class="fa fa-chevron-right"></i>-->
                    </div>
                  </div>
                  <div class=" cfa-welcome py-3">
                    <div>
                        <progress-bar 
                    :moduleName="`<b class='ff-bold' style='color: var(--primary-color);'>${$t('my_progress1')}:</b>`"  
                    :percent="progression" :label="progression+'%'"></progress-bar>
                      </div>
                    </div>
                </div>
                </a>


                <!--<a href="/rendez-vous-expert" style="text-decoration: none;">
                  <div class="d-flex align-items-center justify-content-between cfa-state-view cfa-bg-light py-2 px-4">
                    <div class="d-flex justify-content-start align-items-center">
                      <div>
                        <DoughnutChart
                          :classValue="'chartTextStyle2'"
                          :width="80"
                          :height="80"
                          time="yes"
                          :value-count-up="true"
                          :percent=getPercent(dashboardData?.appointment_time,30).toString().substring(0,3)
                          :percent-to-display="dashboardData?.appointment_time.toString().substring(0,3)"
                          :visibleValue="true"
                          foregroundColor="#06bbc4"
                          backgroundColor="#b9ebec"
                          :strokeWidth="15"
                        />
                      </div>
                      <div class="ms-2">
                        <h5>{{ $t('progress3') }}</h5>
                        <p>Total: 30 min</p>
                      </div>
                    </div>
                    <div>-->
                      <!--<i class="fa fa-chevron-right"></i>-->
                    <!--</div>
                  </div>
                </a>-->
              </div>

              <h1 class="cfa-section-title mt-5 sub-heading">{{ $t('next_modules') }}</h1>

              <div v-if="nextModuleData.length != 0" class="d-flex flex-column py-3 mt-3">
                <course-card v-for="module,i in nextModuleData" v-bind:key="i" :id="module.id" :type="module.category" :preview="module.module_image" :label="'Module '+module.number" :started="false"  :title="module.title" :description="module.resume" :max__horaire="module.duration" :viewMode='displayMode' :filterMode="filterMode" cssClass="cfa-module col-12 mb-4"></course-card>
              </div>
              <div v-else>
                <div class="py-3 cfa-slider-text-content">
                
                    <div class='cfa-alert-d d-flex flex-column justify-content-between align-items-center py-5'>
                      <img width="150" src="../../assets/img/svg/cfa-ud-assets-05.svg" alt="cfa default image for no notification"/>
                      <p class="mt-5 text-center pb-5">😥️ {{ $t('nothing') }} </p>
                    </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">chargement . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import CFAVideo from '@/components/shared/video/CFAVideo.vue'
import DoughnutChart from '@/components/shared/chart/DoughnutChart.vue'
import CourseCard from '@/components/shared/course/CourseCard.vue'
import { getAPI } from '@/axios-api.js'
import ProgressBar from '@/components/shared/chart/ProgressBar.vue'

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav,
    ProgressBar,
    CFAVideo,
    DoughnutChart,
    CourseCard
  },
  data () {
    return {
      waitingAPIResponse: true,
      moduleData: null,
      nextModuleData: [],
      userGlobalState: null,
      tags: [],
      cyberscore:null,
      progression:null,
      moduleCheck:[],
      alertString: null,
      linkedRessource: [],
      dashboardData: null
    }
  },
  methods: {
    hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    retrieveOrg() {
      getAPI.get(`organisations/${sessionStorage.organisation}/`)
        .then(response => {
          if (response.status == 200) {
            this.organisationData = response.data
            this.primary = this.organisationData.primary_color;
            this.secondary = this.organisationData.secondary_color;
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    saveColor() {
      const payload = {
          primary_color: this.primary_color,
          secondary_color: this.secondary_color,
          tertiary_color: this.tertiary_color,
      };
      getAPI.put(`organisations/change_color/${sessionStorage.organisation}/`, payload)
        .then(response => {
          if (response.status === 200) {
            console.log('response', response);
            if(response.data.data){
              // localStorage.setItem('primaryColor', this.primary);
              // localStorage.setItem('secondaryColor', this.secondary);
              localStorage.setItem('tertiaryColor', response.data.data.tertiary_color);
            }
            this.alertString = "Color changed successfully |alert-yes|5000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            console.log('respose',error.response);
            this.alertString = `Upgrade your plan for change color|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    activeColor() {
      const primaryColor = this.primary;
      if(primaryColor){
        const primaryRgb = this.hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = this.secondary;
      if(secondaryColor){
        const secondaryRgb = this.hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    },
    async setPrimaryColor() {
      this.primary_color = this.primary;
      this.saveColor()
      const actualColor = this.primary;
      const rgb = this.hexToRgb(this.primary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--primary-color', actualColor);
      document.documentElement.style.setProperty('--primary-color-r', r);
      document.documentElement.style.setProperty('--primary-color-g', g);
      document.documentElement.style.setProperty('--primary-color-b', b);
    },
    async setSecondaryColor() {
      this.secondary_color = this.secondary;
      this.saveColor();
      const rgb = this.hexToRgb(this.secondary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--secondary-color', this.secondary);
      document.documentElement.style.setProperty('--secondary-color-r', r);
      document.documentElement.style.setProperty('--secondary-color-g', g);
      document.documentElement.style.setProperty('--secondary-color-b', b);
    },
    redirectToModuleIframe() {
      const moduleId = this.moduleData.id;
      const moduleSlug = this.stringToSlug(this.moduleData.title);
      window.location.href = `/module-iframe/${moduleId}/${moduleSlug}`;
      //this.$router.push({ name: 'ModuleIframe', params: { id: moduleId, slug: moduleSlug } });
    },
    stats () {
       getAPI.get(`stat_module/user/${sessionStorage.user}/${this.$route.params.id}/`).then(response => {
          if (response.status === 200) {
              this.cyberscore = response.data.data.cyberscore;
              this.progression = response.data.data.progression;
              //this.module_covered = response.data.length
              //console.log("Moduleeeee: ", progress )
              
              
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          setTimeout(() => {
              this.waitingAPIResponse = false
          }, 2000);
      }).catch(error => {
          if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::` + Date.now()
          } else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::` + Date.now()
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          this.waitingAPIResponse = false
      })
    },
    allModules () {
       getAPI.get(`modules/${this.$route.params.id}/`).then(response => {
          if (response.status === 200) {
              this.moduleData=response.data;
              //this.module_covered = response.data.length
              //console.log("Moduleeeee: ", progress )
              
              
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          setTimeout(() => {
              this.waitingAPIResponse = false
          }, 2000);
      }).catch(error => {
          if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::` + Date.now()
          } else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::` + Date.now()
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          this.waitingAPIResponse = false
      })
    },
    statModules () {
       getAPI.get(`stat_module/user/${sessionStorage.user}/${this.$route.params.id}/`).then(response => {
          if (response.status === 200) {
              this.moduleCheck=response.data.data;
              //this.module_covered = response.data.length
              //console.log("Moduleeeee: ", progress )
              
              
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          setTimeout(() => {
              this.waitingAPIResponse = false
          }, 2000);
      }).catch(error => {
          if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::` + Date.now()
          } else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::` + Date.now()
          } else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          this.waitingAPIResponse = false
      })
    },
    getPercent(value, maxValue) {
        if (maxValue === 0) {
            return 0;
        }
        return Math.min(100, Math.round((value / maxValue) * 100));
    },

    /* OLD VERSION : FIX WHEN CCI BLANK PAG ERROR OCCUR */
    /* ------------------------------------------------ */
    loopSelector_OLD(list, currentId, x) {
      let currentIndex = -1;
      for (let i = 0; i < list.length; i++) {
        if (list[i]._id === currentId) {
          currentIndex = i;
          break;
        }
      }

      let selected = [];
      for (let i = 0; i < x; i++) {
        let nextIndex = ((currentIndex + i + 1) % list.length) + 1;
        selected.push(list[nextIndex]);
      }
      return selected;
    },
    /* NEW VERSION */
    /* ------------------------------------------------ */
    loopSelector(list, currentId, x) {
      if (list.length <= 1) {
        // If the list has 0 or 1 element, return an empty list
        return [];
      }

      let currentIndex = -1;
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === currentId) {
          currentIndex = i;
          break;
        }
      }

      let selected = [];
      for (let i = 0; i < x; i++) {
        let nextIndex = (currentIndex + i + 1) % list.length;
        if(list[nextIndex].id !== currentId) {
          selected.push(list[nextIndex]);
        }
      }
      return selected;
    },
    /* ------------------------------------------------ */

    stringToSlug(str) {
      return str.replace(/\s+/g, '-').toLowerCase();
    }
  },
  mounted () {
    this.statModules();
    this.stats();
    this.allModules();
    this.waitingAPIResponse = true
    let promise1 = getAPI.get(`modules/${this.$route.params.id}/`);
    let promise2 = getAPI.get(`modules/all`);
    let promise3 = getAPI.get(`tags/`);
    let promise4 = getAPI.get(`ressources`);
    let promise5 = getAPI.get(`other/dashboard/`);
    let promise6 = getAPI.get(`other/cyberscores-global`);

    // api call 1
    Promise.all([promise1, promise2, promise3, promise4, promise5, promise6])
      .then(([response1, response2, response3, response4, response5, response6]) => {
        if(response1.status == 200 && response2.status == 200 && response3.status == 200 && response4.status == 200 && response5.status == 200 && response6.status == 201) {
          
          //get current module
          this.moduleData = response1.data;
           this.dashboardData =response6.data.data
           console.log("datamodule",this.moduleData);
          // get next modules
          this.nextModuleData = this.loopSelector(response2.data.data, this.moduleData.id, 2);
          console.log("nextModuleData", this.nextModuleData)
          

          // get tags
          let tags = {};
          for(let tag in response3.data.results) {
            tags[response3.data.results[tag].id] = response3.data.results[tag].name;
          }

          // filtering
          let filteringResource = [];
          let resources = response4.data.results;
          for(let i=0; i<resources.length; i++) {
            if(resources[i].module == this.$route.params.id) {
              filteringResource.push(resources[i])
            }
          }
          this.linkedRessource = filteringResource

          this.tags = tags;
          console.log("this.tags", this.tags)

          this.dashboardData = response5.data.data

          // stop pending
          this.waitingAPIResponse = false
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          this.waitingAPIResponse = false
        }
      })
      .catch(error => {
      console.log(error)
        //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
        this.waitingAPIResponse = false
      });
  }
}
</script>

<style scoped>
.cfa-page{

}
.gFolder-container{
  background-color: #cef1f3;
  border-radius: 0.5em;
  height: 4.3em;
  width: 4.6em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gBlock{
  align-items: center;
  justify-content: start;
  border-left: 4px solid #25c2cb;
  border-radius: 0.6em;
  padding-left: 2em;
  padding-right: 2em;
  height: 8em !important;
}
.gBlock div:last-of-type p{
  margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
  font-weight: 400;
  font-size: 2em;
}
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  /* margin: .5em; */
}
.cfa-warning-deadline{
  border: 2px solid #42cbd0;
  background-color: #d6f6f3;
  color: #20c2c9;
  border-radius: .3em;
  -webkit-box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
  box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
}
.cfa-alert-d{
  border-bottom: 1px solid #f3f3f3;
  transition: all ease-in .3s;
  background: #fff;
}
.cfa-alert-d:last-of-type{
  border: none;
}
.cfa-alert-d:hover{
  -webkit-box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
  box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
}
.cfa-state-view{
  border-radius: .5em;
}
.cfa-resources-course{
  box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
}
.cfa-border-top-light{
  border-top: 1px solid #ddd;
}
.cfa-border-top-light:first-of-type{
  border-top: none;
}
.emptyBlock{
  width: 100%;
  height: 5em;
  background-color: #fddbdb;
}
.cfa-section-title{
  color: #000 !important;
}
.cfa-state-view:hover{
  background: var(--secondary-color) !important;
}
</style>
