<template>
    <div class="form-group py-3 position-relative">
        <p class="mb-2 ff-bold cfa-primary"><strong>Heure du rendez-vous.</strong></p>
        <div class="d-flex">
            <div class="time-select form-control" @click="showPopup()">
                {{ (selectedTime !== '') ? selectedTime + ':' + selectedMinute : 'Choisir une plage horaire' }}
            </div>
        </div>

        <div v-if="showTimePopup" class="time-popup" style="width: 14.5em;">
            <div class="time-grid d-flex flex-column- flex-wrap" style="">
                <button
                    v-for="time in availableTimes"
                    :key="time"
                    class="time-button"
                    :class="{ selected: isSelected(time) }"
                    @click="selectTime(time)"
                >
                    {{ time }}
                </button>
            </div>

            <div class="confirm-button">
                <button class="cfa-btn-light" @click="confirmSelection">Quitter</button>&nbsp;
                <button class="cfa-btn-primary" @click="confirmSelection">Valider</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TimeSelector",
    data() {
        return {
            selectedTime: "",
            selectedMinute: "",
            showTimePopup: false,
            availableTimes: [
                "08:00", "09:00", "10:00", "11:00", "12:00", "13:00",
                "14:00", "15:20", "16:00", "17:00", "18:00", "19:00", "20:00"
            ]
        };
    },
    methods: {
        showPopup() {
            this.showTimePopup = true;
        },
        selectTime(time) {
            this.selectedTime = time.split(':')[0];
            this.selectedMinute = time.split(':')[1];
        },
        isSelected(time) {
            return (time.split(':')[0] === this.selectedTime) && (time.split(':')[1] === this.selectedMinute);
        },
        confirmSelection() {
            this.showTimePopup = false;
        }
    }
};
</script>

<style scoped>
.time-select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.time-popup {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    width: 350px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.tab {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tab.active {
    background-color: #ccc;
}

.time-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
}

.time-button {
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    min-width: 5.6em;
}

.time-button.selected {
    background-color: #cef1f2;
    color: #000;
}

.confirm-button {
    text-align: right;
    margin-top: 16px;
}

.confirm-button button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
</style>
