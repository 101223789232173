<template>
  <div class="cfa-media">
    <ul cllass="d-flex social-links">
      <li><a href=""><i class="fa fa-facebook"></i></a></li>
      <li><a href=""><i class="fa fa-twitter"></i></a></li>
      <li><a href=""><i class="fa fa-linkedin"></i></a></li>
      <li><a href=""><i class="fa fa-instagram"></i></a></li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SocialIcon',
}
</script>

<style scoped>
.cfa-media ul{
  color: #fff;
  list-style-type: none;
  padding: 0;
}
.cfa-media ul li{
  display: inline;
}
.cfa-media ul li a{
  color: #fff;
  transition: all ease-in .2s;
}
.cfa-media ul li a:hover{
  color: #06bbc4;
}
.cfa-media ul li a i{
  font-size: 1.4em;
  padding: .5em;
}
.cfa-media ul li:first-of-type a i{
  padding-left: 0;
}
</style>