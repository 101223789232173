<template>
    <div class="input-container-wrapper form-group">
      <input v-if="!this.autocomplete" @input="setInput" v-model="input" :id="uid+'-input'" :type="type" :placeholder="placeholder" class="input form-control form-control-alternative w-100" @focus="moveLabel" @blur="reinitLabel" autocomplete="new-password">
      <input v-else @input="setInput" v-model="input" :id="uid+'-input'" :type="type" :placeholder="placeholder" class="input form-control form-control-alternative w-100" @focus="moveLabel" @blur="reinitLabel">
        <label :for="uid+'-input'" :id="uid+'-label'" class="label transaition animated-label">{{placeholder}}</label>
        <div :id="uid+'-error1'" role="img" svgicon="icon-warning" class="alert-container">
            <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
                    <path d="M12,0 C18.6274821,0 24,5.37128483 24,11.9992057 C24,18.6271265 18.6274821,24 12,24 C5.37251787,24 0,18.6271265 0,11.9992057 C0,5.37128483 5.37251787,0 12,0 Z M12.0610526,16.2921801 C11.6274111,16.2921801 11.2470743,16.4443148 10.9496045,16.7417846 C10.6509806,17.0404085 10.5,17.4272963 10.5,17.8721801 C10.5,18.3192007 10.6525981,18.7054646 10.9552603,18.9986686 C11.2526499,19.2867647 11.6306317,19.4332327 12.0610526,19.4332327 C12.4824424,19.4332327 12.853225,19.2839566 13.1440797,18.9931019 C13.4373102,18.6998714 13.5842105,18.316035 13.5842105,17.8721801 C13.5842105,17.4304543 13.4388629,17.0459864 13.1496464,16.7474404 C12.8588101,16.4472222 12.48574,16.2921801 12.0610526,16.2921801 Z M12.0421053,3.6 C10.7366943,3.6 10.382605,3.91195124 9.99908447,4.38586429 C9.61556392,4.85977733 9.57781767,5.35178251 9.61556392,5.78586438 L9.61556392,5.78586438 L11.0691213,13.9428436 C11.090737,14.2257 11.1830848,14.4623412 11.3544724,14.6459708 C11.5367791,14.8412994 11.7761787,14.9469169 12.0421053,14.9469169 C12.3006556,14.9469169 12.5353068,14.8502958 12.7177639,14.6678387 C12.9005338,14.4850688 12.9939611,14.2398222 13.0145188,13.9520141 L13.0145188,13.9520141 L14.4698261,5.76900254 C14.5063929,5.35178251 14.338391,4.72918704 14.0294373,4.38586429 C13.7204835,4.04254153 13.3475162,3.6 12.0421053,3.6 Z"></path>
                </g>
            </svg>
        </div>
        <span :id="uid+'-error2'" class="alert-text"> {{ errorText }} </span>
        <div v-if="type == 'password'" @click="hideOrShow" :id="uid+'-toogle'" class="show-eye toggle-password" :style="'right: '+floatingRight+'px;'"></div>
    </div>
</template>

<script>
export default {
    name: "ModernInput",
    props: {
        uid: {
            type: String,
            default: 'cfa-uuid'
        },
        placeholder: {
            type: String,
            default: ''
        },
        errorText: {
            type: String,
            default: 'Incorrect value'
        },
        type: {
            type: String,
            default: 'text'
        },
        autocomplete: {
            type: Boolean,
            default: true
        },
        required: {
          type: Boolean,
          default: true
        },
        syncField: {
          type: String,
          default: null
        },
        inputValue: {
          type: String,
          default: ''
        }
    },
    data () {
        return {
            hideInput: true,
            floatingRight: 7,
            input: this.inputValue
        }
    },
    methods: {
        moveLabel () {
            let label = document.getElementById(this.uid+'-label')
            let input = document.getElementById(this.uid+'-input')
            label.style.display="block"
            label.style.top="-2.2em"
            label.classList.remove('danger')
            input.setAttribute('placeholder', '')
            // input.setAttribute('')
        },
        reinitLabel () {
            let label = document.getElementById(this.uid+'-label')
            let input = document.getElementById(this.uid+'-input')
            let error1 = document.getElementById(this.uid+'-error1')
            let error2 = document.getElementById(this.uid+'-error2')
            if(input.value == "" || (this.type == 'email' && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(input.value))) {
                error2.style.display="block"
                label.style.display="block"
                if(this.required) {
                  error1.style.display="block"
                  label.classList.add('danger')
                  input.classList.add('dangerFrame')
                }
                label.style.top="0em"
                input.setAttribute('placeholder', this.placeholder)
                // let t =
                setTimeout(()=>{
                    label.style.display="none"
                }, 200)
                this.floatingRight = 35
                // clearInterval(t)
            }
            else{
                error1.style.display="none"
                error2.style.display="none"
                this.floatingRight = 7
                input.classList.remove('dangerFrame')
            }
        },
        hideOrShow () {
            let input = document.getElementById(this.uid+'-input')
            let toogler = document.getElementById(this.uid+'-toogle')
            if(this.hideInput) {
                input.setAttribute('type', 'text')
                toogler.classList.remove('show-eye')
                toogler.classList.add('hide-eye')
            }
            else{
                input.setAttribute('type', this.type)
                toogler.classList.add('show-eye')
                toogler.classList.remove('hide-eye')
            }
            this.hideInput = !this.hideInput
        },
        setInput() {
            this.$emit('inputChange', this.input)
        }
    },
    mounted() {
        document.getElementById(this.uid+'-error1').style.display="none";
        document.getElementById(this.uid+'-error2').style.display="none";
    },
    watch: {
      syncField: function () {
        this.reinitLabel();
      }
    }
}
</script>

<style scoped>
    .toggle-password{
        position: absolute;
        top: 9px;
        right: 15px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        right: 35px;
    }
    .show-eye{
        background-image: url(@/assets/Icons/All_Icon/icon_show_password.svg);
    }
    .hide-eye{
        background-image: url(@/assets/Icons/All_Icon/icon_hide_password.svg);
    }
    .danger{
        color: #ff7483;
    }
    .dangerFrame{
        border-color: #ff7483;
    }
    .transaition{
        touch-action: manipulation;
        transition: all .2s;
    }
    .input-container-wrapper {
        position: relative;
        display: flex;
        margin-top: 0;
    }
    .input{
        position: relative;
        width: 900px;
        height: 38px;
        padding: 0 5px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
    }
    .label{
        position: absolute;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 8px 10px 0;
        font-size: 16px;
        pointer-events: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: text;
    }
    .alert-container{
        color: #ff7483;
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 8px;
        padding: 2px 0;
        font-size: 14px;
        font-weight: 600;
        transform: translateY(-55%);
        user-select: none;
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor;
        height: 24px;
        width: 24px;
        overflow: hidden;
    }
    .alert-container svg{
        position: relative;
        bottom: 1px;
    }
    .alert-text{
        position: absolute;
        top: 38px;
        left: 5px;
        font-size: 11px;
        color: #ff7483 !important;
    }
    .animated-label{
        display: none;
    }
</style>
