<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" css_class="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3">
          <h1 class="cfa-section-title">{{ $t('rdv') }}</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div class="cfa-page">
            <div class="d-flex flex-column flex-md-row mt-3">
              <div class="col-12 col-md-6 pe-0 pe-md-2 pt-3">
                <div class="cfa-welcome py-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="">{{ $t('appointment_now') }}</h5>
                  </div>
                  <hr>
                  <p><strong>{{ $t('our_cybersecurity') }}</strong></p>
                  <p class="text-md-left text-justify pt-3 pb-2">{{ $t('cybersecurity_experts') }}</p>

                  <div class="form-group py-3">
                    <p class="mb-2 ff-bold cfa-primary"><strong>{{ $t('your_appointment') }}</strong></p>
                    <input v-model="rdv_subject" id="inputLastname" class="form-control" name="inputLastname" type="text">
                  </div>

                  <div class="form-group py-3">
                    <p class="mb-2 ff-bold cfa-primary"><strong>{{ $t('appointment_date') }}</strong></p>
                    <calendar-input lang="fr" :events="events" @availableDate="getAvailableDate" @unavailableDate="getUnAvailableDate" />
                  </div>

                  <div class="form-group py-3 row">
                      <div class="col-6">
                          <div class="form-group py-3">
                              <p class="mb-2 ff-bold cfa-primary"><strong>Date</strong></p>
                              <input v-model="rdv_date" @click="giveGuideLineForDate" id="inputLastname" class="form-control" name="inputLastname" type="text" placeholder="jj/mm/aa" readonly style="background-color: #f7f7f7;">
                          </div>
                      </div>
                      <div class="col-6">
                          <time-range-input></time-range-input>
                          <!--<div class="form-group py-3">
                              <p class="mb-2 ff-bold cfa-primary"><strong>Heure du rendez-vous.</strong></p>
                              <div class="d-flex">
                                  <select class="form-control" v-model="selectedTime">
                                      <option value="">Choisir une heure</option>
                                      <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
                                  </select>
                                  <div style="padding: 0.5em; font-weight: 400;">:</div>
                                  <select class="form-control" v-model="selectedMinute">
                                      <option value="">Choisir une minute</option>
                                      <option value="00">00</option>
                                      <option value="15">15</option>
                                      <option value="30">30</option>
                                      <option value="45">45</option>
                                  </select>
                              </div>
                          </div>-->
                      </div>
                      <!--
                      <div class="col-6">
                          <div class="form-group py-3">
                              <p class="mb-2 ff-bold cfa-primary"><strong>Durée</strong></p>
                              <select v-model="rdv_duration" class="form-control">
                                  <option value="10" default selected>10 minutes</option>
                                  <option value="15" >15 minutes</option>
                                  <option value="20" >20 minutes</option>
                                  <option value="25" >25 minutes</option>
                                  <option value="30" >30 minutes</option>
                              </select>
                          </div>
                      </div> -->
                  </div>

                  <div class="form-group py-3">
                      <p class="mb-2 ff-bold cfa-primary"><strong>{{ $t('Duration') }}</strong></p>
                      <select v-model="rdv_duration" class="form-control">
                          <option value="10" default selected>10 minutes</option>
                          <option value="15" >15 minutes</option>
                          <option value="20" >20 minutes</option>
                          <option value="25" >25 minutes</option>
                          <option value="30" >30 minutes</option>
                      </select>
                  </div>

                  <div class="form-group py-3">
                    <p class="mb-2 ff-bold cfa-primary"><strong>{{ $t('better_understand') }}</strong></p>
                    <textarea v-model="rdv_detail" class="form-control h-100" name="inputLastname" style="min-height: 10em !important;" ></textarea>
                  </div>

                  <div class="w-100 pt-3">
                    <button @click="takeAppointment" class="btn cfa-btn-primary w-100 py-2  ff-bold">{{ $t('appointment_request') }}</button>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 ps-0 ps-md-2  pt-md-0">
                <div class="cfa-welcome ml-0 gNoShadow mt-3 py-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="">{{ $t('remaining_time') }}</h5>
                  </div>
                  <hr>
                  <div class="text-center pt-4 pb-3">
                    <DoughnutChart
                      :classValue="'chartTextStyle4'"
                      :width="160"
                      :height="160"
                      :valueCountUp="true"
                      :percent=getPercent(dashboardData?.appointment_time,30).toString().substring(0,3)
                      :percent-to-display="dashboardData?.appointment_time.toString().substring(0,3)"
                      time="yes"
                      :customTextSize="27"
                      :visibleValue="true"
                      foregroundColor="var(--primary-color)"
                      backgroundColor="#ededed"
                      :strokeWidth="20"
                    />
                  </div>
                  <div class="px-5 mt-4 text-center">
                    <button class="btn cfa-btn-primary w-75 no-event">{{ $t('cybersecurity_expert') }}</button>
                  </div>
                </div>

                <div class="cfa-welcome ml-0 gNoShadow mt-3 py-4" style="height: calc(100% - 365px)">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="">{{ $t('appointment_history') }}</h5>
                    <p>{{ $t('Details') }}</p>
                  </div>
                  <hr>
                  <div v-if="userAppointment.data == null">
                    <p>{{ $t('no_appointments') }}</p>
                  </div>
                  <div v-else>
                    <div class="container">
                      <div class="row px-0">
                        <div v-for="rdv in userAppointment.data" v-bind:key="rdv" class="col-md-12 col-12 mb-4 px-0">
                          <div class="card">
                            <div class="card-head" style="padding: 1em; background: #f4f4f4">
                              <div class="d-flex justify-content-between align-items-center">
                                <h5 class="mb-0">MEETTING</h5>
                                <button v-if="!rdv.is_valid" class="btn btn-danger" @click="deleteRDV"><i class="fa fa-trash"></i> {{ $t('CANCEL') }}</button>
                              </div>
                            </div>
                            <div class="card-body">
                              <h5 class="card-title">{{ $t('expert_meeting') }} : {{ rdv.subject.toUpperCase() }}</h5>
                              <p class="card-text fw-bold text-danger">Date: {{ formatDateFromString(rdv.date)[0] }} - {{ formatDateFromString(rdv.date)[1] }} - {{ formatDateFromString(rdv.date)[2] }}</p>
                              <p class="card-text">{{ $t('Hour') }}: {{ formatDateFromString(rdv.date)[3] }}:{{ formatDateFromString(rdv.date)[4] }}</p>
                              <p class="card-text">{{ $t('Subject') }}: {{ rdv.subject }}</p>
                              <p class="card-text">{{ $t('Details') }}:</p>
                              <div style="padding: 0.5em; margin-bottom: .5em;" class="cfa-bg-light-primary">{{ rdv.detail }}</div>
                              <hr>
                              <div v-if="rdv.link == null" style="background: #ffcccc;padding: 1.2em; display: flex; justify-content: center; align-items: center; flex-direction: column">
                                <a :href="rdv.link" target="_blank" class="fw-bold mt-1 mb-3 btn btn-danger">{{ $t('in_processing') }}</a>
                                <p style="text-transform: uppercase">{{ $t('soon-receive') }}</p>
                              </div>
                              <div v-if="rdv.link != null" style="background: #f4f4f4;padding: 1.2em; text-align: center">
                                <a :href="rdv.link" target="_blank" class="fw-bold  mt-1 mb-1 btn cfa-btn-primary">{{ $t('join-meeting') }}</a><!--  Rejoindre le Meeting -->
                                <p class="fw-bold my-2">{{ $t('copy_paist_link') }} </p>
                                <a target="_blank" :href="rdv.link" class="fw-bold cfa-primary">{{ rdv.link }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">chargement . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
    <!-- alert -->
    <transition name="fadeY" appear mode="out-in">
      <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
    </transition>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import DoughnutChart from '@/components/shared/chart/DoughnutChart.vue'
import CalendarInput from '@/components/shared/input/CalendarInput.vue'
import Alert from '@/components/shared/alert/Alert.vue'
import { getAPI } from '@/axios-api.js'
import TimeRangeInput from "@/components/shared/input/TimeRangeInput.vue";

export default {
  name: 'DashboardView',
  components: {
      TimeRangeInput,
    CFAFooter,
    CFANav,
    DoughnutChart,
    CalendarInput,
    Alert
  },
  data () {
    return {
      alertString: '',
      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',
      waitingAPIResponse: false,
      events: [],
      rdv_subject: '',
      rdv_date: '',
      rdv_time: '',
      rdv_detail: '',
      rdv_duration: '',
      allAppointment: [],
      filteredAppointment: [],
      userAppointment: [],
      dashboardData: null,
      hours: [8, 9, 10, 11, 14, 15, 16, 17],
      selectedTime: null,
      selectedMinute: null,
    }
  },
  methods: {
    getPercent(value, maxValue) {
        if (maxValue === 0) {
            return 0;
        }
        return Math.min(100, Math.round((value / maxValue) * 100));
    },
    displayError(error, type='alert-ok', time=5000) {
      this.gAlertMessage = error
      this.gAlertType = type
      this.alertMe = true
      setTimeout(() => {
          this.alertMe = false
      }, time);
    },
    clickDay(data) {
      console.log(data);
    },
    changeDate(data) {
      console.log(data);
    },
    clickToday(data) {
      console.log(data);
    },
    isPast(date) {
      const today = new Date()
      return date < today
    },
    getAvailableDate (date) {
      if(this.isPast(date)) {
        this.displayError(`Oups ! On ne peut pas remonter dans le passé. Du moins, pas encore 😂️. Veuillez sélectionner une date dans le futur pour prendre rendez-vous.`, 'alert-no', 8000)
      }
      else{
        this.rdv_date = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
        this.displayError(`La date du <b style="font-weight:bold;">${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}</b> est disponible. Completez le formulaire pour prendre rendez-vous cette date.`, 'alert-middle', 7000)
      }
    },
    getUnAvailableDate () {
      this.displayError('Oups ! Vous ne pouvez prendre rendez-vous ce jour. Un autre évènement y est prévu.', 'alert-no', 5000)
    },
    formatDate (date, time) {
      // console.log(date, time)
      // Convert date and time to UTC format
      const [_day, _month, _year] = date.split("/");
      const [_hours, _minutes] = time.split(":");
      const utcDate = new Date(Date.UTC(_year, _month - 1, _day, _hours, _minutes));

      // Format date as YYYY-MM-DD
      const year = utcDate.getUTCFullYear();
      const month = String(utcDate.getUTCMonth() + 1).padStart(2, "0");
      const day = String(utcDate.getUTCDate()).padStart(2, "0");

      // Format time as HH:MM:SS.sss
      const hours = String(utcDate.getUTCHours()).padStart(2, "0");
      const minutes = String(utcDate.getUTCMinutes()).padStart(2, "0");
      const seconds = String(utcDate.getUTCSeconds()).padStart(2, "0");
      const milliseconds = String(utcDate.getUTCMilliseconds()).padStart(3, "0");

      // Combine date and time in desired format
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}+00`;

      return formattedDate.split('.')[0];
    },
    giveGuideLineForDate () {
      // this.displayError('Référez-vous au calendrier précédent pour choisir une date de rendez-vous !.', 'alert-middle', 5000)
    },
    takeAppointment () {
      if(this.rdv_subject != '' && this.rdv_detail != '' && this.rdv_date !=  '' && !this.rdv_time && this.rdv_duration != '') {

        getAPI.post(`appointment/`, {
          subject: this.rdv_subject,
          duration: parseInt(this.rdv_duration),
          date: this.formatDate(this.rdv_date, this.rdv_time),
          detail: this.rdv_detail,
          user: sessionStorage.user,
        }).then((response) => {
          if(response.status == 201) {
            //this.alertString = `Votre rendez-vous a été bien enregistré|alert-yes|5000::`+Date.now()
            this.getAllAppointment();
            this.getUserAppointment();
          }
          else {
            this.waitingAPIResponse = false
            //this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
          }
        }).catch(() => {
          this.waitingAPIResponse = false
          //this.alertString = `Vous avez déjà excéder votre quota pour cette année !|alert-no|4000::`+Date.now()
        });
      }
      else{
        //this.alertString = `Veuillez remplir tous les champs convenablement|alert-no|5000::`+Date.now()
      }
    },
    formatDateFromString (value) {
      if (!value) return ''
      const date = new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      const hour = String(date.getHours()).padStart(2, '0')
      const minute = String(date.getMinutes()).padStart(2, '0')
      return [day, month, year, hour, minute]
    },
    getAllAppointment () {
      this.waitingAPIResponse = true;
      getAPI.get(`appointment/all`).then((response) => {
        if(response.status == 200) {
          this.allAppointment = response.data.data;
          this.events = []
          for (let i = 0; i < this.allAppointment.length; i++) {
            let array_date = this.formatDateFromString(this.allAppointment[i].date)
            let month = (array_date[1]-1 > 0) ? array_date[1]-1 : 12
            this.events.push(new Date(array_date[2], month, array_date[0]))
          }
          console.log("this.events", this.events)
        }
        else {
          //this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch((error) => {
      console.log(error)
        this.waitingAPIResponse = false
        //this.alertString = `${error.message||'Une erreur est survenu'}|alert-no|4000::`+Date.now()
      });
    },
    getUserAppointment () {
      this.waitingAPIResponse = true;
      getAPI.get(`appointment/user/${sessionStorage.user}`).then((response) => {
        if(response.status == 200) {
          this.userAppointment = response.data;
          console.log("xx", this.userAppointment)
        }
        else {
          //this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch((error) => {
      console.log(error)
        this.waitingAPIResponse = false
        //this.alertString = `${error.message||'Une erreur est survenu'}|alert-no|4000::`+Date.now()
      });
    },
    getDashboard () {
      this.waitingAPIResponse = true
      getAPI.get(`other/dashboard/`).then(response => {
        if(response.status == 200) {
          this.dashboardData = response.data.data
          console.log(this.dashboardData)
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch(error => {
        if (error.response) {
          //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false
      })
    },
    deleteRDV () {
      getAPI.delete(`appointment/${this.userAppointment.data.id}`).then((response) => {
        if(response.status == 204) {
          //this.alertString = `Rendez-vous annulé avec succès|alert-yes|4000::`+Date.now()
          this.getDashboard();
          this.getAllAppointment();
          this.getUserAppointment();
        }
        else {
          //this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch((error) => {
      console.log(error)
        this.waitingAPIResponse = false
        //this.alertString = `${error.message||'Une erreur est survenu'}|alert-no|4000::`+Date.now()
      });
    }
  },
  mounted() {
    this.getDashboard();
    this.getAllAppointment();
    this.getUserAppointment();
    // Promise.all([this.getDashboard(), this.getAllAppointment(), this.getUserAppointment()]).then(() => {
    //
    // })
  },
  watch: {
      selectedTime: function (value) {
          this.rdv_time = value + ':' + (this.selectedMinute ? this.selectedMinute : '00');
      },
      selectedMinute: function (value) {
          this.rdv_time = (this.selectedTime ? this.selectedTime : '00') + ':' + value;
      }
  }
}
</script>

<style scoped>
  .cfa-page{

  }
  .cfa-welcome{
    background-color: #fff;
    border-radius: 0.5em;
    padding: 1em 1em;
    
  }
  .form-control::placeholder {
    color: #999;
    opacity: 1;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    background-clip: padding-box;
    font-weight: 400;
    /* transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; */
  }
  .form-control input{
    background-color: #f7f8fc;
    border-color: #e0e0ec;
  }
  input, select {
    outline: none !important;
  }
</style>
