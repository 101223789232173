<template>
  <div class="cfa-progress-bar mt-3 w-100">
    <div class="d-flex justify-content-between align-items-center">
      <span class="" v-html="moduleName"></span>
      <span :style="(percent != 0) ? 'color: '+forgroundColor : 'color: #000;'">{{ label }}</span>
    </div>
    <div class="position-relative cfa-progress-wrapper" :style="(percent != 0) ? 'background-color: '+backgroundColor : 'background-color: var(--primary-light-color);'">
      <div class="cfa-progress-line" :style="'background-color: '+forgroundColor+';width:'+percent+'%;'"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    moduleName: {
      type: String,
      default: 'Module 1'
    },
    percent: {
      type: Number,
      default: 10
    },
    label: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: 'var(--primary-light-color)'
    },
    forgroundColor: {
      type: String,
      default: 'var(--primary-color)'
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped>
.cfa-progress-wrapper{
  padding: .5em;
  overflow: hidden;
  cursor: pointer;
  border-radius: .5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.cfa-progress-line{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* right: 0; */
}
</style>