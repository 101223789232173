<template>
  <div class="cfa-root">
    <c-f-a-header></c-f-a-header>
    <div class="cfa-root-container">
      <header class="text-start py-3 px-md-5 px-0">
        <h1>{{ $t('legal_notice') }}</h1>
      </header>
      <div class="container">
        <h2>Informations légales</h2>
        <div class="section_content">
          <p>Raison sociale : Nom de la société</p>
          <p>Forme juridique : Société par actions simplifiée (SAS)</p>
          <p>Adresse : 123 Rue de la Chicitude, 75001 Paris</p>
          <p>Téléphone : 01 23 45 67 89</p>
          <p>Email : contact@nomsociete.com</p>
          <p>Capital social : 10 000 €</p>
          <p>Numéro d'immatriculation : 123 456 789 RCS Paris</p>
          <p>Numéro de TVA intracommunautaire : FR 12 345 678 901</p>
        </div>


        <h2>Directeur de la publication</h2>
        <div class="section_content">
          <p>Nom et prénom du directeur de la publication</p>
        </div>

        <h2>Hébergement</h2>
        <div class="section_content">
          <p>Nom de l'hébergeur</p>
          <p>Adresse de l'hébergeur</p>
        </div>

        <h2>Propriété intellectuelle</h2>
        <div class="section_content">
          <p>Tous les contenus présents sur ce site sont protégés par le droit d'auteur et/ou la propriété intellectuelle. Toute utilisation, reproduction, diffusion ou représentation totale ou partielle de ces contenus est strictement interdite sans autorisation préalable de la société.</p>
        </div>

        <h2>Liens hypertextes</h2>
        <div class="section_content">
          <p>La société décline toute responsabilité quant au contenu des sites vers lesquels des liens hypertextes pourraient renvoyer depuis son propre site.</p>
        </div>

        <h2>Responsabilité</h2>
        <div class="section_content">
          <p>Bien Chic SAS décline toute responsabilité en cas de dommage résultant de l'utilisation du site.</p>
          <p>Les liens hypertextes présents sur le site peuvent renvoyer vers d'autres sites internet dont Bien Chic SAS n'a pas la maîtrise.</p>
        </div>

        <h2>Protection des données personnelles</h2>
        <div class="section_content">
          <p>Les données personnelles collectées sur le site ne sont utilisées que dans le cadre de la relation commerciale entre Bien Chic SAS et ses clients.</p>
          <p>Les données sont conservées pendant une durée de trois ans.</p>
        </div>
      </div>
    </div>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFAHeader from '@/components/nav/CFAHeader.vue'

export default {
  name: 'MentionsView',
  components: {
    CFAFooter,
    CFAHeader,
  },
  data () {
    return {
      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',
      waitingAPIResponse: false
    }
  },
  methods: {
  },
  mounted() {
    console.log(this.$route)
  }
}
</script>

<style scoped>
.cfa-root-container{
  min-height: 50vh;
  min-width: 100vw;
  text-align: center;
  padding-top: 1em;
}
 body {
   font-family: Arial, sans-serif;
   background-color: #F8F8F8;
   margin: 0;
   padding: 0;
 }

header {
  background-color: #272f44;
  color: #FFFFFF;
  /*padding: 20px;*/
  text-align: center;
}

h1 {
  font-size: 25px;
  margin-top: 0;
  padding-left:1em;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px #CCCCCC;
  text-align: left;
}

h2 {
  font-size: 18px;
  margin-top: 0;
}

p {
  margin-top: 0;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 20px;
}

li {
  margin-top: 10px;
}
h2{
  margin-bottom: 0;
  padding: .5em;
  background-color: #f1eeee;
}
.section_content{
  padding: .5em;
  background-color: #f6f6f6;
  margin-bottom: .5em;
}
</style>
