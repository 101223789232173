<template>
  <div class="gZebbraLoader">
    <div id="loading">
      <div class="preloader d-flex flex-column">
        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
        <p class="pl-3 fw-bold">loading . . .</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreLoader',

  components: {
  }

}
</script>

<style>
.gContainer{
  position: relative;
}
.preloader{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.preloader img{
  width: 7em;
  z-index: 1111;
}
</style>
