<template>
  <div>
    <div class="cfa-certificate-preview">
      <div class="w-100 h-100 position-relative">
        <div class="barL">
          <div class="position-relative cfa-decoration-certificate">
            <img style="position: absolute; right: 2.05em; z-index: 10; top: -2em;" width="50" src="../../../assets/Icons/All_Icon/Group1000004446.png" alt="cfa video placeholder"/>
          </div>
        </div>
        <div class="barl"></div>
        <div class="p-5 pb-2">
          <br><br>
          <p v-if="!isReady" class="text-danger" style="color: #132B4D">Votre certificat n’est pas disponible<br></p>
          <h3><strong>SPECTORLY</strong></h3>
          <p>{{lname}} {{fname}}</p>
          <br><br>
          <p class="pe-5">
            {{ $t('certificate_label') }}
          </p>
          <div class="text-end pe-5 pt-5">
            <p>SPECTORLY</p>
            <!--<small>Signature du Mentor</small>-->
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4 text-end">
      <button v-if="isReady" class="btn cfa-btn-primary">{{ $t('download') }}</button>
      <button v-else class="btn cfa-btn-danger" style="color: #132B4D">VOTRE CERTIFICAT N'EST PAS PRÊT !</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CertificatePreview',
  props: {
    fname: {
      type: String,
      default: ''
    },
    lname: {
      type: String,
      default: 'sur Spectorly'
    },
    isReady: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped>
.cfa-certificate-preview{
  border-radius: .5em;
  overflow: hidden;
  height: 27.5em;
  background-color: #fff;
}
.barL{
  position: absolute;
  top: 3em;
  left: 0;
  right: 0;
  padding: 0.5em;
  background: var(--primary-color);
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--primary-color) 57%, var(--primary-light-color-2) 100%);
}
.barl{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4em;
  padding: 0.5em;
  height: 100vh;
  background: var(--primary-color);
  background: linear-gradient(0deg, var(--primary-color) 0%, var(--primary-color) 57%, var(--primary-light-color-2) 100%);
}
</style>
