<template>
  <div>
    <div class="container_ col-12 p-0 p-md-4 d-flex flex-row flex-ss-column justify-content-between">
      <div class="col-md-5  text-start py-md-0 py-3 px-md-5 px-3 cfa-footer-brand">
        <a href="/">
          <img class="col-8 col-md-6" src="@/assets/brand/spec-brand-1.svg" style="width: 11em;"/>
        </a>
      </div>

      <div class="d-flex align-items-center px-3 pt-md-0 pt-3 justify-content-center">
        <a class="cfa-header-items cfa-bold_ mx-3 d-md-inline d-none" href="">{{ $t('landing_item1') }}</a>
        <a class="cfa-header-items cfa-bold_ mx-3 d-md-inline d-none" href="">{{ $t('landing_item2') }}</a>
        <a class="cfa-header-items cfa-bold_ mx-3 d-md-inline d-none" href="">{{ $t('landing_item3') }}</a>
        <a class="cfa-header-items cfa-bold_ mx-3 d-md-inline d-none" href="">{{ $t('landing_item4') }}</a>
        <a class="cfa-header-items cfa-bold_ mx-3 d-md-inline d-none" href="/certificate">{{ $t('ccheck') }}</a>
        <a href="/login" class="ms-3">
          <button class="btn cfa-btn-primary cfa-bold px-3 py-2 loginBtn">{{ $t('landing_item5') }}</button>
        </a>
        <select-button @action="changeLang" :dropDownLabel="['🇨🇵️ Fr', '🇺🇸️ En']" :dropDownLink="['go-to-fr', 'go-to-en']" label="<i class='fa fa-globe' style='font-size: 1.4em; color: #06bbc4 !important; position: relative; top: .05em;'></i>" uid="cfa-lang-swiper" :showArrow="false" cssStyle="border: none !important;" cssClass="d-md-block d-none"></select-button>
      </div>
    </div>
  </div>
</template>

<script>
import SelectButton from '@/components/shared/button/SelectButton.vue'

export default {
  name: 'CFAFooter',
  components: {
    SelectButton
  },
  data () {
    return {
      lang: (localStorage.language == 'en') ? 'EN' : 'FR'
    }
  },
  methods: {
  },

}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .loginBtn{
    font-size: 10px;
    position: relative;
    bottom: .5em;
  }
}
.cfa-header-items{
  color: #000;
  font-size: 1.3em;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
}
.cfa-lang{
  font-size: .80em;
  padding: .2em .3em;
  border-radius: .4em;
  border: 1px solid #bdbaba;
  cursor: pointer;
}
.cfa-footer div ul li strong, .cfa-footer div ul li a{
  color: #fff;
  text-decoration: none;
}
.cfa-footer div ul{
  list-style-type: none;
  text-align: left;
  font-size: 1.1em;
}
.cfa-footer div ul li strong{
  font-size: 1.5em;
}
.cfa-footer div ul li{
  margin: .5em;
}
.cfa-newsletter-text{
  font-size: 1.1em;
}
.cfa-footer div ul li a:hover{
  color: #06bbc4;
  text-decoration: underline;
}
.cfa-footer{
  padding-top: 4rem !important;
  padding-bottom: 3rem !important;
}
.cfa-footer-brand{
  /*border-bottom: 1px solid #ddd;*/
}
</style>
