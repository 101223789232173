<template>
    <div>
        <div class="card-body p-0 mt-3 mb-3">
            <div class="card-transparent cfa-warning-deadline py-3 px-2" :style="dynamicStyle">
                <div class="d-flex d-xs-grid">
                    &nbsp;
                    <img width="30" src="../../assets/Icons/All_Icon/Group1000004442.png" :style="'position: relative; top: .0em; filter: hue-rotate('+this.hue+'deg);'"/>&nbsp;&nbsp;
                    <div class="d-flex align-items-center justify-content-between w-100">
                    <h5 id="timer-countercallback" class="h3 mb-0 mt-0">{{day}} {{toMonthName(month)}} {{year}}</h5>
                    <!-- <h5 class="mb-0 mt-1 mb-0 d-none d-md-block">{{ $t('remain', {day: remainingDay}) }}&nbsp;</h5> -->
                    <h5 class="mb-0 mt-1 mb-0 d-none d-md-block"> {{ remainingDay }} jours !</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DeadLine',

    props: {
        day: {
            type: Number,
            default: 1,
            required: true
        },
        month: {
            type: Number,
            default: 11,
            required: true
        },
        year: {
            type: Number,
            default: 2022,
            required: true
        },
        remainDays: {
            type: Number,
            default: 2022,
            required: true
        },
        // totalDays: {
        //     type: Number,
        //     default: 2022,
        //     required: true
        // },
    },
    
    data() {
        return {
            dynamicStyle: '',
            remainingDay: 0,
            hue: '180'
        }
    },
    methods: {
        toMonthName(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber - 1);

            return date.toLocaleString((this.$i18n.locale == 'en') ? 'en-EN' : 'fr-FR', {
                month: 'long',
            });
        },
        dateChecker () {
            let deadline    = new Date(this.year, this.month-1, this.day);
            let currentDate = new Date()
            let difference  = deadline.getTime() - currentDate.getTime()
            let totalDays   = Math.ceil(difference / (1000 * 3600 * 24));
            if( totalDays <= 7) {
                this.dynamicStyle = "border: 2px solid red; background-color: rgb(246, 214, 214); color: red;"
                this.hue = '180'
            }
            else if( totalDays > 7 &&  totalDays <= 14) {
                this.dynamicStyle = "border: 2px solid #ffb200; background-color: #f6edd6; color: #ffb200;"
                this.hue = '220'
            } 
            else{
                this.dynamicStyle = "border: 2px solid #42cbd0; background-color: #d6f6f3;"
                this.hue = '8'
            }
            this.remainingDay = totalDays
            console.log(deadline)
            console.log(currentDate)
            console.log(totalDays)
        }
    },
    mounted() {
        this.dateChecker()
    }
}
</script>

<style scoped>
    .cfa-warning-deadline{
        border: 2px solid #42cbd0;
        background-color: #d6f6f3;
        color: #20c2c9;
        border-radius: .3em;
        -webkit-box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
        box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
    }
</style>