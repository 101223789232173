<template>
  <div class="cfa-video-wrapper pt-2">
    <div v-if="videoLink != null" :id="uid+'-image'" class="w-100 position-relative" >
      <img :src="placeholder" style="min-height: 38em;" alt="loading ..."/>
      <div class="cfa-video-control">
        <div class="cfa-play-btn">
          <button :id="uid+'-btn'" class="" @click="playVideo">
            <i class="fa fa-play cfa-primary"></i>
          </button>
        </div>
        <div v-show="showLabel" class="cfa-ceo cfa-bg-primary">
          <p><strong>XXXXXX XXXXX</strong></p>
          <hr>
          <p>CEO at CyberSpector</p>
        </div>
      </div>
    </div><!-- :style="'max-height: '+maxHeight+'em;'" -->
    <div v-else>
      <div class="py-3 cfa-slider-text-content">
        <div class="cfa-welcome ml-0 mt-4 pt-2">
          <br><br><br>
          <div class='cfa-alert-d d-flex flex-column justify-content-between align-items-center py-5'>
            <img src="@/assets/img/gif/file_broken.gif" alt="cfa default image for no notification" style="width: 130px;"/>
            <p class="mt-5 text-center pb-2" style="font-weight: 400;">😥️ Fichier introuvable </p>
          </div>
        </div>
      </div> 
    </div>
    <div :id="uid+'-iframe'" class="w-100 cfa-iframe d-none" ><!-- :style="'max-height: '+xmaxHeight+'em;'" -->
      <div style="padding:56.25% 0 0 0;position:relative;">
          <iframe :id="uid+'-video'" :src="videoLink+'?autoplay=1&loop=0&autopause=1&autopause=0'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Introduction Module"></iframe>
        <!--<iframe src="https://player.vimeo.com/video/853766159?autoplay=1&loop=0&autopause=1&autopause=0" badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Module:1_Introduction: Enjeux de la cybersécurité"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>-->
      </div>
      <!--<div style="padding:56.25% 0 0 0;position:relative;">
          <iframe src="https://player.vimeo.com/video/825394121?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Introduction Module"></iframe>
      </div>-->
      <!--<script src="https://player.vimeo.com/api/player.js"></script>-->
      <!--<iframe :id="uid+'-video'" width="420" :height="videoHeight" :src="videoLink+'?enablejsapi=1&origin=http://localhost:8080&controls=1&showinfo=1&color=white&autoplay=1'"  frameborder="0" allowfullscreen></iframe>-->
      <!--style="pointer-events: none;"-->
    </div>
  </div>
</template>

<script>

export default {
    name: 'CFAVideo',
    props: {
      uid: {
        type: String,
        default: 'cfa-video-player01'
      },
      placeholder: {
        type: String,
        default: 'https://picsum.photos/700/450'
      },
      videoLink: {
        type: String,
        default: ''
      },
      showLabel: {
        type: Boolean,
        default: true
      },
      videoHeight: {
        type: Number,
        default: 345
      },
      maxHeight: {
        type: Number,
        default: 26
      },
      autoplay: {
          type: Boolean,
          default: false
      },
    },
    data () {
      return {
      }
    },
    mounted() {
      const script = document.createElement('script');
      script.src = 'https://player.vimeo.com/api/player.js';
      document.head.appendChild(script);

      if(this.autoplay) {
          this.playVideo();

          /*let search = `#${this.uid+'-iframe'} iframe`;
          window.jQuery(document).ready(function( $ ) {
              console.log($(search))
              $(search)[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
          });*/

          /*let iframe = document.getElementById(this.uid+'-video');
          iframe.onload = function() {
              let player = iframe.contentWindow.document.querySelector("video");

              // Calculate the center of the video player
              let rect = player.getBoundingClientRect();
              let centerX = rect.left + rect.width / 2;
              let centerY = rect.top + rect.height / 2;

              // Create a new mouse event
              let event = new MouseEvent("click", {
                  clientX: centerX,
                  clientY: centerY
              });

              // Dispatch the event on the video player
              player.dispatchEvent(event);
          };*/
      }
    },
    methods: {
      // playVideo () {
      //   //document.getElementById(this.uid+'-video').click();
      //   document.getElementById(this.uid+'-btn').classList?.add('rainbow')
      //   setTimeout(() => {
      //     document.getElementById(this.uid+'-iframe').classList?.remove('d-none')
      //     document.getElementById(this.uid+'-image').classList?.add('d-none')
      //     document.getElementById(this.uid+'-btn').classList?.remove('rainbow')
      //   }, 3000);
      // }
      playVideo () {
        const btnElement = document.getElementById(this.uid+'-btn');
        const iframeElement = document.getElementById(this.uid+'-iframe');
        const imageElement = document.getElementById(this.uid+'-image');
        
        if (btnElement && iframeElement && imageElement) {
            btnElement.classList.add('rainbow');
            
            setTimeout(() => {
                iframeElement.classList.remove('d-none');
                imageElement.classList.add('d-none');
                btnElement.classList.remove('rainbow');
            }, 3000);
        } else {
            console.error('DOM error');
        }
      }
    },
    watch: {
    }
}
</script>

<style scoped>
.cfa-video-wrapper div:first-of-type img{
  width: 100%;
}
.cfa-video-wrapper div:first-of-type{
  border-radius: .5em;
  overflow: hidden;
  /* max-height: 26em; */
  background-color: #cef1f3;
}
.cfa-iframe iframe{
  width: 100%;
}
.cfa-iframe{
  border-radius: .5em;
  overflow: hidden;
  /* max-height: 26em; */
  background-color: #cef1f3;
}
.cfa-video-wrapper div:last-of-type{

}
.cfa-video-control{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #13444682 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cfa-play-btn{
  width: 4em;
  height: 4em;
  border-radius: 100% !important;
  padding: 0.5em;
  background-color: #ffffff75 !important;
  transition: all ease-in .3s;
}
.cfa-play-btn:hover{
  background-color: #00bfc6 !important;
}
.cfa-play-btn button{
  border-radius: 100% !important;
  width: 100%;
  height: 100%;
}
.cfa-play-btn button:hover{
  border-color: #00bfc6;
}
.cfa-ceo{
  padding: 0.5em;
  font-weight: 400;
  position: absolute;
  bottom: 1em;
  left: 1em;
}
.cfa-ceo p, hr{
  margin: 0;
  padding: 0;
  font-size: 1.1em;
}
.cfa-ceo hr{
  width: 75%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}
/* animated border button */
@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.rainbow {
  position: relative;
  z-index: 0;
  /* width: 400px;
  height: 300px; */
  border-radius: 10px;
  overflow: hidden;
  /* padding: 2rem; */
  border: 2px solid #cef1f3;
}
.rainbow::before {
  content: '';
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  /* background-color: #399953; */
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  /* background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5); */
  background-image: linear-gradient(#06bbc4, #06bbc4), linear-gradient(#cef1f3, #cef1f3), linear-gradient(#06bbc4, #06bbc4), linear-gradient(#cef1f3, #cef1f3);
  animation: rotate 4s linear infinite;
}
.rainbow::after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: white;
  border-radius: 5px;
  /* animation: opacityChange 3s infinite alternate; */
}
@keyframes opacityChange {
  50% {
    opacity: 1;
	}
  100% {
    opacity: 0.5;
	}
}

/* ---------------------- */
</style>