<template>
  <div :id="uid + '-wrapper'" class="cfa-slider-background">
    <div class="">
      <div :id="uid" class="cfa-slider-content">
        <div class="text-left d-flex">
          <button class="cfa-go-back btn cfa-btn-secondary gCorner-1001" @click="close">
            <i class="fa fa-long-arrow-right"></i>
          </button>
          <button class="w-100 btn cfa-btn-secondary gCorner-0110 gNoBtn">
            <span>NOTIFICATION</span>
          </button>
        </div>
        <div class="py-3 cfa-slider-text-content">
          <div class="cfa-welcome ml-0 mt-4 pt-5">
            <br><br><br>
            <div class='cfa-alert-d d-flex flex-column justify-content-between align-items-center py-5'>
              <img width="150" src="../../../assets/img/svg/cfa-ud-assets-05.svg"
                alt="cfa default image for no notification" />
              <p class="mt-5 text-center">😥️ Aucune mise à jour disponible. En attendant repandez l'amour. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SliderPopup',
  props: {
    uid: {
      type: String,
      default: 'gSlider01'
    },
    open: {
      type: String,
    }
  },
  methods: {
    close() {
      document.getElementById(this.uid).style.right = "-50vw"
      setTimeout(() => {
        document.getElementById(this.uid + '-wrapper').style.display = "none"
      }, 300);
      this.$emit('close')
    }
  },
  watch: {
    open: function () {
      if (this.open) {
        document.getElementById(this.uid + '-wrapper').style.display = "block"
        setTimeout(() => {
          document.getElementById(this.uid).style.right = "0"
        }, 100);
      }
    }
  }
}
</script>

<style scoped>
.cfa-slider-background {
  background-color: #0000005e;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100vw;
  overflow: auto;
  display: none;
  z-index: 100;
}

.cfa-slider-content {
  height: 100vh;
  padding: 2em;
  background-color: #fff;
  /*width: calc(30vw - 4em);*/
  width: 26em;
  position: absolute;
  right: -50vw;
  transition: all ease-in .3s;
}

.gEmpty {
  width: 100vw;
}

.cfa-go-back {
  width: 3em !important;
  margin-right: 0.3em;
}

.cfa-go-back i {
  position: relative;
  /* top: .2em; */
}

.cfa-slider-text-content {
  max-height: calc(100vh - 57px);
}

.cfa-go-back:hover {
  background-color: #585f73;
  color: #fff;
}
</style>