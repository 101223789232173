<template>
    <div class="certificate-celebration">
        <div class="overlay"></div>
        <div class="emoji-container">
            <div class="emoji" v-for="emoji in emojis" :key="emoji">
                {{ emoji }}
            </div>
        </div>
    </div>
</template>

<style scoped>
.certificate-celebration {
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.emoji-container {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 100vw;
    align-items: center;
    left: 0;
    bottom: 7em;
}

.emoji {
    font-size: 4rem;
    animation-name: applaudAnimation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes applaudAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}
</style>

<script>
export default {
    data() {
        return {
            emojis: ['👏', '👏👏', '👏👏👏'] // Add more emojis as needed
        };
    }
};
</script>
