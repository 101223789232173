<template>
  <div class="calendar">
    <!-- Display the month and year at the top of the calendar -->
    <div class="month-year fw-bold">
      {{ month }} {{ year }}
    </div>
    
    <!-- Display the calendar -->
      <div class="d-flex">
        <table class="c-table">
            <tr>
                <th class="text-center fw-bold" v-for="dayOfWeek in daysOfWeek" :key="dayOfWeek"><h5>{{ dayOfWeek }}</h5></th>
            </tr>
            <tr v-for="(week, weekIndex) in weeks" :key="weekIndex">
                <!-- previous month empty day -->
                  <td v-for="i in 7 - week.length" :key="i" :class="(weekIndex === 0) ? 'empty-cell cell-zero' : 'empty-cell cell-zero-0'">
                  </td>
                <!-- common day of week -->
                <td v-for="(day, dayIndex) in week" :key="dayIndex"
                    :class="{ 'unavailable': isNotAvailable(day), 'today': isToday(day), 'selected': isSelected(day) }"
                    @mouseover="hoverDate = day"
                    @mouseout="hoverDate = null"
                    @click="selectDate(day)">
                    {{ getDayOfMonth(day) }}
                </td>
                <!-- Add empty cells for incomplete last week -->
                  <td v-for="i in 7 - week.length" :key="i" :class="(weekIndex !== 0) ? 'empty-cell cell-end' : 'empty-cell cell-end-0'">
                  </td>
            </tr>
        </table>
      </div>
    <!--<table style="width: 100%;">
      <tr v-for="(week, weekIndex) in weeks" :key="weekIndex">
        <td v-for="(day, dayIndex) in week" :key="dayIndex"
          :class="{ 'unavailable': isNotAvailable(day), 'today': isToday(day), 'selected': isSelected(day) }"
          @mouseover="hoverDate = day"
          @mouseout="hoverDate = null"
          @click="selectDate(day)">
          {{ getDayOfWeek(day) }} {{ getDayOfMonth(day) }}
        </td>
      </tr>
    </table>-->
    
    <!-- Display the navigation buttons -->
    <div class="nav-buttons mx-2">
      <button class="btn cfa-btn-primary calendar-btn" @click="previousMonth">{{ (this.lang == 'fr') ? 'Précédent' : 'Previous' }}</button>
      <button class="btn cfa-btn-primary calendar-btn" @click="nextMonth">{{ (this.lang == 'fr') ? 'Suivant' : 'Next' }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalendarInput',
  props: {
    events: {
      type: Array,
      required: true
    },
    lang: {
        type: String,
        default: 'fr'
    }
  },
  data() {
    return {
        days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        weeks: [],
        month: '',
        year: 0,
        today: new Date(),
        currentCalendarDate: new Date(),
        hoverDate: null,
        selectedDate: null
    }
  },
  created() {
    this.updateCalendar(this.today)
  },
  methods: {
    updateCalendar(date) {
        // Update the calendar to display the given month and year
        this.weeks = []
        this.month = this.getMonthName(date.getMonth())
        this.year = date.getFullYear()
        
        const currentMonth = date.getMonth()
        const currentYear = date.getFullYear()
        const numDays = new Date(currentYear, currentMonth + 1, 0).getDate()
        
        let week = []
        for (let day = 1; day <= numDays; day++) {
            const currentDate = new Date(currentYear, currentMonth, day)
            week.push(currentDate)
            if (currentDate.getDay() === 6 || day === numDays) {
                this.weeks.push(week)
                week = []
            }
        }
    },
    getMonthName(month) {
        // Return the name of the given month
        const monthNames = (this.lang == 'fr') ? [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ] : [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ]
        return monthNames[month]
    },
    isNotAvailable(day) {
        // Check if the given day is in the list of events
        return this.events.some(event => event.getTime() === day.getTime())
    },
    isToday(day) {
        // Check if the given day is today's date
        return (
            day.getFullYear() === this.today.getFullYear() &&
            day.getMonth() === this.today.getMonth() &&
            day.getDate() === this.today.getDate()
        );
    },
    isSelected(day) {
      // Check if the given day is the selected date
      return day.getTime() === this.selectedDate
    },
    setCurrentCalendarDate(date) {
        this.currentCalendarDate = date;
    },
    previousMonth() {
        // Go to the previous month
        let date = this.currentCalendarDate;
        const currentMonth = date.getMonth();
        const currentYear = date.getFullYear();
        let previousMonth;
        let previousYear;
        
        if (currentMonth === 0) {
            previousMonth = 11;
            previousYear = currentYear - 1;
        } 
        else {
            previousMonth = currentMonth - 1;
            previousYear = currentYear;
        }
        
        let prevMonthFirstDate = new Date(previousYear, previousMonth, 1);
        this.setCurrentCalendarDate(prevMonthFirstDate);
        this.updateCalendar(prevMonthFirstDate);
    },
    nextMonth() {
        // Move to the next month
        let month = this.currentCalendarDate.getMonth() + 1
        let year = this.currentCalendarDate.getFullYear()
        if (month > 11) {
            month = 0
            year++
        }
        let nextMonthFirstDate = new Date(year, month);
        this.setCurrentCalendarDate(nextMonthFirstDate);
        this.updateCalendar(nextMonthFirstDate)
    },
    selectDate(day) {
      // Select the given date and emit the value
      if(!this.isNotAvailable(day)) {
        this.selectedDate = day.getTime()
        this.$emit('availableDate', day)
      }
      else{
        this.$emit('unavailableDate', day)
      }
    },
    getDayOfWeek(day) {
      // Return the day of the week for the given date
      const days = (this.lang == 'fr') ? ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'] : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return days[day.getDay()]
    },
    getDayOfMonth(day) {
      // Return the day of the month for the given date
      return day.getDate()
    }
  }
}
</script>

<style scoped>
    .c-table {
        border-collapse: separate;
        /* width: 100%;*/
        margin:auto;
    }
    .calendar-btn{
        padding: .1em !important;
    }
    .calendar {
        /* Style the calendar container */
        /*width: 500px;*/
        /*height: 500px;*/
        border: 1px solid var(--primary-color);
        background-color: var(--primary-light-color-2);
        padding: 0.5em;
        border-radius: 5px;
    }

    .calendar .month-year {
    /* Style the month and year display */
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    padding: .5em 0em;
    }

    .calendar td {
        /* Style the calendar cells */
        width: 55px;
        height: 50px;
        min-height: 50px;
        max-height: 50px;
        max-width: 50px;
        min-width: 50px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        /*border: 1px solid white;
        background-color: #dfdfdf;*/
        border-radius: 100%;
    }

    .calendar td:hover{
        background-color: var(--primary-color);
    }

    .calendar .unavailable {
    background-color: #ecbbbb !important;
    cursor: not-allowed;
    }

    .calendar .selected {
    background-color: var(--primary-color) !important;
    }

    .calendar .today {
        /* Style the today cell */
        /*background-color: #cecece;*/
        background-color: var(--primary-light-color-2);
    }

    .calendar .nav-buttons {
    /* Style the navigation buttons */
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: .5em 0em;
    }

    .calendar button {
    /* Style the buttons */
    width: 100px;
    height: 30px;
    font-size: 16px;
    }
    .cell-end-0, .cell-zero-0{
      display: none !important;
    }
</style>
