<template>
  <div class="position-relative">
    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
      <img width="25" height="25" :src="image_url" alt="avatar"/>
      <div><span class="d-none d-md-flex">{{ firstname }} {{ lastname }}&nbsp;</span> &blacktriangledown; </div>
    </div>
    <div id="avatarTools" class="cfa-avatar-menu d-none">
      <!-- item1 -->
      <a href="/dashboard"><button><i class="fa fa-dashboard"></i>Dashboard</button></a>
      <!-- item2 -->
      <a href="/parametres"><button><i class="fa fa-cogs"></i>{{ $t('param') }}</button></a>
      <!-- item3 -->
<!--       
      <form class="langForm" style="margin-left: 1.1em;">
        <input v-if="$i18n.locale == 'fr'" checked class="radioLang" type="radio" id="contactChoice1" name="lang" value="fr" v-model="$i18n.locale" @change="changeLanguage">
        <input v-else class="radioLang" type="radio" id="contactChoice1" name="lang" value="fr" v-model="$i18n.locale" @change="changeLanguage">
        <label for="contactChoice1" style="position: relative; bottom: 1.1em; margin-right: 1em; margin-left: .5em; cursor: pointer;">
          <img width="20" class="mr-4" src="../../../assets/img/svg/lang/fr.svg" alt="">
        </label>
        <input v-if="$i18n.locale == 'en'" no-disabled checked class="radioLang" type="radio" id="contactChoice2" name="lang" value="en" v-model="$i18n.locale" @change="changeLanguage">
        <input v-else class="radioLang" no-disabled type="radio" id="contactChoice2" name="lang" value="en" v-model="$i18n.locale" @change="changeLanguage">
        <label for="contactChoice2" style="no-pointer-events: none; position: relative; bottom: 1.1em; margin-right: 1em; margin-left: .5em; cursor: pointer;">
          <img width="20" class="mr-4" src="../../../assets/img/svg/lang/en.svg" alt="">
        </label>
      </form>
      -->
      <hr>
      <button @click="logout"><i class="fa fa-sign-out"></i>{{ $t('logout') }}</button>
    </div>
  </div>
</template>

<script>
import { getAPI } from '@/axios-api.js'

export default {
  name: 'AvatartType1',
  props: {
    image: {
      type: String,
      default: 'https://picsum.photos/100'
    },
    fname: {
      type: String,
      default: '............',
      required: true,
    },
    lname: {
      type: String,
      default: '',
      required: true,
    },
    refresh: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      openMenuTools: false,
      userData: null,
      firstname: sessionStorage.firstname,
      lastname: sessionStorage.lastname,
      image_url: this.image,
    }
  },
  methods: {
    openToolsMenu () {
      let menu = document.getElementById('avatarTools')
      if(menu.classList.contains('d-none'))
        menu.classList.remove('d-none')
      else
        menu.classList.add('d-none')
    },
    logout () {
      this.$emit('getLogoutConfirmatiion')
    },
    retrieveUserData () {
      getAPI.get('accounts/users/me')
      .then(response => {
        if(response.status == 200) {
          this.userData = response.data.data
          sessionStorage.setItem('organisation', this.userData.organisation.id)
          //sessionStorage.setItem('organisation', this.userData.organisation)
        }
        else {
          this.displayError('Oups ! something went wrong.', 'alert-no')
        }
      })
    }
  },
  mounted () {
    this.retrieveUserData()

    document.addEventListener('click', () => {
      document.getElementById('avatarTools').classList.add('d-none');
    });

    document.getElementById('avatarIcon').addEventListener('click', (event) => {
      this.openToolsMenu();
      event.stopPropagation();
    });
  },
  watch: {
    userData: function () {
      if(this.userData != null) {
        this.firstname = this.userData.firstname
        this.lastname = this.userData.lastname
        this.image_url = (this.userData.image_url != null) ? this.userData.image_url : this.image
        this.$emit('userData', this.userData)
      }
    },
    refresh: function () {
      this.retrieveUserData()
    }
  }
}
</script>

<style scoped>
.cfa-avatar1{
  cursor: pointer;
  /* padding: 0.5em; */
  border-radius: .5em;
  /* background-color: #f0f3f8; */
  transition: all ease-in .1s;
  margin-left: 0.5em;
}
.cfa-avatar1:hover{
  background-color: var(--primary-light-color-2);
}
.cfa-avatar1 img{
  border-radius: 100%;
  margin: 0.5em;
}
.cfa-avatar1 div{
  display: flex;
  font-weight: 400;
}

.cfa-avatar-menu hr{
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}
.cfa-avatar-menu button i{
  margin-right: 0.5em;
}
.cfa-avatar-menu button:hover{
  background-color: #cef1f2;
}
.text-gray{
  color: #d1d3e2;
}
</style>
